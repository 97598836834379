import React, {useState} from 'react'
import {Modal, Select} from 'antd'
import ObjectInspector from "react-object-inspector"

const PayloadExampleModal = (props) => {
    const { visible, setVisible, events, allEvents } = props
    const eventDefault = allEvents.length > 0 ? allEvents[0] : {}
    const [event, setEvent] = useState(eventDefault)

    const _changeFieldEvent = (value) => {
        const _event = allEvents.find(item => item.event === value)
        if(_event !== undefined) {
            setEvent(_event)
        } else {
            setEvent({})
        }
    }

    const hideModal = () => {
        setVisible(false)
    }

    const _validate = () => {
        setVisible(false)
    }

    return (
        <Modal
            title="Payload example"
            visible={visible}
            onOk={_validate}
            onCancel={hideModal}
            width={1000}
            okText="OK"
            cancelText="Cancel"
        >
            <div className="PayloadExampleModal">
                <div className="CreateChild">
                    <label htmlFor="event" className="">Event:</label>
                    <Select
                        value={event.event || ''}
                        name="event"
                        style={{width: "100%"}}
                        onChange={_changeFieldEvent}
                        defaultValue={event.event || ''}
                    >
                        {events.length > 0 &&
                            events.map(
                                (item, index) => (
                                    <Select.Option
                                        key={index}
                                        value={
                                            item.value
                                        }
                                    >
                                        {item.label}
                                    </Select.Option>
                                )
                            )}
                    </Select>
                </div>
                {Object.keys(event).length > 0 && <div className="payload">
                    <div className="headerPayload">
                        <h3>Headers</h3>
                        <ObjectInspector depth={0} path="root.value" data={event.headers} />
                    </div>
                    <div className="bodyPayload">
                        <h3>Body</h3>
                        <ObjectInspector depth={0} path="root.value" data={event.body} />
                    </div>
                </div>}
            </div>
        </Modal>
    )
}

export default PayloadExampleModal