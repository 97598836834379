import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Modal, TextStyle, InlineError, TextContainer, DisplayText} from '@shopify/polaris'

class RevokeModal extends Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            message: {
                error: '',
                success: '',
            },
            _id: props.revoke._id,
            name: props.revoke.name
        }
    }

    static getDerivedStateFromProps(nextProps, nextState) {
        if (nextProps.revoke._id !== nextState._id) return {
            loading: false,
            message: {
                error: '',
                success: '',
            },
            _id: nextProps.revoke._id,
        }

        return nextState
    }

    _clickSave = async () => {
        const {save} = this.props
        const {loading, _id} = this.state
        if (loading) return

        this.setState({
            loading: true
        })

        try {
            await save({_id})

            this.setState({
                loading: false,
                message: {
                    error: '',
                    success: 'Revoke successfully!',
                }
            })
        } catch (e) {
            this.setState({
                loading: false,
                message: {
                    error: e.message || 'Error!',
                    success: '',
                }
            })
            alert(e.message || 'Error!')
        }
    }

    render() {
        const {toggle, revoke: {open, name}} = this.props
        const {message, loading} = this.state
        return (
            <Modal
                title='Revoke'
                open={open}
                onClose={toggle}
                primaryAction={{
                    content: 'Revoke',
                    onAction: this._clickSave,
                    loading: loading,
                    destructive: true
                }}
                secondaryActions={[
                    {
                        content: 'Close',
                        onAction: toggle,
                        disabled: loading,
                    },
                ]}
            >
                <Modal.Section>
                    <InlineError message={message.error}/>
                    <TextStyle variation='positive'>{message.success}</TextStyle>
                    <TextContainer>
                        <DisplayText size="small">
                            <TextStyle variation="negative">Are you sure to revoke <TextStyle
                                variation="strong">{name}</TextStyle>?</TextStyle>
                        </DisplayText>
                    </TextContainer>
                </Modal.Section>
            </Modal>
        )
    }
}

RevokeModal.propTypes = {
    toggle: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    revoke: PropTypes.object.isRequired,

}


export default RevokeModal
