import React from 'react'
import {Button, DataTable, Tooltip} from '@shopify/polaris'
import ObjectInspector from "react-object-inspector"
import {withRouter} from 'react-router-dom'
import humanizeTime from '../../../helpers/humanizeTime'
import {Tag, message} from 'antd'
import {CircleDisableMinor, ReplayMinor, ViewMajorMonotone} from '@shopify/polaris-icons'
import {
    getTrackingWebhooksHistoriesCancel,
    getTrackingWebhooksHistoriesRetry,
} from '../../../services/api/WebhookSettingsServices'
import ViewPayload from './ViewPayload'
import ViewResponseStatus from './ViewResponseStatus'

const columnContentTypes = [
    'text', 'text', 'text', 'text', 'text', 'text', 'text'
]

const ListWebhookSetting = (props) => {
    const {data, fetchListWebhooks} = props

    const cols = [
        'Tracking Number',
        'Object',
        'Retry',
        'Event',
        'Endpoint',
        'Payload',
        'Created',
        'Response Status',
        'Actions'
    ]

    const _mapRows = data => {
        return data.map((item = {}) => {
            const {event, tracking_code, url, created_at, meta, retry_count, status, response = {}} = item

            const payload = <ViewPayload request={item.request}/>

            const actions = <div className="acions">
                {status === 'failed' && <Tooltip content="Retry" preferredPosition="above">
                    <Button onClick={() => _retryWebhookHistories(item._id)} id="RetryButton" icon={ReplayMinor}/>
                </Tooltip>}
                {status === 'pending' && <Tooltip content="Cancel" preferredPosition="above">
                    <Button onClick={() => _cancelWebhookHistories(item._id)} id="CancelButton" icon={CircleDisableMinor}/>
                </Tooltip>}
            </div>

            return [
                tracking_code,
                <ObjectInspector depth={0} path="root.value" data={meta} />,
                retry_count,
                event,
                url,
                payload,
                (created_at && humanizeTime(created_at)) || null,
                mapStatus(status, response),
                actions
            ]
        })
    }

    const mapStatus = (status, response) => {
        switch (status) {
            case 'pending':
                return <Tag color="#2db7f5">pending</Tag>
            case 'success':
                return <div className='column-response-status'>
                    <Tag color="#87d068">success</Tag>
                    <ViewResponseStatus response={response}/>
                </div>
            case 'failed':
                return <div className='column-response-status'>
                    <Tag color="#f50">failed</Tag>
                    <ViewResponseStatus response={response}/>
                </div>
            case 'cancelled':
                return <Tag color="#f50">cancelled</Tag>
        }
    }

    const _retryWebhookHistories = async (_id) => {
        try {
            const {success, message: error} = await getTrackingWebhooksHistoriesRetry(_id)
            if (!success) {
                message.error(error)
            }
            fetchListWebhooks()
        } catch (e) {
            message.error(e.message)
        }
    }

    const _cancelWebhookHistories = async (_id) => {
        try {
            const {success, message: error} = await getTrackingWebhooksHistoriesCancel(_id)
            if (!success) {
                message.error(error)
            }
            fetchListWebhooks()
        } catch (e) {
            message.error(e.message)
        }
    }

    return (
        <React.Fragment>
            <DataTable
                firstColumnMinWidth={['30px','30px','30px','30px','30px','30px','30px']}
                columnContentTypes={columnContentTypes}
                headings={cols}
                rows={_mapRows(data)}
            />
        </React.Fragment>
    )
}

export default withRouter(ListWebhookSetting)
