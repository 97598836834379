import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {CalloutCard} from '@shopify/polaris'
import GenerateKeyModal from './GenerateKeyModal'

class GenerateKey extends Component {
    state = {
        toggle: false,
        loading: false
    }

    _toggle = () => {
        const {loading, toggle} = this.state
        if (loading) return
        this.setState({toggle: !toggle})
    }

    _save = async apikey => {
        const {insert} = this.props

        if (this.state.loading) return
        this.setState({loading: true})

        const {success, data, message} = await insert(apikey)
        this.setState({loading: false})
        if (!success) throw new Error(message)
        return {success, data, message}
    }

    render() {
        const {toggle, loading} = this.state

        return (
            <div className="mr-4">
                <CalloutCard
                    title={`Generate your API-Key`}
                    illustration="/settings-customizecart-705f57c725ac05be5a34ec20c05b94298cb8afd10aac7bd9c7ad02030f48cfa0.svg"
                    primaryAction={{
                        content: 'Generate',
                        onAction: this._toggle,
                        primary: true,
                        size: 'medium'
                    }}
                >
                </CalloutCard>
                <CalloutCard
                    title={`API Docs`}
                    illustration="/bf0ca0d72f41bb6b6313d0020bb364d5.png"
                    primaryAction={{
                        content: 'Development',
                        url: "https://public-tracking.merch8.com/api/docs/#/",
                        external: true,
                        plain: true,
                        size: 'medium'
                    }}
                >
                </CalloutCard>
                <CalloutCard
                    title={`API Docs`}
                    illustration="/bf0ca0d72f41bb6b6313d0020bb364d5.png"
                    primaryAction={{
                        content: 'Production',
                        url: "https://trackingv2-public-api.merchize.com/docs",
                        external: true,
                        plain: true,
                        size: 'medium'
                    }}
                >
                </CalloutCard>
                <GenerateKeyModal open={toggle} toggle={this._toggle} save={this._save} loading={loading} />
            </div>
        )
    }
}

GenerateKey.propTypes = {
    insert: PropTypes.func.isRequired,
}

export default GenerateKey
