import createAPIServices from './createApiServices'

const baseUrl = process.env.NODE_ENV === 'production' ? '/api' : 'https://staging-tracking-v2.merchize.com/api'
const api = createAPIServices({baseUrl})

export const getSources = data => {
    return api.makeAuthRequest({
        url : `carriers`,
        method: 'GET',
        params : data,
    })
}

export const createSource = data => {
    return api.makeAuthRequest({
        url : 'carriers',
        method : 'POST',
        data : data
    })
}

export const editSource = (id,data) => {
    return api.makeAuthRequest({
        url : `carriers/${id}`,
        method : 'PUT',
        data : data
    })
}