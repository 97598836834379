import { getLocalData, setLocalData } from './LocalStorageServices'

const _store = {
    state: {
        accessToken: getLocalData('accessToken', ''),
        refreshToken: getLocalData('refreshToken', ''),
        user: getLocalData('user', {}),
    },
    subscribers: [],
}

const _broadcast = () => {
    setTimeout(() => {
        _store.subscribers.forEach(subscriber => {
            subscriber(_store.state)
        })
    }, 0)
}

export const notifyUpdate = _broadcast

export const subscribe = subscriber => {
    if (typeof subscriber !== 'function') return
    if (_store.subscribers.indexOf(subscriber) !== -1) return

    _store.subscribers = [].concat(_store.subscribers, subscriber)
}

export const unsubscribe = subscriber => {
    _store.subscribers = _store.subscribers.filter(sub => sub !== subscriber)
}

export const isAuthenticated = () => {
    const { state } = _store

    return !!state.accessToken
}

export const setUserId = userId => {
    const updatedUserId = userId

    _store.user = {
        ..._store.user,
        updatedUserId,
    }

    setLocalData('userId', updatedUserId)
}

export const setUserData = user => {
    const updatedUser = {
        ..._store.state.user,
        ...user,
    }
    _store.state = {
        ..._store.state,
        user: updatedUser,
    }

    setLocalData('user', updatedUser)
    _broadcast()
}

export const updateUserProfile = (args = {}) => {
    const vArgs = Object.assign({}, args)

    const currentUser = Object.assign({}, _store.state.user)
    const currentProfile = Object.assign({}, currentUser.profile)

    const updatedUser = {
        ...currentUser,
        profile: {
            ...currentProfile,
            ...vArgs,
        },
    }

    _store.state = {
        ..._store.state,
        user: updatedUser,
    }

    setLocalData('user', updatedUser)
    _broadcast()
}

export const getUserData = () => getLocalData('user') || _store.state.user || {}

export const getUserId = () => {
    const user = getLocalData('user') || _store.state.user || {}

    return user._id || ''
}

export const setAccessToken = accessToken => {
    _store.state = {
        ..._store.state,
        accessToken,
    }

    setLocalData('accessToken', accessToken)
}

export const setRefreshToken = refreshToken => {
    _store.state = {
        ..._store.state,
        refreshToken,
    }

    setLocalData('refreshToken', refreshToken)
}

export const getAccessToken = () => getLocalData('accessToken') || _store.state.accessToken || ''

export const getRefreshToken = () => getLocalData('refreshToken') || _store.state.refreshToken || ''

export const logoutUser = (message = '') => {

    setAccessToken('')
    setRefreshToken('')
    setUserData({})
    setUserId('')

    return true
}

export const loginUser = ({ token, payload, userData }) => {
    const { userId, roles, scopes } = payload
    setAccessToken(token)
    setUserId(userId)
    setUserData({ ...userData, roles, scopes })
}

export const hasScopes = (...requestScopes) => {
    const { scopes } = getUserData()
    for (const scope of requestScopes) {
        if (scope && scopes.indexOf(scope) !== -1) return true
    }

    return false
}

export const hasRoles = (...requestRoles) => {
    const { roles } = getUserData()
    for (const role of requestRoles) {
        if (role && roles.indexOf(role) !== -1) return true
    }

    return false
}
