import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {InlineError, Modal, TextField} from '@shopify/polaris'
import {generateSecretKey} from '../../../services/api/WebhookServices'
import safeTrim from '../../../helpers/safeTrim'

const _parseWebhook = ({url, name, status, secret_key}) => {
    const webhook = {}

    if (safeTrim(name) !== '') webhook.name = name
    if (safeTrim(url) !== '') webhook.url = url
    if (safeTrim(status) !== '') webhook.status = status
    if (secret_key !== undefined) webhook.secret_key = secret_key

    return webhook
}

class InsertWebhookModal extends Component {
    state = {
        url: '',
        name: '',
        status: 'active',
        secret_key: '',
    }

    _clickSave = async () => {
        const {url, name, status, secret_key} = this.state
        try {
            await this.props.save(_parseWebhook({url, name, status, secret_key}))
            this.setState({
                url: '',
                name: '',
                status: 'active',
                secret_key: '',
            })
        } catch (e) {
            return alert(e.message)
        }
    }

    _onChange = (key, value) => this.setState({[key]: value})

    _canAdd = ({url, name, status, secret_key}) => {
        return url && status
    }

    _generateSecret = async () => {
        try {
            const {success, data, message} = await generateSecretKey()

            if (!success) throw new Error(message)

            this.setState({
                secret_key: data
            })
        } catch (e) {
            alert(e.message || 'Generate secret error.')
        }
    }

    render() {
        const {open, toggle, loading, message} = this.props
        const {url, name, status, secret_key} = this.state

        const canAdd = this._canAdd({url, name, status, secret_key}) && !loading

        return (
            <Modal
                open={open}
                onClose={toggle}
                title="Add new Webhook"
                primaryAction={{
                    content: 'Add',
                    onAction: this._clickSave,
                    disabled: !canAdd,
                }}
                secondaryActions={[
                    {
                        content: 'Close',
                        onAction: toggle,
                        disabled: loading,
                    },
                ]}
            >
                <Modal.Section>
                    <InlineError message={message.error}/>
                    <TextField
                        value={url}
                        type='url'
                        onChange={value => this._onChange('url', value)}
                        label="URL"
                    />
                    <TextField
                        value={name}
                        onChange={value => this._onChange('name', value)}
                        label="Name"
                    />
                    <TextField
                        value={secret_key}
                        type={'text'}
                        onChange={value => this._onChange('secret_key', value)}
                        label="Secret"
                        labelAction={{content: 'Generate', onAction: this._generateSecret}}
                        helpText="We’ll use this secret for secure your webhook."
                    />
                    <TextField
                        label="Status"
                        onChange={value => this._onChange('status', value)}
                        value={status}
                        disabled
                    />
                </Modal.Section>
            </Modal>
        )
    }
}

InsertWebhookModal.propTypes = {
    open: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
}


export default InsertWebhookModal
