import React, { useState } from 'react'
import { Select, Modal, Input } from 'antd'
import PropTypes from 'prop-types'
import {FormFeedback} from 'reactstrap'
import {isValidUrl} from '../../../helpers/validateURL'

const WebhookSettingCreateModal = (props) => {
    const { visible, setVisible, handleClickAdd, events} = props
    const eventDefault = events.length > 1 ? events[1].value : ''
    const [errors, setErrors] = useState({})
    const [fields, setFields] = useState({
        secret_key: '',
        url: '',
        tracking_code: '',
        tracking_carrier: '',
        event: eventDefault,
        meta: '',
        order_id: '',
        fulfillment_id: '',
        store: ''
    })

    const hideModal = () => {
        setVisible(false)
    }

    const _validate = () => {
        let err = {}

        if (fields.secret_key === '') err.secret_key = 'Secret Key is required'
        if (fields.tracking_code === '') err.tracking_code = 'Tracking Number is required'
        if (fields.tracking_carrier === '') err.tracking_carrier = 'Tracking Carrier is required'
        if (fields.url === '') err.url = 'Endpoint is required'
        if (!isValidUrl(fields.url)) err.url = 'Endpoint must be valid URL'
        if (fields.event === '') err.event = 'Event is required'
        if (fields.order_id === '') err.order_id = 'Order ID is required'
        if (fields.fulfillment_id === '') err.fulfillment_id = 'Fulfillment ID is required'
        if (fields.store === '') err.store = 'Store is required'

        setErrors(err)

        if (Object.keys(err).length >= 1) return

        handleClickAdd({
            // ...fields,
            secret_key: fields.secret_key,
            tracking_code: fields.tracking_code,
            tracking_carrier: fields.tracking_carrier,
            url: fields.url,
            event: fields.event,
            meta: {
                order_id: fields.order_id,
                fulfillment_id: fields.fulfillment_id,
                store: fields.store
            }
        })
        setFields({
            secret_key: '',
            url: '',
            tracking_code: '',
            tracking_carrier: '',
            event: eventDefault,
            meta: '',
            order_id: '',
            fulfillment_id: '',
            store: ''
        })
        setVisible(false)
    }

    const _changeField = (e) => {
        const {name, value} = e.target
        setFields({
            ...fields,
            [name]: value
        })
    }

    const _changeFieldEvent = (value) => {
        setFields({
            ...fields,
            event: value
        })
    }

    return (
        <Modal
            title="Add Webhook"
            visible={visible}
            onOk={_validate}
            onCancel={hideModal}
            okText="Add"
            cancelText="Cancel"
        >
            <div className="TrackingMappingCreate">
                <div className="CreateContent">
                    <div className="CreateChild">
                        <label htmlFor="Carrier" className="">Secret Key:</label>
                        <Input name="secret_key" defaultValue={!!fields ? fields.secret_key : ''} onChange={_changeField}/>
                        {errors.secret_key && <FormFeedback>{errors.secret_key}</FormFeedback>}
                    </div>
                    <div className="CreateChild">
                        <label htmlFor="tracking_code" className="">Tracking Number:</label>
                        <Input name="tracking_code" defaultValue={!!fields ? fields.tracking_code : ''} onChange={_changeField}/>
                        {errors.tracking_code && <FormFeedback>{errors.tracking_code}</FormFeedback>}
                    </div>
                    <div className="CreateChild">
                        <label htmlFor="tracking_carrier" className="">Tracking Carrier:</label>
                        <Input name="tracking_carrier" defaultValue={!!fields ? fields.tracking_carrier : ''} onChange={_changeField}/>
                        {errors.tracking_carrier && <FormFeedback>{errors.tracking_carrier}</FormFeedback>}
                    </div>
                    <div className="CreateChild">
                        <label htmlFor="url" className="">Endpoint:</label>
                        <Input name="url" defaultValue={!!fields ? fields.url : ''} onChange={_changeField}/>
                        {errors.url && <FormFeedback>{errors.url}</FormFeedback>}
                    </div>
                    <div className="CreateChild">
                        <label htmlFor="event" className="">Event:</label>
                        <Select
                            value={eventDefault}
                            name="event"
                            style={{width: "100%"}}
                            onChange={_changeFieldEvent}
                            defaultValue={eventDefault}
                        >
                            {events.length > 0 &&
                                events.map(
                                    (item, index) => (
                                        <Select.Option
                                            key={index}
                                            value={
                                                item.value
                                            }
                                        >
                                            {item.label}
                                        </Select.Option>
                                    )
                                )}
                        </Select>
                        {errors.event && <FormFeedback>{errors.event}</FormFeedback>}
                    </div>
                    {/*<div className="CreateChild">*/}
                    {/*    <label htmlFor="meta" className="">Object:</label>*/}
                    {/*    <TextArea rows={4} name="meta" onChange={_changeField}/>*/}
                    {/*    {errors.meta && <FormFeedback>{errors.meta}</FormFeedback>}*/}
                    {/*</div>*/}
                    <div className="CreateChild">
                        <label htmlFor="order_id" className="">Order ID:</label>
                        <Input name="order_id" defaultValue={!!fields ? fields.order_id : ''} onChange={_changeField}/>
                        {errors.order_id && <FormFeedback>{errors.order_id}</FormFeedback>}
                    </div>
                    <div className="CreateChild">
                        <label htmlFor="fulfillment_id" className="">Fulfillment ID:</label>
                        <Input name="fulfillment_id" defaultValue={!!fields ? fields.fulfillment_id : ''} onChange={_changeField}/>
                        {errors.fulfillment_id && <FormFeedback>{errors.fulfillment_id}</FormFeedback>}
                    </div>
                    <div className="CreateChild">
                        <label htmlFor="store" className="">Store:</label>
                        <Input name="store" defaultValue={!!fields ? fields.store : ''} onChange={_changeField}/>
                        {errors.store && <FormFeedback>{errors.store}</FormFeedback>}
                    </div>
                </div>
            </div>
        </Modal>
    )
}

WebhookSettingCreateModal.propTypes = {
    handleClickAdd: PropTypes.func.isRequired,
}

export default WebhookSettingCreateModal
