import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Filters, ChoiceList, Loading} from '@shopify/polaris'
import source from '../static/source'
import keys from '../static/keys'

class FilterJob extends Component {
    state = {
        queryBy: 'source',
    }

    _onChange = key => ([v]) => this.props.setQuery({[key]: v})

    _onChangeText = key => v => this.props.setQuery({[key]: v}, true)

    _onQueryClear = key => () => this.props.setQuery({[key]: ''})

    _makeAppliedFilters = filter => {
        const result = []

        Object.keys(filter).forEach(key => {
            if (key === 'end_date' || key === 'start_date') return

            const label = (key === 'source') ? (source.find(i => i.value === filter[key]) || {}).label : filter[key]

            if (!label) return

            result.push({
                key,
                label: `${keys[key]}: ${label.toString()}`,
                onRemove: this._onQueryClear(key)
            })
        })

        return result
    }

    _clearFilter = () => {
        const {filter} = this.props
        const result = {}

        Object.keys(filter).forEach(key => {
            if (key === 'end_date' || key === 'start_date') return

            result[key] = ''
        })
        this.props.setQuery(result)
    }

    render() {
        const {query, loading} = this.props
        const {queryBy} = this.state
        const filters = [{
            key: 'source',
            label: 'Source',
            filter: (
                <ChoiceList
                    title="Source"
                    choices={source}
                    selected={query.source || []}
                    onChange={this._onChange('source')}
                />
            ),
            shortcut: true,
        }]

        const appliedFilters = this._makeAppliedFilters(query)

        return (
            <React.Fragment>
                {loading && <Loading/>}
                <Filters
                    disabled={loading}
                    queryValue={query[queryBy]}
                    filters={filters}
                    appliedFilters={appliedFilters}
                    queryPlaceholder={`Find by ${queryBy}`}
                    onQueryChange={this._onChangeText(queryBy)}
                    onQueryClear={this._onQueryClear(queryBy)}
                    onClearAll={this._clearFilter}
                />
            </React.Fragment>
        )
    }
}

FilterJob.propTypes = {
    query: PropTypes.object.isRequired,
    setQuery: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
}

export default FilterJob
