import React from 'react'
import {TopBar} from '@shopify/polaris'
import {CircleChevronRightMinor} from '@shopify/polaris-icons'
import {withRouter} from 'react-router-dom'
import {getMe} from '../../../services/api/UserServices2'
import {getUserData} from '../../../services/AuthServices'
import {setLocalData, removeLocalData} from '../../../services/LocalStorageServices'

class TopBarUserMenu extends React.Component {
    state = {
        isOpen: false,
    }

    componentDidMount() {
        this.setLocalUser()
    }

    handleLogout = () => {
        removeLocalData('user')
        removeLocalData('accessToken')
        removeLocalData('userId')
        removeLocalData('refreshToken')
        window.location.assign(`${window.location.origin}/a/login`)
    }

    getUserInfor = async () => {
        try {
            const {success, data} = await getMe()
            if (!success) throw new Error('Fail to get user')

            return data
        } catch (e) {
            throw e
        }

    }
    setLocalUser = async () => {
        try {
            const user = await this.getUserInfor()
            setLocalData('user', user)
        } catch (e) {
            throw e
        }
    }

    render() {
        const {isOpen} = this.state
        const {email} = getUserData()

        return (
            <TopBar.UserMenu
                actions={[
                    {
                        items: [{content: 'Logout', icon: CircleChevronRightMinor, onAction: this.handleLogout}],
                    },
                ]}
                name={email || ''}
                initials={email ? email[0] : ''}
                open={isOpen}
                onToggle={() => this.setState(state => {
                    return {
                        isOpen: !state.isOpen,
                    }
                })}
            />
        )
    }
}


export default withRouter(TopBarUserMenu)
