const keys = {
    'tracking_code': 'Tracking Code',
    'status': 'Status',
    'carrier': 'Carrier',
    'source': 'Source',
    'order_number': 'Order Number',
    'transit_time': 'Transit time',
}

export default keys
