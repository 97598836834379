import React, {Component} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {DataTable,Tooltip, TextStyle} from '@shopify/polaris'
import {withRouter} from 'react-router-dom'
import humanizeTime from '../../../helpers/humanizeTime'
import ForceUpdateJob from "./ForceUpdateJob"

const tableCol = ['ID', 'Source', 'Status', 'Total', 'Tracking Scan', 'Tracking Failed', 'Start Time', 'End Time', 'Execution Time', 'Action']

const columnContentTypes = [
    'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text',
]

class ListJobs extends Component {
    state = {}

    _mapRows = data => {
        return data.map((item = {}) => {
            const {
                _id,
                source,
                status,
                start_at,
                trackings_total,
                end_at,
                trackings_failed,
                trackings_updated
            } = item

            const {refresh} = this.props
            const forceJob = <ForceUpdateJob status={status} source={source} refresh={refresh}/>

            const toHHMMSS = (secs) => {
                let secNum = parseInt(secs, 10)
                let hours   = Math.floor(secNum / 3600)
                let minutes = Math.floor(secNum / 60) % 60
                let seconds = secNum % 60

                return [hours,minutes,seconds].map(v => v < 10 ? "0" + v : v).join(":")
            }


            const duration = moment.duration(moment(end_at).diff(moment(start_at))).asSeconds()
            const displayDate = (date) => moment(date).format('YYYY-MM-DD HH:mm:ss')

            return [
                _id, source, status, trackings_total,
                trackings_updated, trackings_failed,
                <Tooltip content={displayDate(start_at)}>
                    <TextStyle>{humanizeTime(start_at)}</TextStyle>
                </Tooltip>,
                <Tooltip content={displayDate(end_at)}>
                    <TextStyle>{humanizeTime(end_at)}</TextStyle>
                </Tooltip>,
                toHHMMSS(duration),
                forceJob
            ]
        })
    }

    render() {
        const {data, total, paging} = this.props
        const rows = this._mapRows(data)
        const page = paging.page
        const limit = paging.limit
        const startShow = (page - 1) * limit + 1 > total ? total : (page - 1) * limit + 1
        const endShow = page * limit > total ? total : page * limit
        const footer = `Showing ${startShow} - ${endShow} of ${total} results`

        return (
            <DataTable
                columnContentTypes={columnContentTypes}
                headings={tableCol}
                rows={rows}
                footerContent={footer}
            />
        )
    }
}

ListJobs.propTypes = {
    data: PropTypes.object,
    total: PropTypes.number,
    paging: PropTypes.object
}

export default withRouter(ListJobs)
