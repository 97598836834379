import React, {Component} from 'react'
import DashboardContainer from './dashboard/components/DashboardContainer'
import {Redirect, Route, Switch} from 'react-router-dom'
import AuthContainer from './auth/components/AuthContainer'
import AuthContext from './auth/context/AuthContext'

class App extends Component {

    render() {
        const authContext = this.context
        const {isAuthenticated, isLoading} = authContext
        const defaultRedirect = isAuthenticated ? '/d' : '/a'

        return (
            !isLoading &&
            (<div className="App">
                <Switch>
                    {isAuthenticated && <Route path={'/d'} component={DashboardContainer}/>}
                    {!isAuthenticated && <Route path={'/a'} component={AuthContainer}/>}

                    <Route path={'*'}>
                        <Redirect to={defaultRedirect}/>
                    </Route>
                </Switch>
            </div>)
        )
    }
}

App.contextType = AuthContext

export default App
