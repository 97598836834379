import React  from 'react'
import { Modal } from 'antd'
import {ViewMajorMonotone} from '@shopify/polaris-icons'
import { Button } from '@shopify/polaris'
import ObjectInspector from "react-object-inspector"

const ViewPayload = (props) => {
    const {request} = props
    const {data = {}} = request
    const [modal, contextHolder] = Modal.useModal()

    const config = {
        title: 'Payload',
        width: '700px',
        content: (
            <ObjectInspector depth={0} path="root.value" data={data} />
        ),
    };

    return (
        <React.Fragment>
            <Button onClick={() => {
                modal.info(config);
            }} id="ViewPayload" icon={ViewMajorMonotone}/>

            {contextHolder}
        </React.Fragment>
    )
}
export default ViewPayload