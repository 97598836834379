const carriers = [
    {'value': 'DHLeCommerce', 'label': 'DHLeCommerce'},
    {
        'value': 'USPS',
        'label': 'USPS',
    },
    {'value': 'UPS', 'label': 'UPS'},
    {'value': 'Multrans', 'label': 'Multrans'},
    {'value': 'Australia Post', 'label': 'Australia Post'},
]

export default carriers
