import React from 'react'
import {FormLayout, TextField, TextStyle, Card, SkeletonBodyText} from '@shopify/polaris'
import {getUserData, setUserData} from '../../../services/AuthServices'
import {updateMe} from '../../../services/api/UserServices'
import {getMe} from '../../../services/api/UserServices2'
import safeTrim from '../../../helpers/safeTrim'
import {SAVE_PROFILE_ERROR, SAVE_PROFILE_SUCCESS, DISCARD_PROFILE_ERROR} from '../static/message'


class LoadingProfileDetails extends React.Component {
    render() {

        return (
            <Card
                title='Details'
                secondaryFooterActions={[{content: 'Discard'}]}
                primaryFooterAction={{content: 'Save'}}
            >
                <Card.Section>
                    <FormLayout>
                        <SkeletonBodyText lines={2}/>
                    </FormLayout>
                </Card.Section>
            </Card>
        )
    }
}

class ProfileDetails extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            name: '',
            email: '',
            role: '',
            status : '',
            isSubmit: false,
            successMessage: '',
        }
    }

    _setUserDataState = async (options) => {
        const {success, data} = await getMe()
        if (!success) throw new Error('Fail to get your account!')

        this.setState({
            isLoading: false,
            name: data.name || '',
            email: data.email || '',
            role: data.role || '',
            status: data.status || '',
            ...options,
        })
    }

    componentDidMount() {
        this._setUserDataState()
    }

    onChangeField = field => value => {
        this.setState({
            [field]: value,
        })
    }

    _updateProfile = async ({name}) => {
        const {success, data, message} = await updateMe({name})

        if (!success) throw new Error(message)

        setUserData(data)
    }

    handleSave = async () => {
        const {name} = this.state
        this.setState({
            isSubmit: true,
        })
        try {
            const _name = safeTrim(name)
            await this._updateProfile({name: _name})
            this._setUserDataState()
            this.setState({
                successMessage: SAVE_PROFILE_SUCCESS,
            })
        } catch (e) {
            this.setState({
                successMessage: '',
            })
            alert(e.message || SAVE_PROFILE_ERROR)
        }

        this.setState({
            isSubmit: false,
        })

    }

    handleDiscard = async () => {
        const {name} = getUserData()
        const {isSubmit} = this.state
        try {
            if (isSubmit) {
                await this._updateProfile({name})
                this._setUserDataState()
            } else {
                this.setState({
                    name,
                })
            }
        } catch (e) {
            return alert(e.message || DISCARD_PROFILE_ERROR)
        }

        this.setState({
            isSubmit: false,
        })
    }

    render() {
        const {name, email, role, status, isLoading, isSubmit, successMessage} = this.state
        const {name: _name} = getUserData()

        return (
            isLoading ?
                <LoadingProfileDetails/> :
                <Card
                    title='Details'
                    secondaryFooterActions={[{
                        content: 'Discard',
                        onAction: this.handleDiscard,
                        disabled: name === _name,
                    }]}
                    primaryFooterAction={{
                        content: 'Save',
                        loading: isSubmit,
                        onAction: this.handleSave,
                        disabled: name === _name,
                    }}
                >
                    <Card.Section>
                        <FormLayout>
                            <TextField
                                type='text'
                                label='Name'
                                value={name}
                                onChange={this.onChangeField('name')}
                            />
                            <TextField
                                disabled
                                type='text'
                                label='Role'
                                value={role}
                                onChange={this.onChangeField('role')}
                            />
                            <TextField
                                disabled
                                type='email'
                                label='Email'
                                value={email}
                            />
                            <TextField
                                disabled
                                type='status'
                                label='Status'
                                value={status}
                            />
                            <TextStyle variation='positive'>{successMessage}</TextStyle>
                        </FormLayout>
                    </Card.Section>
                </Card>
        )
    }
}

export default ProfileDetails
