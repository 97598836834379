import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {forceJob} from "../../../services/api/PublicTrackingServices"
import {RefreshMajorMonotone} from "@shopify/polaris-icons"
import {Button, Toast} from "@shopify/polaris"
import {Popconfirm} from "antd"

class ForceUpdateJob extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            error: '',
            active: false,
            success: false
        }
    }

    _onToggleActive = () => {
        this.setState({
            active: false
        })
    }

    _forceJob = async () => {
        this.setState({
            loading: true,
            error: '',
            active: false,
            success: false
        })

        try {
            const {source} = this.props
            const payload = {
                source: source
            }
            const response = await forceJob(payload)

            const {success, message} = response

            if (!success) {
                return this.setState({
                    loading: false,
                    error: message,
                    success: true,
                    active: true
                })
            }

            this.setState({
                loading: false,
                success: true,
                active: true
            }, () => {
                const {refresh} = this.props

                if (typeof refresh === 'function') {
                    refresh()
                }
            })
        } catch (e) {
            this.setState({
                loading: false,
                error: e.message
            })
        }
    }

    render() {
        const {status} = this.props
        const {loading, error, active, success} = this.state
        const toastMarkup = active ?
            (!!error && error.length > 0 ? <Toast content={error} onDismiss={this._onToggleActive} error={true}/>
                :
                <Toast content="Force job success!" onDismiss={this._onToggleActive} error={false}/>) : null

        return (
            <div className="ForceUpdateTracking">
                {
                    status === 'done' &&
                    <Popconfirm
                        title="Are you sure force job?"
                        onConfirm={this._forceJob}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button destructive icon={RefreshMajorMonotone} disabled={loading}>
                            Force job
                        </Button>
                    </Popconfirm>
                }

                {success && toastMarkup}
            </div>
        )
    }
}

ForceUpdateJob.propTypes = {
    source: PropTypes.string,
    status: PropTypes.string
}

export default ForceUpdateJob
