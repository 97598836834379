import React, { useState } from 'react'
import {CirclePlusMajorMonotone, ComposeMajorMonotone} from '@shopify/polaris-icons'
import { Button } from '@shopify/polaris'
import WebhookSimulatorModal from './WebhookSimulatorModal'

const WebhookSimulator = (props) => {
    const [visible, setVisible] = useState(false)

    const showModal = () => {
        setVisible(true)
    }

    return (
        <React.Fragment>
            <Button onClick={showModal} icon={ComposeMajorMonotone}>
                Webhook Simulator
            </Button>
            {visible && <WebhookSimulatorModal visible={visible} setVisible={setVisible} showModal={showModal} {...props}/>}
        </React.Fragment>
    )
}

export default WebhookSimulator