import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Card, SkeletonBodyText} from '@shopify/polaris'

class StatisticsListSkeleton extends Component {
    _makeSkeletonBodyText = count => {
        const {id} = this.props

        return (new Array(count)).fill(0, 0, count).map((e, i) => (
            <Card.Section key={id + '-' + i}>
                <SkeletonBodyText lines={1}/>
            </Card.Section>
        ))
    }

    render() {
        const {skeletonBodyText, title} = this.props

        const items = this._makeSkeletonBodyText(skeletonBodyText)

        return (
            <Card title={title}>
                {items}
            </Card>
        )
    }
}

StatisticsListSkeleton.propTypes = {
    skeletonBodyText: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
}

export default StatisticsListSkeleton
