import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {ChoiceList, InlineError, Modal, TextStyle} from '@shopify/polaris'
import roles from '../static/roles'
import {SAVE_UPDATE_ROLE_ERROR, SAVE_UPDATE_ROLE_SUCCESS} from '../static/message'

class UpdateRoleModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            role: props.role,
            message: {
                error: '',
                success: '',
            },
        }
    }

    _clickSave = async () => {
        const {save} = this.props
        const {role} = this.state

        try {
            await save({role})

            this.setState({
                message: {
                    error: '',
                    success: SAVE_UPDATE_ROLE_SUCCESS,
                },
            })
        } catch (e) {
            this.setState({
                message: {
                    error: e.message || SAVE_UPDATE_ROLE_ERROR,
                    success: '',
                },
            })
            alert(e.message || SAVE_UPDATE_ROLE_ERROR)
        }
    }

    render() {
        const {open, toggle, role, loading} = this.props
        const {message, role: _role} = this.state
        return (
            <Modal
                open={open}
                onClose={toggle}
                title="Change Role"
                primaryAction={{
                    content: 'Save',
                    onAction: this._clickSave,
                    loading: loading,
                    disabled: role === _role,
                }}
                secondaryActions={[
                    {
                        content: 'Close',
                        onAction: toggle,
                        disabled: loading,
                    },
                ]}
            >
                <Modal.Section>
                    <InlineError message={message.error}/>
                    <TextStyle variation='positive'>{message.success}</TextStyle>
                    <ChoiceList
                        title="Role"
                        choices={roles}
                        selected={_role}
                        onChange={([value]) => this.setState({role: value})}
                    />
                </Modal.Section>
            </Modal>
        )
    }
}

UpdateRoleModal.propTypes = {
    open: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    role: PropTypes.string.isRequired,
}


export default UpdateRoleModal
