import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {DataTable} from '@shopify/polaris'
import humanizeTime from '../../../helpers/humanizeTime'
import statuses from '../static/statuses'
import moment from 'moment'
import TrackingDetails from './TrackingDetails'
import ForceUpdateTracking from "./ForceUpdateTracking"


const cols = [
    'Tracking Number',
    'Carrier',
    'Origin Carrier',
    'Source',
    'Status',
    'Last update at',
    'Delivered at',
    'Actions'
]

const columnContentTypes = [
    'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text',
]

const tableCol = (cols) => {
    return cols.map(col => <p className='font-bold'>{col}</p>)
}

class TrackingNumberTable extends Component {
    state = {
        loading: {
            getEasyPost: '',
        },
    }

    formatRow = (data, row) => {
        if (Array.isArray(data)) {
            return (data && data.length > 0) ? data[data.length - 1][row] : ''
        }
        return data ? data[row] : ''
    }

    formatDate = (date, format = 'llll') => date ? moment(date).format(format) : ''

    _mapRows = data => {
        const {displayDetail, showDetail, reload} = this.props
        const rows = []

        data.forEach((item, index) => {
            const {
                id: _id,
                tracking_code,
                carrier,
                type,
                origin_carrier,
                data,
                updated_at,
                created_at,
                details,
                tracking_url
            } = item
            const tracking_details = data ? data.tracking_details : {}
            const public_url = this.formatRow(data, 'public_url')
            const status = this.formatRow(data, 'status')
            const delivered_at = this.getDelivered(data, type)
            const vNumber = tracking_code

            const parsedStatus = (statuses.find(({value}) => value === status) || {}).label || status
            const vStatus = <span className={status}>{parsedStatus}</span>
            const source = this.formatRow(tracking_details, 'source')
            const vDeliveredAt = this.formatDate(delivered_at)
            const forceUpdate = <ForceUpdateTracking reload={reload} trackingNumber={vNumber} carrier={carrier}/>
            let trackingDetails = ''
            const row = [
                vNumber, carrier, origin_carrier, source, vStatus,
                humanizeTime(updated_at || created_at), vDeliveredAt, forceUpdate,
                trackingDetails,
            ]
            // eslint-disable-next-line
            rows.push(row.map(col => {
                    if (col !== forceUpdate) {
                        // eslint-disable-next-line
                        return <a href='#' onClick={() => displayDetail(_id)} rel="noopener noreferrer">{col}</a>
                    } else {
                        return col
                    }
                })
            )

            if (showDetail[_id]) {
                trackingDetails = (
                    <TrackingDetails
                        key={index}
                        details={details}
                        url={tracking_url ? tracking_url : public_url}
                    >
                    </TrackingDetails>)
                rows.push([trackingDetails, '', '', '', '', '', ''])
            }
        })

        return rows
    }

    getDelivered = (data, type) => {
        if (type === 'trackingmore') {
            const trackings = data ? (data.origin_info || {}) : {}
            const trackingDetails = trackings ? (trackings.trackinfo || []) : []
            const tracking = trackingDetails.find(tracking => tracking.checkpoint_status === 'delivered')

            return tracking ? tracking.Date : ''
        } else {
            return this.formatRow(data, 'delivered_at')
        }
    }

    render() {
        const {data, page, limit} = this.props
        const rows = this._mapRows(data)
        const footer = `Showing ${(page - 1) * limit + 1} - ${data.length}`

        return (
            <DataTable
                columnContentTypes={columnContentTypes}
                headings={tableCol(cols)}
                rows={rows}
                footerContent={footer}
            />
        )
    }
}

TrackingNumberTable.propTypes = {
    total: PropTypes.number,
    data: PropTypes.array.isRequired,
    filter: PropTypes.object.isRequired,
    setFilter: PropTypes.func.isRequired,
    submitFilter: PropTypes.func.isRequired,
    displayDetail: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    showDetail: PropTypes.object.isRequired,
}

export default TrackingNumberTable
