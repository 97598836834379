const keys = {
    'name': 'Name',
    'url': 'Url',
    'secret_key': 'Secret',
    'status': 'Status',
    'deleted_at': 'Deleted time',
    'updated_at': 'Updated time',
    'created_at': 'Created time',
}

export default keys
