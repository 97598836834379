import React, {useEffect, useState} from 'react'
import {Card, Page} from '@shopify/polaris'
import {message, Pagination, Spin} from 'antd'
import {getTrackingWebhooksHistories} from '../../../services/api/WebhookSettingsServices'
import ListWebhookSetting from './ListWebhookSetting'
import TableFilter from './TableFilter'
import ReturnPage from './../../shared/ReturnPage'

const WebhookNotification = () => {
    const [query, setQuery] = useState({
        name: '',
        url: '',
        status: '',
        end_date: '',
        start_date: '',
        limit: 10,
        page: 1,
    })
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [total, setTotal] = useState(0)
    const webhookSettingsUrl = '/d/webhook-setting'

    const _handleChangePagination = (page, pageSize) => {
        setQuery({
            ...query,
            page: pageSize !== query.limit ? 1 : page,
            limit: pageSize
        })
    }

    const _fetchListWebhooks = async () => {
        setLoading(true)
        setData([])
        try {
            const {success, data: resp, message: error} = await getTrackingWebhooksHistories({...query})

            if (!success) {
                message.error(error)
            }

            const {total, histories} = resp
            setTotal(total)
            setData(histories)
        } catch (e) {
            message.error(e.message)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        _fetchListWebhooks()
    }, [query.page, query.limit, query.tracking_code, query.status, query.created_from, query.created_to])

    return (
        <>
            <Page subtitle={<ReturnPage url={webhookSettingsUrl} title='Webhook Setting'/>} fullWidth title="Webhook Notification ">
                <div className="ManageWebhooks TrackingMapping TrackingNumbers mb-3">
                    <div className="Toolbar">
                    </div>
                    <Spin spinning={loading} tip="Getting list data...">
                        <Card sectioned>
                            <Card.Subsection>
                                <TableFilter filter={query} setFilter={setQuery} loading={loading} />
                            </Card.Subsection>
                            <Card.Subsection>
                                <ListWebhookSetting
                                    total={total} data={data} loading={loading} page={query.page} limit={query.limit}
                                    fetchListWebhooks={_fetchListWebhooks}
                                />
                            </Card.Subsection>
                            <Card.Subsection>
                                <Pagination
                                    current={query.page}
                                    total={total}
                                    pageSize={query.limit}
                                    onChange={_handleChangePagination}
                                    className="text-right"
                                    showSizeChanger
                                    pageSizeOptions={['10', '20', '50', '100']}
                                />
                            </Card.Subsection>
                        </Card>
                    </Spin>
                </div>
            </Page>
        </>
    )
}

export default WebhookNotification
