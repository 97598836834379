import React from 'react'
import { Page } from '@shopify/polaris'
import TrackingSource from './TrackingSource'

function TrackingSourceContainer() {
    return (
        <Page fullWidth title="Tracking Source">
            <TrackingSource />
        </Page>
    )
}

export default TrackingSourceContainer