const keys = {
    'name': 'Name',
    'prefix': 'Prefix',
    'status': 'Status',
    'revoked_at': 'Revoked time',
    'created_at': 'Created time',
    'source': 'Source'
}

export default keys
