import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { Navigation } from '@shopify/polaris'
import { getUserData } from '../../../services/AuthServices'
import {
    ExchangeMajorMonotone,
    AnalyticsMajorMonotone,
    ShipmentMajorMonotone,
    ProfileMajorMonotone,
    CustomersMajorMonotone,
    KeyMajorMonotone,
    TeamMajorMonotone,
    JobsMajorMonotone,
    AffiliateMajorMonotone,
    ArchiveMinor,
    FolderMajorMonotone,
    RiskMajorMonotone,
} from '@shopify/polaris-icons'


const DashboardSidebar = props => {
    const route = useLocation()
    const history = useHistory()
    const { role } = getUserData()

    const handleGoto = React.useCallback((path) => {
        history.push(path)
    }, [history])

    const trackingItems = [
        {
            // url: '/d/statistics',
            label: 'Statistics',
            icon: AnalyticsMajorMonotone,
            onClick: () => handleGoto('/d/statistics'),
        },
        {
            // url: '/d/tracking-numbers',
            label: 'Tracking Numbers',
            icon: ShipmentMajorMonotone,
            onClick: () => handleGoto('/d/tracking-numbers'),
            role: ''
        },
        {
            label: 'Tracking Mapping',
            icon: ArchiveMinor,
            onClick: () => handleGoto('/d/tracking-mapping'),
            role: ''
        },
        {
            label: 'Tracking Mapping V2',
            icon: ArchiveMinor,
            onClick: () => handleGoto('/d/tracking-mapping-v2'),
            role: ''
        },
        {
            label: 'Tracking Source',
            icon: FolderMajorMonotone,
            onClick: () => handleGoto('/d/tracking-source'),
            role: 'admin'
        },
        // {
        //     label: 'Tracking Code Crawl',
        //     icon: RiskMajorMonotone,
        //     onClick: () => handleGoto('/d/tracking-code-crawl'),
        //     role: ''
        // },
    ]

    const accountItems = [
        {
            // url: '/d/profile',
            label: 'Profile',
            icon: ProfileMajorMonotone,
            onClick: () => handleGoto('/d/profile'),
            role: ''
        },
        {
            // url: '/d/security',
            label: 'Manage APIKeys',
            icon: KeyMajorMonotone,
            onClick: () => handleGoto('/d/manage-apikeys'),
            role: ''
        },
        {
            // url: '/d/security',
            label: 'Manage Webhooks',
            icon: TeamMajorMonotone,
            onClick: () => handleGoto('/d/manage-webhooks'),
            role: ''
        },
        // {
        //     // url: '/d/security',
        //     label: 'Manage Jobs',
        //     icon: JobsMajorMonotone,
        //     onClick: () => handleGoto('/d/manage-jobs'),
        //     role: ''
        // },
        {
            // url: '/d/security',
            label: 'Manage Proxy',
            icon: AffiliateMajorMonotone,
            onClick: () => handleGoto('/d/manage-proxy'),
            role: 'admin'
        },
        {
            // url: '/d/security',
            label: 'Webhook Setting ',
            icon: ExchangeMajorMonotone,
            onClick: () => handleGoto('/d/webhook-setting'),
            role: ''
        },
    ]

    const trackingItemsRender = role === 'admin' ? trackingItems : trackingItems.filter(i => i.role !== 'admin')
    const accountItemsRender = role === 'admin' ? accountItems : accountItems.filter(i => i.role !== 'admin')

    const TrackingSection = (
        <Navigation.Section
            title={'Tracking'}
            items={trackingItemsRender}
        />
    )

    const AccountSection = (
        <Navigation.Section
            title={'Account'}
            items={accountItemsRender}
        />
    )

    const AdministrationSection = (
        <Navigation.Section
            title={'Account'}
            items={[
                {
                    label: 'Manage users',
                    icon: CustomersMajorMonotone,
                    onClick: () => handleGoto('/d/manage-users'),
                },
            ]}
        />
    )


    return (
        <Navigation
            location={route.pathname}>
            {TrackingSection}
            {AccountSection}
            {role === 'admin' && AdministrationSection}
        </Navigation>
    )
}


export default DashboardSidebar
