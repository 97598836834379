import React from 'react'
import {FormLayout, TextField, TextStyle, Card, InlineError, Select, Form, ButtonGroup, Button} from '@shopify/polaris'
import safeTrim from '../../../helpers/safeTrim'
import PropTypes from 'prop-types'
import {generateSecretKey} from '../../../services/api/WebhookServices'

const STATUSES_OPTIONS = [
    {'value': 'in_active', 'label': 'Inactive', 'variation': 'subdued'},
    {'value': 'active', 'label': 'Active', 'variation': 'positive'},
]

const _parseWebhook = ({url, name, status, secret_key}) => {
    const webhook = {}

    if (safeTrim(name) !== '') webhook.name = name
    if (safeTrim(url) !== '') webhook.url = url
    if (safeTrim(status) !== '') webhook.status = status
    if (secret_key !== undefined) webhook.secret_key = secret_key

    return webhook
}

class WebhookDetail extends React.Component {
    constructor(props) {
        super(props)

        const {webhook} = props

        this.state = {
            isSubmit: false,
            message: {
                error: '',
                success: '',
            },
            webhook: {
                ...webhook,
            },
        }
    }

    _onChangeWebhook = field => value => {
        this.setState(state => ({
            webhook: {
                ...state.webhook,
                [field]: value,
            },
        }))
    }

    _handleSave = async () => {
        const {webhook} = this.state
        const _webhook = _parseWebhook(webhook)
        const {updateWebhook} = this.props

        this.setState({
            isSubmit: true,
        })

        try {
            const {data} = await updateWebhook(webhook._id, _webhook)

            this.setState({
                isSubmit: false,
                webhook: data,
                message: {
                    error: '',
                    success: 'Update successfully.',
                },
            })
        } catch (e) {
            this.setState({
                isSubmit: false,
                message: {
                    error: e.message || 'Update Error',
                    success: '',
                },
            })
            alert(e.message || 'Update Error')
        }
    }

    _generateSecret = async () => {
        try {
            const {success, data, message} = await generateSecretKey()

            if (!success) throw new Error(message)

            this.setState(({webhook}) => ({
                webhook: {
                    ...webhook,
                    secret_key: data
                }
            }))
        } catch (e) {
            alert(e.message || 'Generate secret error.')
        }
    }

    _handleDiscard = async () => {
        const {webhook} = this.props

        this.setState({webhook})
    }

    _canSave = ({name, url, status}) => {
        return name && url && status
    }

    _canDiscard = ({name: _name, url: _url, secret_key: _secret_key, status: _status}, {name, url, secret_key, status}) => {
        return (_name !== name || _url !== url || _status !== status || _secret_key !== secret_key)
    }

    render() {
        const {webhook, isSubmit, message} = this.state
        const {webhook: oldWebhook} = this.props
        const {name, url, status, secret_key} = webhook

        const canDiscard = this._canDiscard(oldWebhook, webhook) && !isSubmit
        const canSave = this._canSave(webhook) && canDiscard

        return (
            <React.Fragment>
                <Card
                    title='Webhook Detail'
                >
                    <Card.Section>
                        <Form onSubmit={this._handleSave}>
                            <FormLayout>
                                <TextField
                                    type='text'
                                    label='Name'
                                    value={name}
                                    onChange={this._onChangeWebhook('name')}
                                    disabled={status === 'deleted'}
                                />
                                <TextField
                                    type='url'
                                    label='Url'
                                    value={url}
                                    onChange={this._onChangeWebhook('url')}
                                    disabled={status === 'deleted'}
                                />
                                <TextField
                                    type='text'
                                    label='Secret'
                                    value={secret_key}
                                    onChange={this._onChangeWebhook('secret_key')}
                                    disabled={status === 'deleted'}
                                    labelAction={{content: 'Generate', onAction: this._generateSecret, disabled: status === 'deleted'}}
                                    helpText="We’ll use this secret for secure your webhook."
                                />
                                {status === 'deleted' ?
                                    <TextField
                                        type='text'
                                        label='Status'
                                        value={status}
                                        onChange={this._onChangeWebhook('status')}
                                        disabled
                                    /> :
                                    <Select
                                        options={STATUSES_OPTIONS}
                                        label='Status'
                                        value={status}
                                        onChange={this._onChangeWebhook('status')}
                                    />
                                }
                                <InlineError message={message.error}/>
                                <TextStyle variation='positive'>{message.success}</TextStyle>
                            </FormLayout>
                            <div className='pt-6'>
                                <ButtonGroup>
                                    <Button disabled={!canDiscard} onClick={this._handleDiscard}>Discard</Button>
                                    <Button submit primary loading={isSubmit} disabled={!canSave}>Save</Button>
                                </ButtonGroup>
                            </div>
                        </Form>
                    </Card.Section>
                </Card>
            </React.Fragment>
        )
    }
}

WebhookDetail.propTypes = {
    webhook: PropTypes.object.isRequired,
    _id: PropTypes.string.isRequired,
    fetchWebhook: PropTypes.func.isRequired,
    updateWebhook: PropTypes.func.isRequired,
}

export default WebhookDetail
