import React, {useEffect, useState} from 'react'
import getHistory from "../../../store/getHistory"
import {Button, Card, Page} from '@shopify/polaris'
import { Pagination, Spin, message } from "antd"
import {
    addTrackingWebhooks,
    getTrackingWebhooks,
    getTrackingWebhooksExample,
} from '../../../services/api/WebhookSettingsServices'
import ListWebhookSetting from './ListWebhookSetting'
import TableFilter from './TableFilter'
import WebhookSettingCreate from './WebhookSettingCreate'
import {NotificationMajorMonotone} from '@shopify/polaris-icons'
import WebhookSimulator from './WebhookSimulator'
import PayloadExample from './PayloadExample'

const WebhookSetting = () => {
    const [query, setQuery] = useState({
        name: '',
        url: '',
        status: '',
        end_date: '',
        start_date: '',
        limit: 10,
        page: 1,
    })
    const [events, setEvents] = useState([])
    const [allEvents, setAllEvents] = useState([])
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [total, setTotal] = useState(0)
    const [isCheckAll, setIsCheckAll] = useState(false)
    const [totalCheck, setTotalCheck] = useState([])
    const history = getHistory()

    const _handleChangePagination = (page, pageSize) => {
        setQuery({
            ...query,
            page: pageSize !== query.limit ? 1 : page,
            limit: pageSize
        })
    }

    const _fetchListWebhooks = async () => {
        setLoading(true)
        setData([])
        setTotalCheck([])
        setIsCheckAll(false)
        try {
            const {success, data: resp, message: error} = await getTrackingWebhooks({...query})

            if (!success) {
                message.error(error)
            }

            const {total, webhooks} = resp
            setTotal(total)
            setData(webhooks)
        } catch (e) {
            message.error(e.message)
        } finally {
            setLoading(false)
        }
    }

    const _fetchListWebhooksExapmle = async () => {
        try {
            const {success, data: resp, message: error} = await getTrackingWebhooksExample()

            if (!success) {
                console.log(error)
            }
            const value = resp.length > 0 ? resp.map(item => {
                return {value: item.event, label: item.event}
            }) : []
            setAllEvents(resp)
            setEvents([{value: '', label: 'Select event'},...value])
        } catch (e) {
            console.log(e.message)
        }
    }

    const handleSelectAll = () => {
        const totalCheck = data.map((li) => li)
        setIsCheckAll(!isCheckAll)
        setTotalCheck(isCheckAll ? [] : totalCheck)
    }

    const handleCheck = (e) => {
        const { data, checked } = e.target
        setTotalCheck(!checked ? totalCheck.filter((item) => item !== data) : [...totalCheck, data])
    }

    const handleClickAdd = async (fields) => {
        try {
            const {success, message: error} = await addTrackingWebhooks(fields)

            if (!success) {
                return message.error(error)
            }

            message.success('Add Webhook successfully!')
            _fetchListWebhooks()
        } catch (e) {
            message.error(e.message)
        }
    }

    useEffect(() => {
        _fetchListWebhooks()
        _fetchListWebhooksExapmle()
    }, [query.page, query.limit, query.tracking_code, query.status, query.created_from, query.created_to])

    return (
        <Page fullWidth title="Webhook Setting ">
            <div className="ManageWebhooks TrackingMapping TrackingNumbers mb-3">
                <div className="Toolbar">
                    <WebhookSettingCreate events={events} handleClickAdd={handleClickAdd}/>
                    <Button icon={NotificationMajorMonotone} onClick={() =>  history.push('/d/webhook-notification')}>
                        Webhook Notification
                    </Button>
                    <WebhookSimulator events={events}/>
                    <PayloadExample events={events} allEvents={allEvents}/>
                </div>
                <Spin spinning={loading} tip="Getting list data...">
                    <Card sectioned>
                        <Card.Subsection>
                            <TableFilter filter={query} setFilter={setQuery} loading={loading} />
                        </Card.Subsection>
                        <Card.Subsection>
                            <ListWebhookSetting
                                total={total} data={data} loading={loading} page={query.page} limit={query.limit}
                                isCheckAll={isCheckAll}
                                handleSelectAll={handleSelectAll}
                                onCheck={handleCheck}
                                totalCheck={totalCheck}
                                setTotalCheck={setTotalCheck}
                                setIsCheckAll={setIsCheckAll}
                                fetchListWebhooks={_fetchListWebhooks}
                            />
                        </Card.Subsection>
                        <Card.Subsection>
                            <Pagination
                                current={query.page}
                                total={total}
                                pageSize={query.limit}
                                onChange={_handleChangePagination}
                                className="text-right"
                                showSizeChanger
                                pageSizeOptions={['10', '20', '50', '100']}
                            />
                        </Card.Subsection>
                    </Card>
                </Spin>
            </div>
        </Page>
    )
}

export default WebhookSetting
