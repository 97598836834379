import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Button, ButtonGroup, DataTable, TextStyle} from '@shopify/polaris'
import {withRouter, Link} from 'react-router-dom'
import humanizeTime from '../../../helpers/humanizeTime'
import statuses from '../static/statuses'
import {deleteWebhookById} from '../../../services/api/WebhookServices'
import WebhookDeleteModal from './WebhookDeleteModal'

const tableCol = ['Name', 'Url', 'Secret', 'Status', 'Deleted time', 'Updated time', 'Created time', 'Actions']

const columnContentTypes = [
    'text', 'text', 'text', 'text', 'text', 'text', 'text'
]

class ListWebhook extends Component {
    state = {
        modal: {
            _id: '',
            index: -1,
            toggle: false,
            loading: false
        },
    }

    _toggleModal = (_id, index) => {
        this.setState(({modal}) => ({
            modal: {
                ...modal,
                _id,
                index,
                toggle: !modal.toggle
            },
        }))
    }

    _toggle = () => {
        this.setState(({modal}) => ({
            modal: {
                ...modal,
                toggle: !modal.toggle
            },
        }))
    }

    _deleteWebhook = async () => {
        const {_id, index} = this.state.modal
        this.setState(({modal}) => ({
            modal: {
                ...modal,
                loading: true
            }
        }))
        const {success, data, message} = await deleteWebhookById(_id)

        this.setState(({modal}) => ({
            modal: {
                ...modal,
                loading: true
            }
        }))
        if (!success) throw new Error(message)

        this._toggle()
        this.props.update(index, data)
    }

    _mapRows = data => {
        const {location} = this.props

        return data.map((item = {}, index) => {
            const {_id, name, url, secret_key, status, deleted_at, updated_at, created_at} = item

            const viewName = name
            const viewUrl = url
            const viewSecret = secret_key && secret_key.split('').map((ch, i) => {
                return '*'
            }).join('')

            const variationStatus = (statuses.find(({value}) => value === status) || {}).variation || 'subdued'
            const viewStatus = <TextStyle variation={variationStatus}>{status}</TextStyle>

            const actions = status !== 'deleted' &&
                <ButtonGroup>
                    <Link to={`${location.pathname}/${_id}`}>
                        <Button size='slim'>Edit</Button>
                    </Link>

                    <Button size='slim' onClick={() => this._toggleModal(_id, index)} destructive>delete</Button>
                </ButtonGroup>

            return [
                viewName, viewUrl, viewSecret, viewStatus,
                (deleted_at && humanizeTime(deleted_at)) || null,
                (updated_at && humanizeTime(updated_at)) || null,
                humanizeTime(created_at),
                actions
            ]
        })
    }

    render() {
        const {data, total, paging} = this.props
        const {loading, toggle} = this.state.modal
        const rows = this._mapRows(data)
        const page = paging.page
        const limit = paging.limit
        const startShow = (page - 1) * limit + 1 > total ? total : (page - 1) * limit + 1
        const endShow = page * limit > total ? total : page * limit
        const footer = `Showing ${startShow} - ${endShow} of ${total} results`
        return (
            <React.Fragment>
                <WebhookDeleteModal toggle={this._toggle} open={toggle} loading={loading} save={this._deleteWebhook}/>
                <DataTable
                    columnContentTypes={columnContentTypes}
                    headings={tableCol}
                    rows={rows}
                    footerContent={footer}
                />
            </React.Fragment>
        )
    }
}

ListWebhook.propTypes = {
    total: PropTypes.number.isRequired,
    data: PropTypes.array.isRequired,
    filter: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    paging: PropTypes.object.isRequired,
    update: PropTypes.func.isRequired,
}

export default withRouter(ListWebhook)
