import React, { Component } from 'react'
import { getListUser } from '../../../services/api/UserServices'
import ListUser from './ListUser'
import { Card, Page, Toast } from '@shopify/polaris'
import FilterUser from './FilterUser'
import Pagination from '../../shared/Pagination'
import InsertUser from './InsertUser'

class ManageUsers extends Component {
    timeoutLoading = null

    state = {
        query: {
            name: '',
            email: '',
            role: '',
            status: '',
            end_date: '',
            start_date: '',
        },
        paging: {
            limit: 100,
            page: 1,
        },
        fetching: {
            loading: false,
            err: null,
        },
        users: {
            data: [],
            total: 0,
        },
        showToast: false
    }

    componentDidMount() {
        this._fetchListUsers()
    }

    _insertUser = item => {
        const { users } = this.state
        const { data, total } = users

        return this.setState({
            users: {
                data: [item, ...data],
                total: total + 1,
            },
        })
    }

    _setQuery = (filter, delayLoading = false) => this.setState(({ query, paging }) => ({
        query: {
            ...query, ...filter,
        },
        paging: { ...paging, page: 1 },
    }), () => {
        if (!delayLoading) return this._fetchListUsers()

        if (this.timeoutLoading) clearTimeout(this.timeoutLoading)
        this.timeoutLoading = setTimeout(async () => await this._fetchListUsers(), 1000)
    })

    _setFetching = (loading, err = null) => {
        const { users } = this.state
        this.setState({
            fetching: { loading, err },
            users: {
                ...users,
                ...loading ? { data: [] } : {},
            },
            showToast: !!err
        })
    }

    _submitQuery = () => {
        const { paging } = this.state
        this.setState({ paging: { ...paging, page: 1 } }, () => this._fetchListUsers())
    }

    _switchPage = page => () => {
        const { paging } = this.state

        this.setState({
            paging: { ...paging, page },
        }, () => this._fetchListUsers())
    }

    _fetchListUsers = async () => {
        const { query, paging, fetching } = this.state
        if (fetching.loading) return

        this._setFetching(true)

        try {
            const { success, data: resp, message } = await getListUser({ ...query, ...paging })

            if (!success) throw new Error(message)

            this._setFetching(false, message)
            const { total, data } = resp
            this.setState({ users: { data, total } })
        } catch (e) {
            this._setFetching(false, e.message)
        }
    }

    toggleActive = () => this.setState({ showToast: false })

    render() {
        const { users, query, fetching, paging, showToast } = this.state
        const { total, data } = users
        const { loading } = fetching
        const totalPage = Math.ceil(total / paging.limit)
        const label = (totalPage > 0 && paging.page + '/' + totalPage) || null
        const toastMarkup = showToast ? (
            <Toast content={`${fetching.err}`} error onDismiss={this.toggleActive} />
        ) : null;

        return (
            <Page fullWidth title="Manage Users">
                {toastMarkup}
                <div className="ManageUsers">
                    <div className="Toolbar">
                        <InsertUser insert={this._insertUser} />
                    </div>
                    <Card sectioned>
                        <Card.Subsection>
                            <FilterUser query={query} setQuery={this._setQuery} loading={loading} />
                        </Card.Subsection>
                        <Card.Subsection>
                            <ListUser
                                total={total} data={data} loading={loading}
                                filter={query} setFilter={this._setQuery}
                                submitFilter={this._submitQuery} paging={paging} />
                        </Card.Subsection>
                        <Card.Subsection>
                            <Pagination label={label} showQuickJumper onSwitchPage={this._switchPage}
                                amount={totalPage} current={paging.page} />
                        </Card.Subsection>
                    </Card>
                </div>
            </Page>
        )
    }
}

export default ManageUsers
