import createAPIServices from './createApiServices'

const baseUrl = process.env.NODE_ENV === 'production' ? '/api/user-service/api-keys' : 'https://staging-tracking-v2.merchize.com/api/user-service/api-keys'
const api = createAPIServices({baseUrl})

export const getListAPIKey = ({name, prefix, status, limit, page, start_date, end_date}) => {
    return api.makeAuthRequest({
        url: '',
        method: 'get',
        params: {name, prefix, status, limit, page, start_date, end_date}
    })
}

export const getAPIKeyById = (_id) => {
    return api.makeAuthRequest({
        url: `/${_id}`,
        method: 'get',
    })
}

export const generateAPIKey = ({name, scopes}) => {
    return api.makeAuthRequest({
        url: `/generate`,
        method: 'post',
        data: {name, scopes},
    })
}

export const revokeAPIKeyById = (_id) => {
    return api.makeAuthRequest({
        url: `/revoke/${_id}`,
        method: 'get',
    })
}

export const revokeAPIKey = (api_key) => {
    return api.makeAuthRequest({
        url: `/${api_key}/revoke`,
        method: 'get',
    })
}

export const updateAPIKeyById = (_id, {name, scopes}) => {
    return api.makeAuthRequest({
        url: `/${_id}`,
        method: 'put',
        data: {name, scopes}
    })
}

export default api