import React, { useState, useEffect } from 'react'
import { Card, Button} from '@shopify/polaris'
import { Pagination, Input, Spin, Select, Modal, message, Checkbox,} from "antd"
import 'antd/dist/antd.css'
import carriers from "../static/carriers"
import tracking_status from "../static/statuses"
import tracking_types from "../static/types"
import locations from "../static/locations"
import { getMapping, deleteMapping, createMapping, editMapping, getMappingV2, createMappingV2, editMappingV2, deleteMappingV2 } from '../../../services/api/TrackingMappingServices'
import TrackingMappingCreate from './TrackingMappingCreate'
import TrackingMappingTable from './TrackingMappingTable'
import BulkActionMapping from './BulkActionMapping'


const TrackingMapping = () => {

    const [mapping, setMapping] = useState([])
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(50)
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [carrier, setCarrier] = useState([])
    const [trackingStatus, setTrackingStatus] = useState([])
    // const [locationOptions, setLocationOptions] = useState([])
    const [location, setLocation] = useState([])
    const [type, setType] = useState([])
    const [isCheckAll, setIsCheckAll] = useState(false)
    const [totalCheck, setTotalCheck] = useState([])
    const [processList, setProcessList] = useState([])
    const [popoverActive, setPopoverActive] = useState(true)

    const handleSelectAll = (e) => {
        setIsCheckAll(!isCheckAll)
        setTotalCheck(mapping.map((li) => li))
        if (isCheckAll) {
          setTotalCheck([])
        }
    }

    const handleCheck = (e) => {
        const { data, checked } = e.target
        setTotalCheck([...totalCheck, data])
        if (!checked) {
          setTotalCheck(totalCheck.filter((item) => item !== data))
        }
    }

    const _handleChangePagination = (page, pageSize) => {
        setPage(pageSize !== limit ? 1 : page)
        setLimit(pageSize)
    }

    const _onSearch = query => {
        setSearch(query)
        setPage(1)
    }

    const _handleChangeCarrier = carrier => {
        setCarrier(carrier)
        setPage(1)
    }

    const _handleChangeStatuses = status => {
        setTrackingStatus(status)
        setPage(1)
    }

    const _handleChangeLocation = location => {
        setLocation(location)
        setPage(1)
    }

    const _handleChangeType = type => {
        setType(type)
        setPage(1)
    }

    const fetchTrackingMapping = async () => {
        setLoading(true)
        setError('')
        setMapping([])
        setTotalCheck([])
        setIsCheckAll(false)
        try {

            const payload = {
                page, limit
            }

            if(search) payload.status = search
            if(carrier.length > 0) payload.carrier = carrier
            if(trackingStatus.length > 0) payload.status_mapping = trackingStatus
            if(location.length > 0) payload.location = location
            if(type.length > 0) payload.type = type

            const response = await getMappingV2(payload)
            const { success, data, message } = response

            if (!success) {
                setLoading(false)
                setError(message)
            }

            setMapping(data.data)
            setTotal(data.total)
            // setLocationOptions(locationOptions)
            setLoading(false)
        } catch (e) {
            setLoading(false)
            setError(e.message)
        }
    }

    const _deleteMap = async (id) => {
        try {
            const response = await deleteMappingV2(id)
            const { message: mess, success } = response
            if (!success) return message.error(`${mess}!`)
            message.success('Deleted successfully!')
            fetchTrackingMapping()
        } catch (e) {
            message.error(e.message || 'Unknown error.')
        }
    }

    const handleClickDelete = (e, id) => {
        e.preventDefault()
        Modal.confirm({
            title: 'Confirm Delete',
            icon: '',
            content: 'Are you sure you want to delete?',
            okText: 'Ok',
            cancelText: 'Cancel',
            onOk: () => _deleteMap(id)
        })
    }

    const _createMapp = async (carrier, status, status_mapping, type, location) => {
        setLoading(true)
        try {
            const response = await createMappingV2({ carrier, status, status_mapping, type, location })
            const { message: mess, success } = response
            if (!success) return message.error(`${mess}!`)
            message.success('Create successfully!')
            fetchTrackingMapping()
        } catch (e) {
            message.error(e.message || 'Unknown error.')
        } finally {
            setLoading(false)
        }
    }

    const _editMapp = async (id, status_mapping, type, location) => {
        
        setLoading(true)
        const payload = {
            status_mapping,
            type,
            location
        }
        try {
            const response = await editMappingV2(id, payload)
            const { message: mess, success } = response
            if (!success) return message.error(`${mess}!`)
            message.success('Edit successfully!')
            fetchTrackingMapping()
        } catch (e) {
            message.error(e.message || 'Unknown error.')
        } finally {
            setLoading(false)
        }
    }

    const _multipleEditMapp = async (old_data, new_data) => {
        setLoading(true)
        const payload = {
            old_data, new_data
        }
        const response = await editMapping(payload)
        return response
        
    }

    useEffect(() => {
        fetchTrackingMapping()
    }, [page, limit, carrier, trackingStatus, search, location, type])

    const togglePopoverActive = () => {
        setPopoverActive((popoverActive) => !popoverActive)
    }
      
  
    const activator = (
      <Button onClick={togglePopoverActive} disclosure>
        Actions
      </Button>
    )

    return (
        <React.Fragment>
            <TrackingMappingCreate refreshTrackingMapping={fetchTrackingMapping}/>
            <div className="TrackingMapping TrackingMappingV2">
                <Spin spinning={loading} tip="Getting list mapping...">
                    <Card sectioned>
                        <Card.Subsection>
                            <div className="TrackingFilter">
                                <div className="TrackingSearch">
                                    <Input.Search
                                        placeholder="Find by Last note"
                                        onChange={(e) =>
                                            _onSearch(e.target.value)
                                        }
                                        value={search}
                                        style={{width: '250px'}}
                                        allowClear
                                    />
                                </div>
                                <div className="ListFilter">
                                    <div className="TrackingCarrier">
                                        <div className="Filters">
                                            <label htmlFor="carrier">
                                                Carriers
                                            </label>
                                            <Select
                                                value={carrier}
                                                mode="multiple"
                                                onChange={_handleChangeCarrier}
                                                optionLabelProp="label"
                                                placeholder="Select carriers"
                                                defaultValue={carrier}
                                                allowClear
                                            >
                                                {carriers.length > 0 &&
                                                    carriers.map(
                                                        (item, index) => (
                                                            <Select.Option
                                                                key={index}
                                                                value={
                                                                    item.value
                                                                }
                                                            >
                                                                {item.label}
                                                            </Select.Option>
                                                        )
                                                    )}
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="TrackingStatuses">
                                        <div className="Filters">
                                            <label htmlFor="status">
                                                Tracking Status
                                            </label>
                                            <Select
                                                value={trackingStatus}
                                                onChange={_handleChangeStatuses}
                                                mode="multiple"
                                                maxTagCount="3"
                                                optionLabelProp="label"
                                                defaultValue={trackingStatus}
                                                placeholder="Select Tracking Status"
                                                allowClear
                                                options={tracking_status}
                                            />
                                        </div>
                                    </div>

                                    <div className="TrackingLocation">
                                        <div className="Filters">
                                            <label htmlFor="location">
                                                Location
                                            </label>

                                            <Select
                                                value={location}
                                                onChange={_handleChangeLocation}
                                                mode="multiple"
                                                maxTagCount="3"
                                                // optionLabelProp="label"
                                                // defaultValue={trackingStatus}
                                                placeholder="Select location"
                                                allowClear
                                                options={locations}
                                            />
                                        </div>
                                    </div>

                                    <div className="TrackingType">
                                        <div className="Filters">
                                            <label htmlFor="type">
                                                Type
                                            </label>

                                            <Select
                                                value={type}
                                                onChange={_handleChangeType}
                                                mode="multiple"
                                                maxTagCount="3"
                                                // optionLabelProp="label"
                                                // defaultValue={trackingStatus}
                                                placeholder="Select type"
                                                allowClear
                                                options={tracking_types}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card.Subsection>
                        <Card.Subsection>
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        {/* <th>
                                            <Checkbox
                                                checked={isCheckAll}
                                                onChange={handleSelectAll}
                                            />
                                        </th> */}
                                        {/* {totalCheck.length > 0 && (
                                            <BulkActionMapping 
                                                popoverActive={popoverActive}
                                                activator={activator}
                                                togglePopoverActive={togglePopoverActive}
                                                totalCheck={totalCheck}
                                                setTotalCheck={setTotalCheck}
                                                setIsCheckAll={setIsCheckAll}
                                                editMapp={_multipleEditMapp}
                                                processList={processList}
                                                setProcessList={setProcessList}
                                                setLoading={setLoading}
                                            />
                                        )} */}
                                        {/* {totalCheck.length <= 0 && ( */}
                                            <>
                                                <th>Last note</th>
                                                <th>Tracking status</th>
                                                <th>Carrier</th>
                                                <th>Location</th>
                                                <th>Type</th>
                                                <th>Created at</th>
                                                <th>Updated at</th>
                                                <th>Actions</th>
                                            </>
                                        {/* )} */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {!!mapping &&
                                        mapping.length > 0 &&
                                        mapping.map((item, index) => (
                                            <TrackingMappingTable
                                                key={index}
                                                index={index}
                                                item={item}
                                                totalCheck={totalCheck}
                                                onCheck={handleCheck}
                                                handleClickDelete={
                                                    handleClickDelete
                                                }
                                                refreshTrackingMapping={fetchTrackingMapping}
                                            />
                                        ))}

                                    {!!error && error.length > 0 && (
                                        <tr>
                                            <td
                                                style={{color: 'red'}}
                                                colSpan={7}
                                            >
                                                {error}
                                            </td>
                                        </tr>
                                    )}

                                    {!!mapping && mapping.length === 0 && (
                                        <tr>
                                            <td colSpan={7}>No record</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </Card.Subsection>
                        <Card.Subsection>
                            <Pagination
                                current={page}
                                total={total}
                                pageSize={limit}
                                onChange={_handleChangePagination}
                                className="text-right"
                                showSizeChanger
                                pageSizeOptions={['10', '20', '50', '100']}
                            />
                        </Card.Subsection>
                    </Card>
                </Spin>
            </div>
        </React.Fragment>
    )
}

export default TrackingMapping