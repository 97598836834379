import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DataTable, Link, TextStyle } from '@shopify/polaris'
import { withRouter } from 'react-router-dom'
import humanizeTime from '../../../helpers/humanizeTime'
import statuses from '../static/statuses'

const tableCol = ['Name', 'Email', 'Role', 'Status', 'Updated time', 'Created time']

const columnContentTypes = [
    'text', 'text', 'text', 'text', 'text', 'text',
]

class ListUser extends Component {
    state = {}

    _mapRows = data => {
        return data.map((item = {}) => {
            const { _id, name, email, role, status, updated_at, created_at } = item

            const viewName = this._userLink({ _id, name, email, role, status, updated_at, created_at })
            const viewEmail = email
            const viewRole = role

            const variationStatus = (statuses.find(({ value }) => value === status) || {}).variation || 'subdued'
            const viewStatus = <TextStyle variation={variationStatus}>{status}</TextStyle>

            return [
                viewName, viewEmail, viewRole, viewStatus,
                humanizeTime(updated_at),
                humanizeTime(created_at),
            ]
        })
    }

    _userLink = ({ _id, name, email, role, status, updated_at, created_at }) => {
        const { history } = this.props

        return (
            <Link
                accessibilityLabel={`View details for ${name}`}
                onClick={() => history.push(`/d/manage-users/user/view`, {
                    params: { _id },
                    user: { _id, name, email, role, status, updated_at, created_at },
                })}
            >{name || email}</Link>
        )
    }

    render() {
        const { data, total, paging } = this.props
        const rows = this._mapRows(data)
        const page = paging.page
        const limit = paging.limit
        const startShow = (page - 1) * limit + 1 > total ? total : (page - 1) * limit + 1
        const endShow = page * limit > total ? total : page * limit
        const footer = `Showing ${startShow} - ${endShow} of ${total} results`
        return (
            <DataTable
                columnContentTypes={columnContentTypes}
                headings={tableCol}
                rows={rows}
                footerContent={footer}
            />
        )
    }
}

ListUser.propTypes = {
    total: PropTypes.number.isRequired,
    data: PropTypes.array.isRequired,
    filter: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    paging: PropTypes.object.isRequired,
}

export default withRouter(ListUser)
