import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Card, ResourceItem, ResourceList, TextStyle} from '@shopify/polaris'

class StatisticsList extends Component {
    render() {
        const {data: stats} = this.props
        const {title, data, total} = stats
        
        const parsedData = [
                ...data.map(item => ({
                title: item._id,
                value: item.total_trackers,
                id: item._id,
            })),
            {id: 'all-value', title: 'Total', value: total},
        ]

        return (
            <Card title={title}>
                <ResourceList
                    items={parsedData}
                    renderItem={({id, title, value}) => (
                        <ResourceItem id={id}>
                            <div className="flex justify-between">
                                <TextStyle variation="strong">{title}</TextStyle>
                                <TextStyle>{Number(value) === 0 ? 0 : isNaN(value) ? value + '' : Number(value).toLocaleString()}</TextStyle>
                            </div>
                        </ResourceItem>)}
                />
            </Card>
        )
    }
}

StatisticsList.propTypes = {
    data: PropTypes.object.isRequired,
}

export default StatisticsList
