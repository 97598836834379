import React from 'react'
import {CalloutCard} from '@shopify/polaris'
import {resetPass} from '../../../services/api/UserServices'
// import {SAVE_USER_PROFILE_ERROR, SAVE_USER_PROFILE_SUCCESS, DISCARD_USER_PROFILE_ERROR} from "../static/message"
import ResetPasswordModal from './ResetPasswordModal'
import PropTypes from 'prop-types'

class UserSecurity extends React.Component {
    state = {
        loading: false,
        toggle: false,
    }

    _resetPass = async ({newPassword}) => {
        const {_id} = this.props
        this.setState({
            loading: true,
        })
        const {success, /*data,*/ message} = await resetPass(_id, {newPassword})

        if (!success) throw new Error(message)
  
        this.setState({
            loading: false,
        })
    }

    _toggleModal = () => {
        this.setState(state => ({
            toggle: !state.toggle,
        }))
    }

    render() {
        const {toggle, loading} = this.state
        const {user} = this.props
        const {name} = user

        return (
            <React.Fragment>
                <ResetPasswordModal
                    open={toggle}
                    loading={loading}
                    toggle={this._toggleModal}
                    save={this._resetPass}
                />
                <CalloutCard
                    title="Security"
                    primaryAction={{
                        content: 'Reset password',
                        onAction: this._toggleModal,
                    }}
                >
                    <p>Reset the <strong>{name || 'user'}</strong>'s password.</p>
                </CalloutCard>
            </React.Fragment>
        )
    }
}

UserSecurity.propTypes = {
    user: PropTypes.object.isRequired,
    _id: PropTypes.string.isRequired,
    fetchUser: PropTypes.func.isRequired,
    setViewUserState: PropTypes.func.isRequired,
}

export default UserSecurity
