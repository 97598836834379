import React, {Component} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import ManageUsers from './ManageUsers'
import ViewUser from './ViewUser'

class ManageUsersContainer extends Component {

    render() {
        const {basePath} = this.props

        return (
            <Switch>
                <Route exact path={`${basePath}/manage-users`} render={() => <ManageUsers {...this.props}/>}/>
                <Route exact path={`${basePath}/manage-users/user/view`} component={ViewUser}/>
                <Route path={'*'}>
                    <Redirect to={`${basePath}/manage-users`}/>
                </Route>
            </Switch>
        )
    }
}

export default ManageUsersContainer
