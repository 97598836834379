import React, {Component} from 'react'
import Statistics from './StatisticsNew'
import {Page} from '@shopify/polaris'

class StatisticsContainer extends Component {

    render() {
        return (
            <Page fullWidth title="Statistics">
                <Statistics {...this.props}/>
            </Page>
        )
    }
}

export default StatisticsContainer
