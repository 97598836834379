import React from 'react'
import {CalloutCard} from '@shopify/polaris'
import WebhookDeleteModal from './WebhookDeleteModal'
import PropTypes from 'prop-types'

class WebhookDelete extends React.Component {
    state = {
        loading: false,
        toggle: false,
    }

    _toggleModal = () => {
        this.setState(state => ({
            toggle: !state.toggle,
        }))
    }

    _deleteWebhook = async () => {
        const {_id, deleteWebhook, history} = this.props
        this.setState({
            loading: true,
        })

        const {success, /*data,*/ message} = await deleteWebhook(_id)

        this.setState({loading: false})

        if (!success) throw new Error(message)

        history.goBack()
    }

    render() {
        const {loading, toggle} = this.state
        const {webhook} = this.props
        const {name, status} = webhook

        return (
            <React.Fragment>
                <WebhookDeleteModal toggle={this._toggleModal} open={toggle} save={this._deleteWebhook} loading={loading}/>
                <CalloutCard
                    title="Delete webhook"
                    primaryAction={{
                        content: 'Delete webhook',
                        onAction: this._toggleModal,
                        destructive: true,
                        disabled: status === 'deleted' || loading,
                    }}
                >
                    <p>Delete the <strong>{name || 'noname'}</strong>'s webhook.</p>
                </CalloutCard>
            </React.Fragment>
        )
    }
}

WebhookDelete.propTypes = {
    webhook: PropTypes.object.isRequired,
    _id: PropTypes.string.isRequired,
    fetchWebhook: PropTypes.func.isRequired,
    deleteWebhook: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
}

export default WebhookDelete
