import React, {Component} from 'react'
import {Button} from '@shopify/polaris'
import ImportCsvModal from './ImportCsvModal'
import {ImportMinor} from '@shopify/polaris-icons'

class ImportCsvButton extends Component {
    state = {
        toggle: false
    }

    _toggle = () => this.setState({toggle: !this.state.toggle})

    render() {
        const {toggle} = this.state
        const {fetchImport} = this.props

        return (
            <div className="ImportCsvButton">
                <Button onClick={this._toggle} icon={ImportMinor}>
                    Import CSV
                </Button>
                <ImportCsvModal open={toggle} toggle={this._toggle} fetchImport={fetchImport}/>
            </div>
        )
    }
}

export default ImportCsvButton
