import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {forceUpdateTracking} from "../../../services/api/TrackingNumberServices"
import {RefreshMajorMonotone} from "@shopify/polaris-icons"
import {Button, Toast} from "@shopify/polaris"

class ForceUpdateTracking extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            error: '',
            active: false,
            success: false
        }
    }

    _onToggleActive = () => {
        this.setState({
            active: false
        })
    }

    _forceTrackingUpdate = async () => {
        this.setState({
            loading: true,
            error: '',
            active: false,
            success: false
        })

        try {
            const {trackingNumber, carrier} = this.props
            const payload = {
                tracking_code: trackingNumber,
                carrier: carrier
            }
            const response = await forceUpdateTracking(payload)

            const {success, message} = response

            if (!success) {
                return this.setState({
                    loading: false,
                    error: message,
                    success: true,
                    active: true
                })
            }

            this.setState({
                loading: false,
                success: true,
                active: true
            }, () => {
                const {reload} = this.props

                if (typeof reload === 'function') {
                    reload()
                }
            })
        } catch (e) {
            this.setState({
                loading: false,
                error: e.message
            })
        }
    }

    render() {
        const {loading, error, active, success} = this.state
        const toastMarkup = active ?
            (!!error && error.length > 0 ? <Toast content={error} onDismiss={this._onToggleActive} error={true}/>
                :
                <Toast content="Force update tracking success!" onDismiss={this._onToggleActive} error={false}/>) : null

        return (
            <div className="ForceUpdateTracking">
                <Button destructive onClick={this._forceTrackingUpdate} icon={RefreshMajorMonotone} disabled={loading}>
                    Force update
                </Button>
                {success && toastMarkup}
            </div>
        )
    }
}

ForceUpdateTracking.propTypes = {
    trackingNumber: PropTypes.string,
    carrier: PropTypes.string
}

export default ForceUpdateTracking
