import React, {Component} from 'react'

import {
    createTrackingNumber,
    getTrackingNumbers,
    importCSV,
    exportCSV,
} from '../../../services/api/TrackingNumberServices'
import TrackingNumberTable from './TrackingNumberTable'
import {Card} from '@shopify/polaris'
import TableFilter from './TableFilter'
import Pagination from '../../shared/Pagination'
import ImportCsvButton from './ImportCsvButton'
import ExportCsvButton from './ExportCsvButton'
import ForceUpdateButton from './ForceUpdateButton'
import InsertTrackingNumber from './InsertTrackingNumber'
import Toast from '../../dashboard/toast'
import {saveAs} from 'file-saver'
import _ from 'lodash'

class TrackingNumbers extends Component {
    timeoutLoading = null

    state = {
        query: {
            carrier: '',
            source: '',
            tracking_code: '',
            status: '',
            transit_time: null,
        },
        paging: {
            limit: 50,
            page: 1,
        },
        fetching: {
            loading: false,
            err: null,
        },
        trackingNumbers: {
            data: [],
            total: 0,
        },
        forceUpdateStatusLoading: false,
        importCsv: false,
        details: {},
        error: false,
        message: '',
    }

    componentDidMount() {
        this.fetchTrackingNumbers()
    }

    displayDetails = (_id) => {
        const origin_details = this.state.details
        const value = origin_details[_id] === null ? true : !origin_details[_id]
        const details = Object.assign(origin_details, {[_id]: value})

        this.setState({details})
    }

    insertTracking = async trackingNumber => {
        try {
            const {success, message} = await createTrackingNumber(trackingNumber)
            if (!success) {
                return {error: true, message}
            }

            this.fetchTrackingNumbers()

            return {error: false, message: ''}
        } catch (e) {
            throw e
        }
    }

    setQuery = (filter, delayLoading = false) => this.setState(({query, paging}) => ({
        query: {
            ...query, ...filter,
        },
        paging: {...paging, page: 1},
    }), () => {
        if (!delayLoading) return this.fetchTrackingNumbers()

        if (this.timeoutLoading) clearTimeout(this.timeoutLoading)
        this.timeoutLoading = setTimeout(async () => await this.fetchTrackingNumbers(), 1000)
    })

    setFetching = (loading, err = null) => {
        const {trackingNumbers} = this.state
        this.setState({
            fetching: {loading, err},
            trackingNumbers: {
                ...trackingNumbers,
                ...loading ? {data: []} : {},
            },
        })
    }

    submitQuery = () => {
        const {paging} = this.state
        this.setState({paging: {...paging, page: 1}}, () => this.fetchTrackingNumbers())
    }

    switchPage = page => () => {
        const {paging, trackingNumbers: {data}, query} = this.state
        const lastId = _.get(_.last(data), 'id', '') || ''

        this.setState({
            paging: {...paging, page},
            query: {...query, last_id: lastId}
        }, () => this.fetchTrackingNumbers())
    }

    fetchTrackingNumbers = async () => {
        const {query, paging, fetching} = this.state
        if (fetching.loading) return
        this.setFetching(true)

        try {

            const {success, data: resp, message} = await getTrackingNumbers({...query, ...paging})
            this.setFetching(false, message)
            if (!success) {
                return this.setState({
                    error: true,
                    message,
                })
            }

            const {data} = resp
            if (_.isElement(data) || data.length < 50) {
                this.setState({lastPage: paging.page})
            }
            this.setState({trackingNumbers: {data}})
        } catch (e) {
            this.setFetching(false, e.message)
            return this.setState({
                error: true,
                message: e.message,
            })
        }
    }

    fetchImport = async file => {
        try {
            const {success, message, data} = await importCSV(file)
            if (!success) {
                return {error: true, message: message || 'Error import file csv'}
            }

            this.fetchTrackingNumbers()

            return {error: false, message: '', data: data}
        } catch (e) {
            return {error: true, message: 'Error import file csv'}
        }
    }

    exportCSV = async (query) => {
        try {
            const url = await exportCSV(query)
            const file = new File([url], 'traking-number.csv', {type: 'application/csv; charset=UTF-8'})
            saveAs(file)
        } catch (e) {
            alert(e)
        }
    }


    render() {
        const {trackingNumbers, query, fetching, paging, details, error, message, lastPage} = this.state
        const {data} = trackingNumbers
        const {loading} = fetching

        return (
            <div className="TrackingNumbers">
                <div className="Toolbar">
                    {error && (<Toast message={message} error={error} />)}
                    <InsertTrackingNumber insert={this.insertTracking} />
                    <ImportCsvButton fetchImport={this.fetchImport} />
                    <ExportCsvButton query={query} paging={paging} onExport={this.exportCSV} />
                    <ForceUpdateButton forceUpdate={this.fetchTrackingNumbers} />
                </div>

                <Card sectioned>
                    <Card.Subsection>
                        <TableFilter filter={query} setFilter={this.setQuery} loading={loading} />
                    </Card.Subsection>
                    <Card.Subsection>
                        <TrackingNumberTable
                            data={data} loading={loading}
                            filter={query} setFilter={this.setQuery}
                            submitFilter={this.submitQuery} page={paging.page} limit={paging.limit}
                            fetchEasyPostInformation={this.fetchEasyPostInformation}
                            showDetail={details} displayDetail={this.displayDetails}
                            reload={this.fetchTrackingNumbers}
                        />
                    </Card.Subsection>
                    <Card.Subsection>
                        <Pagination showQuickJumper onSwitchPage={this.switchPage} current={paging.page} amount={lastPage} />
                    </Card.Subsection>
                </Card>
            </div>
        )
    }
}


export default TrackingNumbers
