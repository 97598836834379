import React from 'react'
import {FormLayout, Form, Button, TextField, InlineError, TextStyle, Card} from '@shopify/polaris'
import {changePass} from '../../../services/api/UserServices'
import {
    CONFIRM_PASSWORD_ERROR,
    SAME_PASSWORD_ERROR,
    SAVE_CHANGE_PASSWORD_ERROR,
    SAVE_CHANGE_PASSWORD_SUCCESS,
} from '../static/message'

class ChangePass extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
            errorMessage: '',
            successMessage: '',
            isSubmit: false,
        }
    }

    onChangeField = field => value => {
        this.setState({
            [field]: value,
        })
    }

    handleSubmit = async () => {
        const {oldPassword, newPassword, confirmPassword} = this.state
        if (oldPassword === newPassword) {
            this.setState({
                errorMessage: SAME_PASSWORD_ERROR,
            })

            return
        }

        if (confirmPassword !== newPassword) {
            this.setState({
                errorMessage: CONFIRM_PASSWORD_ERROR,
            })

            return
        }

        this.setState({
            isSubmit: true,
        })

        try {
            const {success, message} = await changePass({oldPassword, newPassword})

            if (!success) {
                this.setState({
                    errorMessage: message || SAVE_CHANGE_PASSWORD_ERROR,
                    successMessage: '',
                })
                throw new Error(message)
            } else {
                this.setState({
                    oldPassword: '',
                    newPassword: '',
                    confirmPassword: '',
                    errorMessage: '',
                    successMessage: SAVE_CHANGE_PASSWORD_SUCCESS,
                })
            }

        } catch (e) {
            alert(e.message || SAVE_CHANGE_PASSWORD_ERROR)
        }

        this.setState({
            isSubmit: false,
        })
    }

    _canSubmit = ({oldPassword, newPassword, confirmPassword}) => {
        return oldPassword !== '' && newPassword !== '' && confirmPassword !== ''
    }

    render() {
        const {oldPassword, newPassword, confirmPassword, isSubmit, errorMessage, successMessage} = this.state

        const canSubmit = this._canSubmit({oldPassword, newPassword, confirmPassword})

        return (
            <Card
                title='Change password'
            >
                <Card.Section>
                    <Form onSubmit={this.handleSubmit}>
                        <FormLayout>
                            <TextField
                                id='old-password'
                                type='password'
                                label='Old password'
                                value={oldPassword}
                                onChange={this.onChangeField('oldPassword')}
                                minLength={6}
                            />
                            <TextField
                                type='password'
                                label='New password'
                                value={newPassword}
                                onChange={this.onChangeField('newPassword')}
                                minLength={6}
                            />
                            <TextField
                                type='password'
                                label='Confirm password'
                                value={confirmPassword}
                                onChange={this.onChangeField('confirmPassword')}
                                minLength={6}
                            />
                            <InlineError message={errorMessage} fieldID='old-password'/>
                            <TextStyle variation="positive">{successMessage}</TextStyle>
                            <Button loading={isSubmit} disabled={!canSubmit} submit> Save </Button>
                        </FormLayout>
                    </Form>
                </Card.Section>
            </Card>
        )
    }
}

export default ChangePass
