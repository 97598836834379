import React, { useState, useEffect } from 'react'
import { Card } from '@shopify/polaris'
import { Pagination, Input, Spin, Select, message } from "antd"
import 'antd/dist/antd.css'
import { getSources, createSource, editSource } from '../../../services/api/TrackingSourceServices'
import TrackingSourceCreate from './TrackingSourceCreate'
import TrackingSourceTable from './TrackingSourceTable'
import UseDebounce from '../../../helpers/UseDebounce'

function TrackingSource() {
    const [sources, setSources] = useState([])
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(10)
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [source, setSource] = useState([])

    const debounceSearch = UseDebounce(search, 1000)

    const _handleChangePagination = (page, pageSize) => {
        setPage(pageSize !== limit ? 1 : page)
        setLimit(pageSize)
    }

    const _onSearch = query => {
        setSearch(query)
        setPage(1)
    }

    const _handleChangeSource = source => {
        setSource(source)
        setPage(1)
    }


    const fetchTrackingSource = async () => {
        setLoading(true)
        setError('')
        setSources([])
        try {

            const payload = {
                page, limit
            }

            if (search) payload.name = search
            if (!!source.length) payload.source = source

            const response = await getSources(payload)
            const { success, data, message } = response

            if (!success) {
                setLoading(false)
                setError(message)
            }

            setSources(data.data)
            setTotal(data.total)
            setLoading(false)
        } catch (e) {
            setLoading(false)
            setError(e.message)
        }
    }

    const _createSource = async (payload) => {
        setLoading(true)
        try {
            const response = await createSource(payload)
            const { message: mess, success } = response
            if (!success) return message.error(`${mess}!`)
            message.success('Create successfully!')
            fetchTrackingSource()
        } catch (e) {
            message.error(e.message || 'Unknown error.')
        } finally {
            setLoading(false)
        }
    }

    const _editMapp = async (id, new_data) => {

        setLoading(true)
        const payload = {
            ...new_data
        }
        try {
            const response = await editSource(id, payload)
            const { message: mess, success } = response
            if (!success) return message.error(`${mess}!`)
            message.success('Edit successfully!')
            fetchTrackingSource()
        } catch (e) {
            message.error(e.message || 'Unknown error.')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchTrackingSource()
    }, [page, limit, source, debounceSearch])

    return (
        <React.Fragment>
            <TrackingSourceCreate handleClickAdd={_createSource} />
            <div className="TrackingSource">
                <Spin spinning={loading} tip="Getting list mapping...">
                    <Card sectioned>
                        <Card.Subsection>
                            <div className="TrackingFilter">
                                <div className="TrackingSearch">
                                    <Input.Search
                                        placeholder="Search by Name or Code"
                                        onChange={(e) =>
                                            _onSearch(e.target.value)
                                        }
                                        value={search}
                                        style={{ width: '100%' }}
                                        allowClear
                                    />
                                </div>
                                {/* <div className="ListFilter">
                                    <div className="TrackingStatuses">
                                        <div className="Filters">
                                            <label htmlFor="status">
                                                Source
                                            </label>
                                            <Select
                                                value={source}
                                                onChange={_handleChangeSource}
                                                optionLabelProp="label"
                                                defaultValue={source}
                                                placeholder="Select Source"
                                                allowClear
                                            >
                                                <Select.Option value="crawler">Crawler</Select.Option>
                                                <Select.Option value="trackingmore">Tracking more</Select.Option>
                                                <Select.Option value="multrans">Multrans</Select.Option>
                                                <Select.Option value="">Off</Select.Option>
                                            </Select>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </Card.Subsection>
                        <Card.Subsection>
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <>
                                            <th>Name</th>
                                            <th>Code</th>
                                            <th>Alias</th>
                                            <th>Source</th>
                                            <th>Actions</th>
                                        </>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!!sources &&
                                        sources.length > 0 &&
                                        sources.map((item, index) => (
                                            <TrackingSourceTable
                                                key={index}
                                                index={index}
                                                item={item}
                                                handleClickEdit={_editMapp}
                                            />
                                        ))}

                                    {!!error && error.length > 0 && (
                                        <tr>
                                            <td
                                                style={{ color: 'red' }}
                                                colSpan={5}
                                            >
                                                {error}
                                            </td>
                                        </tr>
                                    )}

                                    {!!sources && sources.length === 0 && (
                                        <tr>
                                            <td colSpan={5}>No record</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </Card.Subsection>
                        <Card.Subsection>
                            <Pagination
                                current={page}
                                total={total}
                                pageSize={limit}
                                onChange={_handleChangePagination}
                                className="text-right"
                                showSizeChanger
                                pageSizeOptions={['10', '20', '50', '100']}
                            />
                        </Card.Subsection>
                    </Card>
                </Spin>
            </div>
        </React.Fragment>
    )
}

export default TrackingSource