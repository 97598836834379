import React, {Component} from 'react'
import Dashboard from './Dashboard'

class DashboardContainer extends Component {
    render() {
        return (
            <Dashboard {...this.props}/>
        )
    }
}

export default DashboardContainer
