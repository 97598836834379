import React, {Component} from 'react'
import {getListAPIKey, revokeAPIKeyById, updateAPIKeyById, generateAPIKey} from '../../../services/api/APIKeyServices'
import ListAPIKey from './ListAPIKey'
import {Card, Page, Layout} from '@shopify/polaris'
import FilterAPIKey from './FilterAPIKey'
import Pagination from '../../shared/Pagination'
import GenerateKey from './GenerateKey'

class ManageAPIKeys extends Component {
    timeoutLoading = null

    state = {
        query: {
            name: '',
            prefix: '',
            status: '',
            end_date: '',
            start_date: '',
        },
        paging: {
            limit: 20,
            page: 1,
        },
        fetching: {
            loading: false,
            err: null,
        },
        apikeys: {
            data: [],
            total: 0,
        },
    }

    componentDidMount() {
        this._fetchListAPIKeys()
    }

    _generateKey = async item => {
        const {success, data: resp, message} = await generateAPIKey(item)

        if (!success) throw new Error(message)

        this.setState(({apikeys: {data, total}}) => ({
            apikeys: {
                data: [resp.data, ...data],
                total: total + 1,
            }
        }))

        return {success, data: resp, message}
    }

    _setQuery = (filter, delayLoading = false) => this.setState(({query, paging}) => ({
        query: {
            ...query, ...filter,
        },
        paging: {...paging, page: 1},
    }), () => {
        if (!delayLoading) return this._fetchListAPIKeys()

        if (this.timeoutLoading) clearTimeout(this.timeoutLoading)
        this.timeoutLoading = setTimeout(async () => await this._fetchListAPIKeys(), 1000)
    })

    _setFetching = (loading, err = null) => {
        const {apikeys} = this.state
        this.setState({
            fetching: {loading, err},
            apikeys: {
                ...apikeys,
                ...loading ? {data: []} : {},
            },
        })
    }

    _submitQuery = () => {
        const {paging} = this.state
        this.setState({paging: {...paging, page: 1}}, () => this._fetchListAPIKeys())
    }

    _switchPage = page => () => {
        const {paging} = this.state

        this.setState({
            paging: {...paging, page},
        }, () => this._fetchListAPIKeys())
    }

    _fetchListAPIKeys = async () => {
        const {query, paging, fetching} = this.state
        if (fetching.loading) return

        this._setFetching(true)

        try {
            const {success, data: resp, message} = await getListAPIKey({...query, ...paging})

            if (!success) throw new Error(message)

            this._setFetching(false, message)
            const {total, data} = resp
            this.setState({apikeys: {data, total}})
        } catch (e) {
            this._setFetching(false, e.message)
            alert(e.message)
        }
    }

    _setApikeys = (item) => {
        const {apikeys: {data, total}} = this.state
        const newData = data.map(e => {
            return e._id === item._id ? {...e, ...item} : e
        })

        this.setState({
            apikeys: {
                data: newData,
                total
            }
        })
    }

    _handleRevoke = async ({_id}) => {
        const {success, data, message} = await revokeAPIKeyById(_id)
        if (!success) throw new Error(message)

        this._setApikeys({_id, status: 'revoked'})
        return {success, data, message}
    }

    _handleEdit = async (item) => {
        const {success, data, message} = await updateAPIKeyById(item._id, item)
        if (!success) throw new Error(message)

        this._setApikeys(data)
        return {success, data, message}
    }

    render() {
        const {apikeys, query, fetching, paging} = this.state
        const {total, data} = apikeys
        const {loading} = fetching
        const totalPage = Math.ceil(total / paging.limit)
        const label = (totalPage > 0 && paging.page + '/' + totalPage) || null

        return (
            <Page fullWidth title="Manage APIKeys">
                <div className="ManageUsers">
                    <Layout>
                        <Layout.Section secondary>
                            <GenerateKey insert={this._generateKey}/>
                        </Layout.Section>
                        <Layout.Section>
                            <Card sectioned>
                                <Card.Subsection>
                                    <FilterAPIKey query={query} setQuery={this._setQuery} loading={loading}/>
                                </Card.Subsection>
                                <Card.Subsection>
                                    <ListAPIKey
                                        total={total} data={data} loading={loading}
                                        filter={query} setFilter={this._setQuery}
                                        submitFilter={this._submitQuery}
                                        paging={paging} handleEdit={this._handleEdit}
                                        handleRevoke={this._handleRevoke}
                                    />
                                </Card.Subsection>
                                <Card.Subsection>
                                    <Pagination label={label} showQuickJumper onSwitchPage={this._switchPage}
                                                amount={totalPage} current={paging.page}/>
                                </Card.Subsection>
                            </Card>
                        </Layout.Section>
                    </Layout>
                </div>
            </Page>
        )
    }
}

export default ManageAPIKeys
