import React, {Component, createRef} from 'react'
import {Button, Modal, Banner, List, Spinner} from '@shopify/polaris'
import {getTemplate} from '../../../services/api/TemplateServices'
import PropTypes from 'prop-types'

class ImportCsvModal extends Component {
    input = createRef()
    state = {
        file: null,
        error: false,
        message: '',
        errors: [],
        loading: false,
        isSuccess: false,
    }

    clickSave = async () => {
        try {
            this.setState({loading: true})
            const form = new FormData()
            const {file} = this.state
            const {fetchImport} = this.props
            form.append('file', file)
            const {error, message, data} = await fetchImport(form)

            if (error) {
                return this.setState({
                    error: true,
                    message,
                })
            }
            const errors = data.filter(item => {
                return !item.success
            })
            if (!errors.length) {
                return this.setState({
                    loading: false,
                    isSuccess: true,
                })
            }
            const _errors = {}
            if (errors.length) {
                errors.forEach(item => {
                    if (!_errors[item.message]) {
                        _errors[item.message] = 1
                    } else {
                        _errors[item.message] = _errors[item.message] + 1
                    }
                })
            }

            return this.setState({errors: _errors, loading: false})

        } catch (error) {
            this.setState({error: true, message: error.message})
        } finally {
            this.setState({loading: false})
        }
    }

    clickSelect = () => this.input.current.click()

    onChangeFile = e => {
        const file = e.target.files[0]
        this.setState({file})
    }

    onSubmit = async e => {
        e.preventDefault()
        const {file} = this.state
        if (file) return await this.clickSave()
    }

    close = () => {
        this.setState({error: false, loading: false, isSuccess: false, errors: []}, () => this.props.toggle())
    }

    render() {
        const {open} = this.props
        const {file, error, message, errors: _errors, loading, isSuccess} = this.state
        const errors = Object.entries(_errors)
        return (
            <div>
                {<Modal
                    open={open}
                    onClose={this.close}
                    title="Import CSV"
                    primaryAction={(!isSuccess && !errors.length > 0 && !error) && {
                        content: 'Import CSV',
                        onAction: this.clickSave,
                        disabled: !file,
                    }}
                    secondaryActions={[
                        {
                            content: 'Close',
                            onAction: this.close,
                        },
                    ]}
                >
                    <Modal.Section>
                        {!loading && !errors.length && !isSuccess && !error && <div className="ImportCsvModal">
                            <form onSubmit={this.onSubmit} className="SourceForm">
                                <Button onClick={this.clickSelect} primary>
                                    Select a file
                                </Button>
                                {file && <small className="FileName">
                                    {'  ' + (file || {}).name}
                                </small>}
                                <input className="FileInput" type="file" accept=".csv" ref={this.input}
                                    onChange={this.onChangeFile} />
                                <div className='mt-2'>
                                    <small> Get file template input
                                        <a href={getTemplate()} className='text-purple-700 font-bold'> here</a>
                                    </small>
                                </div>
                            </form>
                        </div>}
                        {loading && !errors.length && !error && <Spinner
                            accessibilityLabel="Importing..."
                            hasFocusableParent={true}
                        />
                        }
                        {errors.length > 0 && !loading && !error && (<Banner
                            title='Warning!'
                            status="warning"
                        >
                            <List>
                                {errors.map(([key, value]) => (
                                    <List.Item>
                                       {value} - {key}
                                    </List.Item>
                                ))}
                            </List>
                        </Banner>)
                        }
                        {error && !loading && (<Banner
                            title='Warning!'
                            status="warning"
                        >
                            {message}
                        </Banner>)
                        }
                        {isSuccess && <Banner
                            title="Import successful"
                            status="success"
                            onDismiss={() => this.setState({isSuccess: false})}
                        />}

                    </Modal.Section>
                </Modal>}
            </div>
        )
    }
}

ImportCsvModal.propTypes = {
    open: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
}

export default ImportCsvModal
