import React,{ useState } from 'react'
import { ActionList, Popover } from '@shopify/polaris'
import { Modal, Select, Progress } from 'antd'
import { FormFeedback } from 'reactstrap'
import tracking_status from "../static/statuses"


const BulkActionMapping = (props) => {

    const {popoverActive, activator, togglePopoverActive, totalCheck, setTotalCheck, editMapp, setIsCheckAll,processList,setProcessList,setLoading}=props
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [errors, setErrors] = useState({})
    const [newStatus, setNewStatus]= useState("")
    const [countSuccess, setCountSuccess]= useState([])
    const [countError, setCountError]= useState([])
    const [buttonLoading, setButtonLoading] = useState(false)

    const showModal = () => {
        setIsModalVisible(true)
        setNewStatus(totalCheck[0].tracking_status)
      }
    
    const handleOk = async() => {
        setButtonLoading(true)
        let err = {}
        const checkItem = totalCheck[0].carrier
        const success = totalCheck.every(item=>item.carrier === checkItem)
        await setProcessList([])
        await setCountSuccess([])
        await setCountError([])
        if(success){
            for (const item of totalCheck) {
                const data={
                    carrier: item.carrier,
                    note: item.original,
                    tracking_status: item.tracking_status
                }
                const newData={...data, tracking_status:newStatus}
                const test = await editMapp(data,newData)

                if(test.success===true){
                    await setCountSuccess(countSuccess=>[...countSuccess,test])
                    item.tracking_status=test.data.to.status
                    const Ptest={original: data.note,tracking_status: test.data.to.status,...test}
                    setProcessList(processList =>[...processList, Ptest])
                }
                else{
                    setCountError(countError=>[...countError,test])
                    const Ptest={original: data.note,tracking_status: data.tracking_status,...test}
                    setProcessList(processList =>[...processList, Ptest])
                }
            }
            setButtonLoading(false)
        }
        else{
            err.notes="Please choose items with the same carrier!!!" 
        }
        setErrors(err)
        let a= newStatus
    }
    
    const handleCancel = () => {
        setIsModalVisible(false)
        setErrors({})
        setProcessList([])
        setTotalCheck([])
        setCountSuccess([])
        setCountError([])
        setIsCheckAll(false)
        setLoading(false)
    }
    

    const handleChangeStatus = value=>{
        setNewStatus(value)
    }

    return (
        <th colSpan={8}> 
            <div style={{height: 'auto'}}>
                <Popover
                    active={popoverActive}
                    activator={activator}
                    autofocusTarget="first-node"
                    onClose={togglePopoverActive}
                >
                    <Popover.Pane>
                    <ActionList
                        actionRole="menuitem"
                        items={[
                        {content: <a onClick={showModal}>Change Tracking Status</a>},
                        ]}
                    />
                    </Popover.Pane>
                </Popover>
            </div>
            <Modal 
                title="Change Tracking Status" 
                visible={isModalVisible} 
                onOk={handleOk} 
                onCancel={handleCancel}
                okText="Change Tracking Status"
                confirmLoading={buttonLoading}
            >
                <div className="FilterChild">
                    <h3>Tracking status</h3>
                    <Select 
                        value={newStatus || []}
                        onChange={handleChangeStatus}
                        placeholder="Select Tracking Status"
                        style={{ width: '100%' }}
                    >
                    {
                        tracking_status.length > 0 && tracking_status.map((item, index) => (
                            <Select.Option key={index} value={item.value}>{item.label}</Select.Option>
                            ))
                    }
                    </Select>
                    {
                    errors.notes && 
                    <FormFeedback style={{color: "#ed4122"}}>{errors.notes}</FormFeedback>
                    }
                </div>
                <div className='CheckedList'>
                    <h3>List processing: <b>{totalCheck.length}</b> items</h3>
                    {processList.length!==0&&
                    <b>
                        <span>
                            Success: <span style={{color:"green"}} >{countSuccess.length}</span> items,  
                            Error: <span style={{color:"red"}}>{countError.length}</span> items
                        </span>
                    </b>
                    }
                    <div className='ListItem'>
                        <ul>
                        { processList.length===0&&
                            totalCheck.map((item,index)=>(
                                <li key={index}>
                                    <span>
                                        {item.original}
                                    </span>
                                    <Progress
                                        percent={100}
                                        status="active"
                                        showInfo={false}
                                    />
                                </li>
                            ))
                        }
                        { processList.length!==0&&
                            processList.map((item,index)=>(
                                <li key={index}>
                                    <span>
                                        {item.original}
                                    
                                    {item.success===false&&
                                        <small className='Error'>
                                            {item.message}
                                        </small> 
                                    }
                                    {item.success===true&&
                                        <small className='Success'>
                                            Success
                                        </small> 
                                    }
                                    </span>
                                    <Progress
                                        percent={100}
                                        status={item.success===true? "success":"exception"}
                                        showInfo={true}
                                    />
                                </li>
                            ))
                        }
                        </ul>
                    </div>
                </div>
            </Modal>
        </th>
    )
}

export default BulkActionMapping