import React, {Component} from "react"
import {Redirect, Route, Switch} from "react-router-dom"
import ManageJobs from "./ManageJobs"


class ManageJobsContainer extends Component {
    render() {
        const {basePath} = this.props

        return (
            <Switch>
                <Route exact path={`${basePath}/manage-jobs`} render={() => <ManageJobs {...this.props}/>}/>
                <Route path={'*'}>
                    <Redirect to={`${basePath}/manage-jobs`}/>
                </Route>
            </Switch>
        )
    }
}

export default ManageJobsContainer
