import React, {Component} from 'react'
import {
    getTrackerStatistics,
    getCrawlerTracker,
    getCrawlerTraffics
} from '../../../services/api/StatisticsServicesAuth'
import moment from 'moment'
import {Layout} from '@shopify/polaris'
import StatisticsListSkeleton from './StatisticsListSkeleton'
import StatisticsFilter from './StatisticsFilter'
import StatisticsList from "./StatisticsListNew"

class Statistics extends Component {

    state = {
        query: {
            end_date: new Date(),
            start_date: new Date(moment().subtract(1, 'months')),
        },
        trackerStatistics: {
            loading: true,
            err: null,
            data: [],
            total: 0
        },
        trackerStatisticsMore: {
            loading: true,
            err: null,
            data: [],
            total: 0
        },
        crawlerTracker: {
            loading: true,
            err: null,
            data: [],
            total: 0
        },
        crawlerTraffics: {
            loading: true,
            err: null,
            data: [],
            total: 0
        },
        hoverMenu: '',
        firstLoading: true,
    }

    componentDidMount() {
        this._fetchTrackerStatistics()
        this._fetchTrackerStatisticsMore()
        this._fetchCrawlerTracker()
        this._fetchCrawlerTraffics()
    }

    _changeFilter = (filter, callback) => this.setState(({query}) => ({
        query: {
            ...query,
            ...filter,
        },
    }), () => {
        this._fetchTrackerStatistics()
        this._fetchTrackerStatisticsMore()
        this._fetchCrawlerTracker()
        this._fetchCrawlerTraffics()
    })

    _setLoading = ({key, loading = true, err = null, callback, additionalState = {}}) => {
        const update = this.state[key]
        this.setState({
            [key]: {
                ...update,
                ...additionalState,
                loading,
                err,
            },
        }, callback)
    }

    _fetchTrackerStatistics = async () => {
        this._setLoading({
            key: 'trackerStatistics',
            loading: true
        })

        const {query} = this.state
        const newQuery = {
            type: 'easypost',
            createdFrom: new Date(moment(query.start_date).startOf('day')),
            createdTo: new Date(moment(query.end_date).endOf('day'))
        }

        try {
            const response = await getTrackerStatistics(newQuery)

            const {success, data, message} = response

            this._setLoading({
                key: 'trackerStatistics',
                loading: false,
                err: message,
                callback: () => {
                    if (this.state.firstLoading && !this.state.trackerStatistics.loading) {
                        this.setState({
                            firstLoading: false
                        })
                    }
                },
                ...(success && {
                    additionalState: {
                        total: data.total,
                        data: data.statistics,
                    },
                }),
            })
        } catch (e) {
            this._setLoading({
                key: 'trackerStatistics',
                loading: false,
                err: e.message
            })
        }
    }

    _fetchTrackerStatisticsMore = async () => {
        this._setLoading({
            key: 'trackerStatistics',
            loading: true
        })

        const {query} = this.state
        const newQuery = {
            type: 'trackingmore',
            createdFrom: new Date(moment(query.start_date).startOf('day')),
            createdTo: new Date(moment(query.end_date).endOf('day'))
        }

        try {
            const response = await getTrackerStatistics(newQuery)

            const {success, data, message} = response

            this._setLoading({
                key: 'trackerStatisticsMore',
                loading: false,
                err: message,
                callback: () => {
                    if (this.state.firstLoading && !this.state.trackerStatisticsMore.loading) {
                        this.setState({
                            firstLoading: false
                        })
                    }
                },
                ...(success && {
                    additionalState: {
                        total: data.total,
                        data: data.statistics,
                    },
                }),
            })
        } catch (e) {
            this._setLoading({
                key: 'trackerStatisticsMore',
                loading: false,
                err: e.message
            })
        }
    }

    _fetchCrawlerTracker = async () => {
        this._setLoading({
            key: 'crawlerTracker',
            loading: true
        })

        const {query} = this.state

        const newQuery = {
            createdFrom: new Date(moment(query.start_date).startOf('day')),
            createdTo: new Date(moment(query.end_date).endOf('day'))
        }

        try {
            const response = await getCrawlerTracker(newQuery)

            const {success, data, message} = response

            this._setLoading({
                key: 'crawlerTracker',
                loading: false,
                err: message,
                callback: () => {
                    if (this.state.firstLoading && !this.state.crawlerTracker.loading) {
                        this.setState({
                            firstLoading: false
                        })
                    }
                }, ...(success && {
                    additionalState: {
                        total: data.total,
                        data: data.statistics,
                    },
                }),
            })
        } catch (e) {
            this._setLoading({
                key: 'crawlerTracker',
                loading: false,
                err: e.message
            })
        }
    }

    _fetchCrawlerTraffics = async () => {
        this._setLoading({
            key: 'crawlerTraffics',
            loading: true
        })

        const {query} = this.state

        const newQuery = {
            createdFrom: new Date(moment(query.start_date).startOf('day')),
            createdTo: new Date(moment(query.end_date).endOf('day'))
        }

        try {
            const response = await getCrawlerTraffics(newQuery)

            const {success, data, message} = response

            this._setLoading({
                key: 'crawlerTraffics',
                loading: false,
                err: message,
                callback: () => {
                    if (this.state.firstLoading && !this.state.crawlerTraffics.loading) {
                        this.setState({
                            firstLoading: false
                        })
                    }
                }, ...(success && {
                    additionalState: {
                        total: data.total,
                        data: data.statistics,
                    },
                }),
            })
        } catch (e) {
            this._setLoading({
                key: 'crawlerTraffics',
                loading: false,
                err: e.message
            })
        }
    }

    render() {
        const {
            firstLoading,
            trackerStatistics,
            crawlerTracker,
            query,
            trackerStatisticsMore,
            crawlerTraffics
        } = this.state
        const loading = trackerStatistics.loading || crawlerTracker.loading

        return (
            <div className="Statistics">
                <StatisticsFilter filter={query} onChange={this._changeFilter} loading={loading}/>

                <Layout>
                    <Layout.Section oneFour>
                        {
                            (firstLoading && trackerStatistics.loading) ?
                                <StatisticsListSkeleton
                                    skeletonBodyText={11}
                                    title='Easypost'
                                    id='trackerStatistics'
                                /> :
                                <StatisticsList
                                    data={{
                                        data: trackerStatistics.data,
                                        title: 'Easypost',
                                        id: 'trackerStatistics',
                                        total: trackerStatistics.total
                                    }}
                                />
                        }
                    </Layout.Section>

                    <Layout.Section oneFour>
                        {
                            (firstLoading && trackerStatisticsMore.loading) ?
                                <StatisticsListSkeleton
                                    skeletonBodyText={11}
                                    title='Tracking More'
                                    id='trackerStatistics'
                                /> :
                                <StatisticsList
                                    data={{
                                        data: trackerStatisticsMore.data,
                                        title: 'Tracking More',
                                        id: 'trackerStatistics',
                                        total: trackerStatisticsMore.total
                                    }}
                                />
                        }
                    </Layout.Section>

                    <Layout.Section oneFour>
                        {
                            (firstLoading && crawlerTracker.loading) ?
                                <StatisticsListSkeleton
                                    skeletonBodyText={7}
                                    title='Crawler'
                                    id='crawlerTracker'
                                /> :
                                <StatisticsList
                                    data={{
                                        data: crawlerTracker.data,
                                        title: 'Crawler Trackers',
                                        id: 'crawlerTracker',
                                        total: crawlerTracker.total
                                    }}
                                />
                        }
                    </Layout.Section>

                    <Layout.Section oneFour>
                        {
                            (firstLoading && crawlerTraffics.loading) ?
                                <StatisticsListSkeleton
                                    skeletonBodyText={7}
                                    title='Traffics'
                                    id='crawlerTraffics'
                                /> :
                                <StatisticsList
                                    data={{
                                        data: crawlerTraffics.data,
                                        title: 'Crawler Traffics',
                                        id: 'crawlerTraffics',
                                        total: crawlerTraffics.total
                                    }}
                                />
                        }
                    </Layout.Section>
                </Layout>
            </div>
        )
    }
}

export default Statistics
