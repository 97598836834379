const carriers = [
    {'value': 'DHL eCommerce US', 'label': 'DHL eCommerce'},
    {
        'value': 'DHLGlobalMail',
        'label': 'DHLGlobalMail',
    },
    {
        'value': 'USPS',
        'label': 'USPS',
    }, {'value': 'FedEx', 'label': 'FedEx'}, {'value': 'UPS', 'label': 'UPS'}, {
        'value': 'Singapore Post',
        'label': 'Singapore Post',
    }, {'value': 'China EMS', 'label': 'China EMS'}, {
        'value': 'China Post',
        'label': 'China Post',
    }, {'value': 'DHL Express', 'label': 'DHL Express'}, {
        'value': 'S.F Express',
        'label': 'S.F Express',
    }, {'value': 'SF International', 'label': 'SF International'}, {
        'value': 'Yun Express',
        'label': 'Yun Express',
    }, {'value': 'Yanwen', 'label': 'Yanwen'},
    {
        'value': 'Australia Post',
        'label': 'Australia Post',
    }]

export default carriers
