import createAPIServices from './createApiServices'

const baseUrl = process.env.NODE_ENV === 'production' ? '/api' : 'https://staging-tracking-v2.merchize.com/api'
const api = createAPIServices({baseUrl})

export const getTrackingWebhooksExample = () => {
    return api.makeAuthRequest({
        url: 'tracking-webhooks/example',
        method: 'get'
    })
}

export const getTrackingWebhooks = ({tracking_code, event, status, created_from, created_to, limit, page}) => {
    return api.makeAuthRequest({
        url: 'tracking-webhooks',
        method: 'get',
        params: {tracking_code, event, status, created_from, created_to, limit, page}
    })
}

export const addTrackingWebhooks = (fields) => {
    return api.makeAuthRequest({
        url: 'tracking-webhooks',
        method: 'post',
        data: fields
    })
}

export const updateBatchStatus = (status, ids) => {
    return api.makeAuthRequest({
        url: 'tracking-webhooks/status',
        method: 'PATCH',
        data: {
            ids, status
        }
    })
}

export const getTrackingWebhooksHistories = ({tracking_code, event, status, created_from, created_to, limit, page}) => {
    return api.makeAuthRequest({
        url: 'tracking-webhook-histories',
        method: 'get',
        params: {tracking_code, event, status, created_from, created_to, limit, page}
    })
}

export const getTrackingWebhooksHistoriesRetry = (history_id) => {
    return api.makeAuthRequest({
        url: `tracking-webhook-histories/${history_id}/retry`,
        method: 'PATCH'
    })
}

export const getTrackingWebhooksHistoriesCancel = (history_id) => {
    return api.makeAuthRequest({
        url: `tracking-webhook-histories/${history_id}/cancel`,
        method: 'PATCH'
    })
}

export const sendExampleWebhooks = (fields) => {
    return api.makeAuthRequest({
        url: `tracking-webhooks/example/send`,
        method: 'POST',
        data: fields
    })
}

export default api