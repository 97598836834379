import createAPIServices from './createApiServices'

const baseUrl = process.env.NODE_ENV === 'production' ? '/api/user-service/webhooks' : 'https://staging-tracking-v2.merchize.com/api/user-service/webhooks'
const api = createAPIServices({baseUrl})

export const getListWebhook = ({name, url, status, limit, page, start_date, end_date}) => {
    return api.makeAuthRequest({
        url: '',
        method: 'get',
        params: {name, url, status, limit, page, start_date, end_date}
    })
}

export const getWebhookById = (_id) => {
    return api.makeAuthRequest({
        url: `/${_id}`,
        method: 'get',
    })
}

export const generateSecretKey = () => {
    return api.makeAuthRequest({
        url: `/generate/secret`,
        method: 'get',
    })
}

export const deleteWebhookById = (_id) => {
    return api.makeAuthRequest({
        url: `/${_id}`,
        method: 'delete',
    })
}

export const insertWebhook = ({name, url, secret_key}) => {
    return api.makeAuthRequest({
        url: ``,
        method: 'post',
        data: {name, url, secret_key},
    })
}

export const updateWebhookById = (_id, {name, url, secret_key, status}) => {
    return api.makeAuthRequest({
        url: `/${_id}`,
        method: 'put',
        data: {name, url, secret_key, status}
    })
}

export default api