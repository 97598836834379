import React, {Component} from 'react'
import {Button} from '@shopify/polaris'
import PropTypes from 'prop-types'
import {ExportMinor} from '@shopify/polaris-icons'

class ExportCsvButton extends Component {
    _exportCSV = () => {
        const {onExport, query, paging} = this.props

        if (typeof onExport === 'function') {
            onExport({...query, ...paging})
        }
    }

    render() {
        return (
            <div className="ExportCsvButton">
                <Button icon={ExportMinor} onClick={this._exportCSV}>
                    Export to CSV
                </Button>
            </div>
        )
    }
}

ExportCsvButton.propTypes = {
    onExport: PropTypes.func.isRequired,
}

export default ExportCsvButton
