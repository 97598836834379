const carriers = [
    {'value': 'DHLeCommerce', 'label': 'DHLeCommerce'},
    {
        'value': 'USPS',
        'label': 'USPS',
    },
    {'value': 'UPS', 'label': 'UPS'},
    {'value': 'Multrans', 'label': 'Multrans'},
    {'value': 'Australia Post', 'label': 'Australia Post'},
    {'value': 'Canada Post', 'label': 'Canada Post'},
    {'value': 'Asendia', 'label': 'Asendia'},
    {'value': 'Asendia USA', 'label': 'Asendia USA'},
    {'value': 'Yun Express', 'label': 'Yun Express'},
]

export default carriers
