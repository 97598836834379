import React, {Component} from 'react'
import {Button, ButtonGroup, DatePicker, Popover} from '@shopify/polaris'
import PropTypes from 'prop-types'
import moment from 'moment'

class StatisticsFilter extends Component {
    constructor(props) {
        super(props)
        const {start_date: start, end_date: end} = props.filter
        this.state = {
            open: false,
            start,
            end,
            displayMonth: end.getMonth(),
            displayYear: end.getFullYear(),
        }
    }

    _onToggle = () => this.setState({open: !this.state.open})

    _isDateMapped = () => {
        const {start_date, end_date} = this.props.filter
        const {start, end} = this.state

        return start_date.getTime() === start.getTime() && end_date.getTime() === end.getTime()
    }

    _mapDate = () => {
        const {start, end} = this.state
        this.props.onChange({
            start_date: start,
            end_date: end,
        })
    }

    _onChange = value => {
        const {start, end} = value
        this.setState({
            end, start,
            displayMonth: end.getMonth(),
            displayYear: end.getFullYear(),
        }, () => {
            if (end === start) return
            this._onToggle()
        })
    }

    _resetRange = () => {
        const end = new Date()
        const start = new Date(new Date(moment().subtract(1, 'months')))
        this.setState({
            end, start,
            displayMonth: end.getMonth(),
            displayYear: end.getFullYear(),
        }, () => {
            if (end === start) return
            this._onToggle()
        })
    }

    _changeMonth = newMonth => {
        const {displayMonth, displayYear} = this.state

        const decreaseYear = displayMonth === 0 && newMonth === 11
        const increaseYear = displayMonth === 11 && newMonth === 0
        const newYear = decreaseYear ? displayYear - 1 : increaseYear ? displayYear + 1 : displayYear

        this.setState({displayMonth: newMonth, displayYear: newYear})
    }

    render() {
        const {open, displayMonth, displayYear, start, end} = this.state
        const {loading} = this.props
        const selected = {
            start,
            end,
        }

        const applyButtonDisabled = this._isDateMapped()

        const activator = (
            <ButtonGroup segmented>
                <Button onClick={this._onToggle}>
                    {moment(start).format('DD/MM/YYYY')} to {moment(end).format('DD/MM/YYYY')}
                </Button>
                <Button loading={loading} primary onClick={this._mapDate} disabled={applyButtonDisabled}>
                    Apply Filter
                </Button>
            </ButtonGroup>
        )

        return (
            <div className="StatisticsFilter mb-4 flex">
                <Popover
                    fluidContent
                    activator={activator} active={open} onClose={this._onToggle}>
                    <div className="p-4">
                        <div className="pb-4">
                            <Button primary onClick={this._resetRange}>Reset range</Button>
                        </div>
                        <DatePicker
                            allowRange
                            month={displayMonth}
                            year={displayYear}
                            onChange={this._onChange}
                            onMonthChange={this._changeMonth}
                            selected={selected}
                        />
                    </div>
                </Popover>
                <div className="px-4">
                </div>
            </div>
        )
    }
}

StatisticsFilter.propTypes = {
    filter: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
}

export default StatisticsFilter
