import React, {Component} from 'react'
import DashboardHeader from './DashboardHeader'
import DashboardSidebar from './DashboardSidebar'
import MainApp from './MainApp'
import {Frame} from '@shopify/polaris'

class Dashboard extends Component {
    state = {
        navigation: false,
    }

    _toggleNavigation = () => this.setState({navigation: !this.state.navigation})

    render() {
        return (
            <Frame
                topBar={<DashboardHeader toggleNavigation={this._toggleNavigation}/>}
                navigation={<DashboardSidebar/>}
                showMobileNavigation={this.state.navigation}
                onNavigationDismiss={this._toggleNavigation}
            >
                <MainApp basePath='/d'/>
            </Frame>
        )
    }
}

//dashboard.propTypes = {}

export default Dashboard
