import React, {Component} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import LoginContainer from './LoginContainer'

class AuthContainer extends Component {
    render() {
        return (
            <Switch>
                <Route exact path={'/a/login'} component={LoginContainer}/>
                <Redirect to={'/a/login'}/>
            </Switch>
        )
    }
}

export default AuthContainer
