import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Select, Input, Checkbox } from "antd"
import { Button, Tooltip } from '@shopify/polaris'
import humanizeTime from "../../../helpers/humanizeTime"
import { FormFeedback } from 'reactstrap'
import carriers from "../static/carriers"
import tracking_status from "../static/statuses"
import { EditMajorMonotone, MobileCancelMajorMonotone } from '@shopify/polaris-icons'


const TrackingMappingTable = props => {
    const { index, item, handleClickDelete, handleClickEdit, totalCheck, onCheck } = props
    const [newOriginal, setNewOriginal] = useState(item.original)
    const [newTrackingStatus, setNewTrackingStatus] = useState(item.tracking_status)
    const [newCarrier, setNewCarrier] = useState(item.carrier)
    const [visible, setVisible] = useState(false)
    const [errors, setErrors] = useState({})

    const _handleChangeNewOriginal = (e) => {
        setNewOriginal(e.target.value)
        delete errors.notes
    }

    const _handleChangeCarrier = carrier => {
        setNewCarrier(carrier)
        delete errors.carrier
    }

    const _handleChangeStatuses = status => {
        setNewTrackingStatus(status)
        delete errors.trackingStatus
    }

    const showModal = () => {
        setVisible(true)
    }

    const hideModal = () => {
        setVisible(false)
        setNewOriginal(item.original)
        setNewTrackingStatus(item.tracking_status)
        setNewCarrier(item.carrier)
        delete errors.notes
        delete errors.carrier
        delete errors.trackingStatus
    }

    const _validate = () => {
        let err = {}

        if (newOriginal.length === 0) err.notes = 'Original is required'
        if (!newCarrier) err.carrier = 'Carrier is required'
        if (!newTrackingStatus) err.trackingStatus = 'Tracking status is required'

        setErrors(err)

        if (Object.keys(err).length >= 1) return
        const old_data = {
            note: item.original,
            tracking_status: item.tracking_status,
            carrier: item.carrier,
        }

        const new_data = {
            note: newOriginal,
            tracking_status: newTrackingStatus,
            carrier: newCarrier
        }
        handleClickEdit(old_data, new_data, false)
        setVisible(false)
    }


    return (
        <React.Fragment>
            <tr key={index}>
                <td><Checkbox data={item} checked={totalCheck.includes(item)} onChange={onCheck} /></td>
                <td>{item.original}</td>
                <td>{item.tracking_status}</td>
                <td>{item.carrier}</td>
                <td className={`Status ${item.status_mapped ? 'Active' : 'Deactivate'}`}>
                    {item.status_mapped ? 'Mapped' : 'Unmapped'}
                </td>
                <td>{item.created_at ? humanizeTime(item.created_at) : ''}</td>
                <td>{item.updated_at ? humanizeTime(item.updated_at) : ''}</td>
                <td>
                    <div className="acions">
                        <Tooltip content="Edit" preferredPosition="above">
                            <Button icon={EditMajorMonotone} onClick={showModal} />
                        </Tooltip>
                        <Tooltip content="Delete" preferredPosition="above">
                            <Button id="DeleteButton" icon={MobileCancelMajorMonotone} onClick={(e) => handleClickDelete(e, item.original, item.tracking_status, item.carrier)} />
                        </Tooltip>
                    </div>
                </td>
            </tr>
            <Modal
                title="Edit mapping"
                visible={visible}
                onOk={_validate}
                onCancel={hideModal}
                okText="Edit"
                cancelText="Cancel"
            >
                <div className="TrackingMappingCreate">
                    <div className="CreateContent">
                        <div className="CreateChild">
                            <label>Carriers</label>
                            <Select
                                value={newCarrier || []}
                                onChange={_handleChangeCarrier}
                                placeholder="Select carriers"
                                style={{ width: '100%' }}
                                allowClear
                            >
                                {
                                    carriers.length > 0 && carriers.map((item, index) => (
                                        <Select.Option key={index} value={item.value}>{item.label}</Select.Option>
                                    ))
                                }
                            </Select>
                            {errors.carrier && <FormFeedback>{errors.carrier}</FormFeedback>}
                        </div>
                        <div className="CreateChild">
                            <label>Tracking satus</label>
                            <Select
                                value={newTrackingStatus || []}
                                onChange={_handleChangeStatuses}
                                placeholder="Select Tracking Status"
                                style={{ width: '100%' }}
                                allowClear
                            >
                                {
                                    tracking_status.length > 0 && tracking_status.map((item, index) => (
                                        <Select.Option key={index} value={item.value}>{item.label}</Select.Option>
                                    ))
                                }
                            </Select>
                            {errors.trackingStatus && <FormFeedback>{errors.trackingStatus}</FormFeedback>}
                        </div>
                        <div className="CreateChild hideDropDown">
                            <label htmlFor="Carrier" className="">Original</label>
                            <Input id="Carrier" style={{ width: '100%' }} allowClear value={newOriginal} placeholder="Tags notes" onChange={_handleChangeNewOriginal} />
                            {errors.notes && <FormFeedback>{errors.notes}</FormFeedback>}
                        </div>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    )
}

TrackingMappingTable.propTypes = {
    index: PropTypes.number.isRequired,
    item: PropTypes.object.isRequired,
    handleClickDelete: PropTypes.func.isRequired

}

export default TrackingMappingTable