import React, {Component} from 'react'
import Profile from './Profile'
import {Page} from '@shopify/polaris'

class ProfileContainer extends Component {

    render() {

        return (
            <Page narrowWidth title="Profile">
                <Profile {...this.props}/>
            </Page>
        )
    }
}

export default ProfileContainer
