import React, {Component} from 'react'
import {Card, Page, Toast} from "@shopify/polaris"
import Pagination from "../../shared/Pagination"
import {getListJob} from "../../../services/api/PublicTrackingServices"
import ListJob from "./ListJob"
import FilterJob from "./FilterJob"

class ManageJobs extends Component {
    timeoutLoading = null

    state = {
        query: {
            source: ''
        },
        paging: {
            limit: 20,
            page: 1,
        },
        fetching: {
            loading: false,
            err: null,
        },
        jobs: {
            data: [],
            total: 0,
        },
        showToast: false
    }

    componentDidMount() {
        this._fetchListJob()
    }

    _fetchListJob = async () => {
        const {query, paging, fetching} = this.state
        if (fetching.loading) return

        this._setFetching(true)

        try {
            const {success, data: resp, message} = await getListJob({...query, ...paging})

            if (!success) throw new Error(message)

            this._setFetching(false, message)

            const {total, jobs} = resp

            this.setState({jobs: {data: jobs, total}})
        } catch (e) {
            this._setFetching(false, e.message)
            alert(e.message)
        }
    }

    _setQuery = (filter, delayLoading = false) => this.setState(({query, paging}) => ({
        query: {
            ...query, ...filter,
        },
        paging: {...paging, page: 1},
    }), () => {
        if (!delayLoading) return this._fetchListJob()

        if (this.timeoutLoading) clearTimeout(this.timeoutLoading)
        this.timeoutLoading = setTimeout(async () => await this._fetchListJob(), 1000)
    })

    _setFetching = (loading, err = null) => {
        const {jobs} = this.state
        this.setState({
            fetching: {loading, err},
            jobs: {
                ...jobs,
                ...loading ? {data: []} : {},
            },
        })
    }

    _submitQuery = () => {
        const {paging} = this.state
        this.setState({paging: {...paging, page: 1}}, () => this._fetchListJob())
    }

    _switchPage = page => () => {
        const {paging} = this.state

        this.setState({
            paging: {...paging, page},
        }, () => this._fetchListJob())
    }

    toggleActive = () => this.setState({showToast: false})

    render() {
        const {jobs, query, fetching, paging, showToast} = this.state
        const {total, data} = jobs
        const {loading} = fetching
        const totalPage = Math.ceil(total / paging.limit)
        const label = (totalPage > 0 && paging.page + '/' + totalPage) || null
        const toastMarkup = showToast ? (
            <Toast content={`${fetching.err}`} error onDismiss={this.toggleActive}/>
        ) : null

        return (
            <Page fullWidth title="Manage Jobs">
                {toastMarkup}
                <div className="ManageJobs">
                    <Card sectioned>
                        <Card.Subsection>
                            <FilterJob loading={loading} query={query} setQuery={this._setQuery}/>
                        </Card.Subsection>
                        <Card.Subsection>
                            <ListJob
                                total={total}
                                data={data}
                                loading={loading}
                                filter={query}
                                paging={paging}
                                setFilter={this._setQuery}
                                submitFilter={this._submitQuery}
                                refresh={this._fetchListJob}
                            />
                        </Card.Subsection>
                        <Card.Subsection>
                            <Pagination
                                label={label}
                                showQuickJumper
                                onSwitchPage={this._switchPage}
                                amount={totalPage}
                                current={paging.page}
                            />
                        </Card.Subsection>
                    </Card>
                </div>
            </Page>
        )
    }
}

export default ManageJobs
