import React, { useState } from 'react'
import { Select, Modal, Input } from 'antd'
import { FormFeedback } from 'reactstrap'
import { CirclePlusMajorMonotone } from '@shopify/polaris-icons'
import { Button } from '@shopify/polaris'

function TrackingSourceCreate(props) {
    const { handleClickAdd } = props
    const [name, setName] = useState('')
    const [code, setCode] = useState('')
    const [alias, setAlias] = useState([])
    const [searchAlias, setSearchAlias] = useState('')
    const [source, setSource] = useState([])
    const [decription, setDecription] = useState('')
    const [visible, setVisible] = useState(false)
    const [errors, setErrors] = useState({})

    const _handleChangeInput = (field, value) => {
        if (field === 'name') {
            setName(value)
            delete errors.name
        }
        if (field === 'code') {
            setCode(value)
            delete errors.code
        }
        if (field === 'alias') {
            setAlias(value)
            delete errors.alias
        }
        if (field === 'source') {
            setSource(value)
            delete errors.source
        }
        if (field === 'decriptions') {
            setDecription(value)
            delete errors.decription
        }
    }

    const _handleChangeSelect = (field, value) => {
        if (field === 'alias') {
            setAlias([...value])
            setSearchAlias('')
        }
        if (field === 'source') {
            setSource(value)
        }

    }
    const handleSearch = (value) => {
        if (value && value.includes(',')) {
            const newValue = [...new Set([...value.replace(/ /g, ',').split(',').map(i => i.trim()).filter(i => i), ...alias])]
            setAlias(newValue)
            setSearchAlias('')
        } else return setSearchAlias(value)
    }

    const showModal = () => {
        setVisible(true)
    }

    const hideModal = () => {
        setVisible(false)
        setName('')
        setCode('')
        setAlias([])
        setSource([])
        setDecription('')
        delete errors.name
        delete errors.code
        delete errors.alias
        delete errors.source
        delete errors.decription
    }

    const _validate = () => {
        let err = {}

        if (!name) err.name = 'Name is required'
        if (!code) err.code = 'Code is required'
        if (!alias.length) err.alias = 'Alias is required'
        if (!source.length) err.source = 'Source is required'

        setErrors(err)

        if (Object.keys(err).length >= 1) return
        const payload = {
            name: name,
            code: code,
            alias: alias,
            source: source,
            decription: decription
        }
        handleClickAdd(payload)
        setVisible(false)
        setName('')
        setCode('')
        setAlias([])
        setSource([])
        setDecription('')
    }

    return (
        <React.Fragment>
            <div className="mb-3 TrackingSourceCreate">
                <Button primary onClick={showModal} icon={CirclePlusMajorMonotone}>
                    Add new
                </Button>
            </div>
            <Modal
                title="Create source"
                visible={visible}
                onOk={_validate}
                onCancel={hideModal}
                okText="Add"
                cancelText="Cancel"
            >
                <div className="TrackingSourceCreate">
                    <div className="CreateContent">
                        <div className="CreateChild required">
                            <label>Name</label>
                            <Input
                                value={name || []}
                                onChange={(e) => _handleChangeInput('name', e.target.value)}
                                placeholder="Input Name"
                                style={{ width: '100%' }}
                                allowClear
                            />
                            {errors.name && <FormFeedback>{errors.name}</FormFeedback>}
                        </div>
                        <div className="CreateChild required">
                            <label>Code</label>
                            <Input
                                value={code || []}
                                onChange={(e) => _handleChangeInput('code', e.target.value)}
                                placeholder="Input code"
                                style={{ width: '100%' }}
                                allowClear
                            />
                            {errors.code && <FormFeedback>{errors.code}</FormFeedback>}
                        </div>
                        <div className="CreateChild required">
                            <label>Alias</label>
                            <Select
                                mode={'tags'}
                                value={alias || []}
                                onChange={(value) => _handleChangeSelect('alias', value)}
                                placeholder="Input Alias"
                                style={{ width: '100%' }}
                                onSearch={handleSearch}
                                searchValue={searchAlias}
                                open={false}
                                virtual={false}
                                allowClear
                            />
                            {errors.alias && <FormFeedback>{errors.alias}</FormFeedback>}
                        </div>
                        <div className="CreateChild">
                            <label>Source</label>
                            <Select
                                value={source || []}
                                onChange={(value) => _handleChangeSelect('source', value)}
                                placeholder="Select Source"
                                style={{ width: '100%' }}
                                mode={'multiple'}
                                allowClear
                            >
                                <Select.Option value="crawl">Crawl</Select.Option>
                                <Select.Option value="trackingmore">Tracking more</Select.Option>
                                <Select.Option value="multrans">Multrans</Select.Option>
                                {/* <Select.Option value="Off">Off</Select.Option> */}
                            </Select>
                            {errors.source && <FormFeedback>{errors.source}</FormFeedback>}
                        </div>
                        <div className="CreateChild">
                            <label htmlFor="Carrier" className="">Decriptions</label>
                            <Input.TextArea
                                rows={3}
                                placeholder="Decriptions"
                                value={decription}
                                className="OriginalNote"
                                onChange={(e) => _handleChangeInput('decriptions', e.target.value)}
                            />
                            {errors.decription && <FormFeedback>{errors.decription}</FormFeedback>}
                        </div>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default TrackingSourceCreate