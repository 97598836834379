import createAPIServices from './createApiServices'

const baseUrl = process.env.NODE_ENV === 'production' ? '/api/trackingNumbers/v2' : 'https://staging-tracking-v2.merchize.com/api/trackingNumbers/v2'
const api = createAPIServices({baseUrl})

export const createTrackingNumber = (data) => {
    return api.makeAuthRequest({
        url: '/',
        method: 'post',
        data,
    })
}

export const getTrackingNumbers = ({carrier, tracking_code, status, limit, last_id}) => {
    return api.makeAuthRequest({
        url: '/list',
        method: 'get',
        params: {
            tracking_code,
            limit,
            status,
            carrier,
            last_id
        }
    })
}

export const importCSV = (file) => {
    return api.makeAuthRequest({
        url: '/import-csv',
        method: 'post',
        data: file,
    })
}

export const exportCSV = (query) => {
    const _query = Object.keys(query).map(key => `${key}=${query[key]}`).join('&')

    return api.makeAuthRequest({
        url: `export-csv?${_query}`,
        method: 'get',
    })
}

export const forceUpdateTracking = (data) => {
    return api.makeAuthRequest({
        url: 'force-update-tracking',
        method: 'POST',
        data: data
    })
}


