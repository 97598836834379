import React, {Component} from 'react'
import {Page} from '@shopify/polaris'
import LoginForm from './LoginForm'

class LoginContainer extends Component {
    render() {
        return (
            <Page narrowWidth>
                <LoginForm/>
            </Page>
        )
    }
}

export default LoginContainer
