import React, {Component} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import ManageWebhooks from './ManageWebhooks'
import ViewWebhook from './ViewWebhook'

// import {Page} from '@shopify/polaris'

class ManageWebhooksContainer extends Component {

    render() {
        const {basePath} = this.props

        return (
            <Switch>
                <Route exact path={`${basePath}/manage-webhooks`} render={() => <ManageWebhooks {...this.props}/>}/>
                <Route path={`${basePath}/manage-webhooks/:_id`} component={ViewWebhook}/>
                <Route path={'*'}>
                    <Redirect to={`${basePath}/manage-webhooks`}/>
                </Route>
            </Switch>
        )
    }
}

//TrackingNumbersContainer.propTypes = {}

export default ManageWebhooksContainer
