import React, { useState } from 'react'
import { CirclePlusMajorMonotone } from '@shopify/polaris-icons'
import { Button } from '@shopify/polaris'
import PropTypes from 'prop-types'
import WebhookSettingCreateModal from './WebhookSettingCreateModal'


const WebhookSettingCreate = (props) => {
    const [visible, setVisible] = useState(false)

    const showModal = () => {
        setVisible(true)
    }

    return (
        <React.Fragment>
            <Button primary onClick={showModal} icon={CirclePlusMajorMonotone}>
                Add new
            </Button>
            {visible && <WebhookSettingCreateModal visible={visible} setVisible={setVisible} showModal={showModal} {...props}/>}
        </React.Fragment>
    )
}

WebhookSettingCreate.propTypes = {
    handleClickAdd: PropTypes.func.isRequired,
}

export default WebhookSettingCreate