import React, {Component} from 'react'
import {Link} from "react-router-dom"
import PropTypes from "prop-types"

class ReturnPage extends Component {

    render() {
        const {url, title,onGoBack, ...rest} = this.props
        return (
            <div className="ReturnPage" {...rest}>
                <Link onClick={onGoBack} to={url} className="BackButton">
                    <i className="fas fa-chevron-left" />
                    <span className="ml-3">{title}</span>
                </Link>
            </div>
        )
    }
}

ReturnPage.propTypes = {
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
}

export default ReturnPage

