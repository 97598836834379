import React from 'react'
import WebhookSetting from './WebhookSetting'

const WebhookSettingContainer = () => {
    return (
        <div className="WebhookSettingContainer">
            <WebhookSetting/>
        </div>
    )
}

export default WebhookSettingContainer
