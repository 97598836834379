import React, {Component} from 'react'
import {Line} from "react-chartjs-2"
import {getListBandWidth, getCost} from "../../../../services/api/ManageProxyServices"
import moment from "moment"
import {DatePicker} from 'antd'

const {RangePicker} = DatePicker

class BandWidth extends Component {
    state = {
        loading: false,
        error: '',
        from: moment().subtract(14, 'days').startOf('day'),
        to: moment().endOf('day'),
        bwSum: [],
        cost: {}
    }

    componentDidMount() {
        this._fetchBandWidth()
        this._fetchCost()
    }

    _fetchBandWidth = async () => {
        this.setState({
            loading: true,
            error: ''
        })

        try {
            const {from, to} = this.state

            const query = {
                from: new Date(from),
                to: new Date(to),
            }

            const response = await getListBandWidth(query)

            const {success, data, message} = response

            if (!success) {
                return this.setState({
                    loading: false,
                    error: message
                })
            }

            const {data_center: dataCenter} = data.data

            this.setState({
                loading: false,
                bwSum: this._convertToGB(dataCenter.bw_sum)
            })
        } catch (e) {
            this.setState({
                loading: false,
                error: e.message
            })
        }
    }

    _fetchCost = async () => {
        this.setState({
            loading: true,
            error: ''
        })

        try {
            const {from, to} = this.state

            const query = {
                from: new Date(from),
                to: new Date(to),
            }

            const response = await getCost(query)

            const {success, data, message} = response

            if (!success) {
                return this.setState({
                    loading: false,
                    error: message
                })
            }

            const {custom} = data

            this.setState({
                loading: false,
                cost: custom
            })
        } catch (e) {
            this.setState({
                loading: false,
                error: e.message
            })
        }
    }

    _getDates = (startDate, endDate) => {
        let dateArray = []
        let currentDate = moment(startDate)
        let stopDate = moment(endDate)

        while (currentDate <= stopDate) {
            dateArray.push(moment(currentDate).format('DD-MM-YYYY'))
            currentDate = moment(currentDate).add(1, 'days')
        }

        return dateArray
    }

    _convertToGB = (arrValue) => {
        const newArr = []

        if (!!arrValue && arrValue.length > 0) {
            arrValue.map(item => {
                const newValue = item === 0 ? 0 : (item / (1000 * 1000 * 1000)).toFixed(2)

                return newArr.push(newValue)
            })
        }

        return newArr
    }

    _onChange = (value, dateString) => {
        if (!!value && value.length > 1) {
            this.setState({
                from: moment(dateString[0], 'DD/MM/YYYY').startOf('day'),
                to: moment(dateString[1], 'DD/MM/YYYY').endOf('day'),
            }, () => {
                this._fetchBandWidth()
                this._fetchCost()
            })
        }
    }

    render() {
        const {from, to, bwSum, cost} = this.state

        const data = {
            labels: this._getDates(from, to),
            datasets: [
                {
                    label: "SUM",
                    data: bwSum,
                    fill: true,
                    backgroundColor: "rgba(32, 46, 120, 0.2)",
                    borderColor: "#202e78"
                }
            ]
        }

        const legend = {
            display: false
        }

        const options = {
            title: {
                display: true,
                text: 'Bandwidth chart'
            },
            tooltips: {
                enabled: true,
                mode: 'single',
                callbacks: {
                    label: function (tooltipItems, data) {
                        return tooltipItems.yLabel + 'GB';
                    }
                }
            },
        }

        return (
            <div className="BandWidth">
                <div className="Filter">
                    <label>Select date range</label>
                    <RangePicker defaultValue={[from, to]} format="DD/MM/YYYY" onChange={this._onChange}/>
                </div>
                <div className="CostContent">
                    <div className="Title">
                        <h3>Bandwidth</h3>
                        <div className="Content">
                            <span>{!!cost.bw ? ((cost.bw / (1000 * 1000 * 1000)).toFixed(2)) : 0 || 0}GB</span>
                        </div>

                    </div>
                </div>
                <div className="CostContent">
                    <div className="Title">
                        <h3>Cost</h3>
                        <div className="Content">
                            <span>${cost.cost || 0}</span>
                        </div>

                    </div>
                </div>
                <Line data={data} legend={legend} options={options}/>
            </div>
        )
    }
}

export default BandWidth
