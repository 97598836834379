import React from 'react'
import {CalloutCard} from '@shopify/polaris'
import {deleteUser} from '../../../services/api/UserServices'
import UserEliminationModal from './UserEliminationModal'
import PropTypes from 'prop-types'

class UserElimination extends React.Component {
    state = {
        loading: false,
        toggle: false,
    }

    _deleteUser = async () => {
        const {_id, history} = this.props
        this.setState({
            loading: true,
        })
        const {success, /*data,*/ message} = await deleteUser(_id)

        if (!success) throw new Error(message)
        // const {setViewUserState} = this.props
        // this._setUserState({role, status: 'change_role'}, () => {
        //     const {user} = this.state
        //     setViewUserState({user})
        // })

        history.goBack()
    }

    _toggleModal = () => {
        this.setState(state => ({
            toggle: !state.toggle,
        }))
    }

    render() {
        const {toggle, loading} = this.state
        const {user} = this.props
        const {name} = user

        return (
            <React.Fragment>
                <UserEliminationModal
                    open={toggle}
                    loading={loading}
                    toggle={this._toggleModal}
                    save={this._deleteUser}
                    user={user}
                />
                <CalloutCard
                    title="Eliminate account"
                    primaryAction={{
                        content: 'Eliminate account',
                        onAction: this._toggleModal,
                        destructive: true,
                    }}
                >
                    <p>Eliminate the <strong>{name || 'user'}</strong>'s account.</p>
                </CalloutCard>
            </React.Fragment>
        )
    }
}

UserElimination.propTypes = {
    user: PropTypes.object.isRequired,
    _id: PropTypes.string.isRequired,
    fetchUser: PropTypes.func.isRequired,
    setViewUserState: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
}

export default UserElimination
