import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Modal, TextField, TextStyle, InlineError} from '@shopify/polaris'

class EditModal extends Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            message: {
                error: '',
                success: '',
            },
            _id: props.edit._id,
            name: props.edit.name
        }
    }


    static getDerivedStateFromProps(nextProps, nextState) {
        if (nextProps.edit._id !== nextState._id) return {
            loading: false,
            message: {
                error: '',
                success: '',
            },
            _id: nextProps.edit._id,
            name: nextProps.edit.name
        }

        return nextState
    }

    _clickSave = async () => {
        const {save} = this.props
        const {loading, name, _id} = this.state
        if (loading) return

        this.setState({
            loading: true
        })

        try {
            await save({_id, name})

            this.setState({
                loading: false,
                message: {
                    error: '',
                    success: 'Edit successfully!',
                }
            })
        } catch (e) {
            this.setState({
                loading: false,
                message: {
                    error: e.message || 'Error!',
                    success: '',
                }
            })
            alert(e.message || 'Error!')
        }
    }

    render() {
        const {toggle, edit} = this.props
        const {loading, message, name} = this.state

        return (
            <Modal
                open={edit.open}
                onClose={toggle}
                title={`Edit`}
                primaryAction={{
                    content: 'Save',
                    onAction: this._clickSave,
                    disabled: edit.name === name || loading,
                }}
                secondaryActions={[
                    {
                        content: 'Close',
                        onAction: toggle,
                        disabled: loading,
                    },
                ]}
            >
                <Modal.Section>
                    <InlineError message={message.error}/>
                    <TextStyle variation='positive'>{message.success}</TextStyle>
                    <TextField
                        value={name}
                        onChange={value => this.setState({
                            name: value
                        })}
                        label="Name"
                    />
                    <TextField
                        value={edit.prefix}
                        disabled
                        label="Prefix"
                    />
                    <TextField
                        value={edit.status}
                        disabled
                        label="Status"
                    />
                </Modal.Section>
            </Modal>
        )
    }
}

EditModal.propTypes = {
    toggle: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    edit: PropTypes.object.isRequired,
}


export default EditModal
