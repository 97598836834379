import React from 'react'
import {FormLayout, TextField, TextStyle, Card, InlineError, ButtonGroup, Button, Form} from '@shopify/polaris'
import {updateUser, updateRole} from '../../../services/api/UserServices'
import safeTrim from '../../../helpers/safeTrim'
import {SAVE_USER_PROFILE_ERROR, SAVE_USER_PROFILE_SUCCESS, /*DISCARD_USER_PROFILE_ERROR*/} from '../static/message'
import UpdateRoleModal from './UpdateRoleModal'
import PropTypes from 'prop-types'

const _parseUser = ({email, name, status}) => {
    const user = {}
    if (safeTrim(email) !== '') user.email = email
    if (safeTrim(name) !== '') user.name = name
    if (safeTrim(status) !== '') user.status = status

    return user
}

class UserDetail extends React.Component {
    constructor(props) {
        super(props)

        const {user} = props

        this.state = {
            isSubmit: false,
            roleModal: {
                toggle: false,
            },
            message: {
                error: '',
                success: '',
            },
            user: {
                ...user,
            },
        }
    }

    _setUserState = (updatedUser, callback) => {
        this.setState(state => ({
            user: {
                ...state.user,
                ...updatedUser,
            },
        }), callback)
    }

    _onChangeUser = field => value => {
        this.setState(state => ({
            user: {
                ...state.user,
                [field]: value,
            },
        }))
    }

    _updateUser = async ({_id, name, email, status}) => {
        const user = _parseUser({name, email, status})
        const {success, data, message} = await updateUser(_id, user)

        if (!success) throw new Error(message)

        const {setViewUserState} = this.props
        this._setUserState(data, () => {
            const {user} = this.state
            setViewUserState({user})
        })
    }

    _changeRole = async ({role}) => {
        const {user: {_id}} = this.state
        this.setState({
            isSubmit: true,
        })
        const {success, /*data,*/ message} = await updateRole(_id, {role})

        if (!success) throw new Error(message)
        const {setViewUserState} = this.props
        this._setUserState({role}, () => {
            const {user} = this.state
            setViewUserState({user})
        })

        this.setState({
            isSubmit: false,
        })
    }

    handleSave = async () => {
        const {user} = this.state
        this.setState({
            isSubmit: true,
        })
        try {
            await this._updateUser(user)

            this.setState({
                isSubmit: false,
                message: {
                    error: '',
                    success: SAVE_USER_PROFILE_SUCCESS,
                },
            })
        } catch (e) {
            this.setState({
                isSubmit: false,
                message: {
                    error: SAVE_USER_PROFILE_ERROR,
                    success: '',
                },
            })
            alert(e.message || SAVE_USER_PROFILE_ERROR)
        }
    }

    handleDiscard = async () => {
        const {user} = this.props

        this._setUserState(user)
    }

    _canSave = ({name, email}) => {
        return name && email
    }

    _canDiscard = ({name: _name, email: _email, status: _status}, {name, email, status}) => {
        return (_name !== name || _email !== email || _status !== status)
    }

    _toggleChangeRoleModal = () => {
        this.setState(state => ({
            roleModal: {
                ...state.roleModal,
                toggle: !state.roleModal.toggle,
            },
        }))
    }

    render() {
        const {roleModal, user, isSubmit, message} = this.state
        const {user: oldUser} = this.props
        const {name, email, status, role} = user

        const canDiscard = this._canDiscard(oldUser, user) && !isSubmit
        const canSave = this._canSave(user) && canDiscard

        return (
            <React.Fragment>
                <UpdateRoleModal
                    open={roleModal.toggle}
                    loading={isSubmit}
                    toggle={this._toggleChangeRoleModal}
                    save={this._changeRole}
                    role={role}
                />
                <Card
                    title='User Detail'
                >
                    <Card.Section>
                        <Form onSubmit={this.handleSave}>
                            <FormLayout>
                                <TextField
                                    type='text'
                                    label='Name'
                                    value={name}
                                    onChange={this._onChangeUser('name')}
                                />
                                <TextField
                                    type='email'
                                    label='Email'
                                    value={email}
                                    onChange={this._onChangeUser('email')}
                                />
                                <TextField
                                    disabled
                                    type='role'
                                    label='Role'
                                    value={role}
                                    labelAction={{
                                        content: 'Change Role',
                                        onAction: this._toggleChangeRoleModal,
                                        disabled: isSubmit,
                                    }}
                                />
                                <TextField
                                    type='text'
                                    label='Status'
                                    value={status}
                                    onChange={this._onChangeUser('status')}
                                    disabled
                                />
                                <InlineError message={message.error}/>
                                <TextStyle variation='positive'>{message.success}</TextStyle>
                            </FormLayout>
                            <div className='pt-6'>
                                <ButtonGroup>
                                    <Button disabled={!canDiscard} onClick={this.handleDiscard}>Discard</Button>
                                    <Button submit primary loading={isSubmit} disabled={!canSave}>Save</Button>
                                </ButtonGroup>
                            </div>
                        </Form>
                    </Card.Section>
                </Card>
            </React.Fragment>
        )
    }
}

UserDetail.propTypes = {
    user: PropTypes.object.isRequired,
    _id: PropTypes.string.isRequired,
    fetchUser: PropTypes.func.isRequired,
    setViewUserState: PropTypes.func.isRequired,
}

export default UserDetail
