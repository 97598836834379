import createAPIServices from './createApiServices'

const baseUrl = process.env.NODE_ENV === 'production' ? '/api' : 'https://staging-tracking-v2.merchize.com/api'
const api = createAPIServices({baseUrl})

export const getListProxy = (payload) => {
    return api.makeAuthRequest({
        url: '/proxy',
        method: 'GET',
        params: payload
    })
}

export const syncListProxyFromPartner = () => {
    return api.makeAuthRequest({
        url: '/proxy/sync',
        method: 'GET',
    })
}

export const toggleStatusProxy = (id, data) => {
    return api.makeAuthRequest({
        url: `/proxy/${id}`,
        method: 'PUT',
        data: data
    })
}

export const getListBandWidth = ({from, to}) => {
    return api.makeAuthRequest({
        url: '/proxy/bandwidth',
        method: 'GET',
        params: {
            from, to
        }
    })
}

export const getCost = ({from, to}) => {
    return api.makeAuthRequest({
        url: '/proxy/cost',
        method: 'GET',
        params: {
            from, to
        }
    })
}
export default api