import React, {useState} from 'react'
import {DataTable} from '@shopify/polaris'
import ObjectInspector from "react-object-inspector"
import {withRouter} from 'react-router-dom'
import humanizeTime from '../../../helpers/humanizeTime'
import {Checkbox, message, Switch} from 'antd'
import BulkActions from './BulkActions'
import {updateBatchStatus} from '../../../services/api/WebhookSettingsServices'

const columnContentTypes = [
    'text', 'text', 'text', 'text', 'text', 'text', 'text'
]

const ListWebhookSetting = (props) => {
    const {data, onCheck, totalCheck, isCheckAll, handleSelectAll, setTotalCheck, setIsCheckAll, fetchListWebhooks} = props
    const [loadingToggle] = useState(false)

    const cols = !!totalCheck.length ?
        [
            <Checkbox
                checked={isCheckAll}
                onChange={handleSelectAll}
            />,
            <BulkActions
                totalCheck={totalCheck||[]}
                setTotalCheck={setTotalCheck}
                setIsCheckAll={setIsCheckAll}
                fetchListWebhooks={fetchListWebhooks}
            />,
            '', '', '', '', '', '',
        ] :
        [
            <Checkbox
                checked={isCheckAll}
                onChange={handleSelectAll}
            />,
            'Tracking Number',
            'Object',
            'Event',
            'Endpoint',
            'Created',
            'Enabled'
        ]

    const _mapRows = data => {
        return data.map((item = {}, index) => {
            const {event, tracking_code, url, created_at, meta} = item

            const statusColumn = <div>
                <Switch
                    loading={loadingToggle}
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    defaultChecked={item.status === 'active'}
                    onChange={(checked) => _updateStatusProxy(checked, index)}
                />
            </div>

            return [
                <Checkbox
                    data={item}
                    checked={totalCheck.includes(item)}
                    onChange={onCheck}
                />,
                tracking_code,
                <ObjectInspector depth={0} path="root.value" data={meta} />,
                event,
                url,
                (created_at && humanizeTime(created_at)) || null,
                statusColumn
            ]
        })
    }

    const _updateStatusProxy = async (checked, index) => {
        const objItem = data[index]
        const status = checked ? 'active' : 'inactive'
        const _ids = [objItem._id]
        try {
            const {success, message: error} = await updateBatchStatus(status, _ids)
            if (!success) {
                message.error(error)
            }
            message.success('Update status successfully!')
            fetchListWebhooks()
        } catch (e) {
            message.error(e.message)
        }
    }

    return (
        <React.Fragment>
            <DataTable
                columnContentTypes={columnContentTypes}
                headings={cols}
                rows={_mapRows(data)}
            />
        </React.Fragment>
    )
}

export default withRouter(ListWebhookSetting)
