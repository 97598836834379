import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {DataTable, TextStyle, Button, ButtonGroup} from '@shopify/polaris'
import {withRouter} from 'react-router-dom'
import humanizeTime from '../../../helpers/humanizeTime'
import statuses from '../static/statuses'
import EditModal from './EditModal'
import RevokeModal from './RevokeModal'

const tableCol = ['Name', 'Prefix', 'Status', 'Created time', 'Actions']

const columnContentTypes = [
    'text', 'text', 'text', 'text', 'text',
]

class ListAPIKey extends Component {
    state = {
        edit: {
            open: false,
            name: '',
            prefix: '',
            status: '',
            _id: '',
        },
        revoke: {
            open: false,
            name: '',
            prefix: '',
            status: '',
            _id: '',
        },
    }

    _onClickToggle = field => ({_id, name, prefix, status, created_at}) => () => {
        this.setState(state => {
            const _state = state[field]
            return {
                [field]: {
                    _id, name, prefix, status,
                    open: !_state.open
                }
            }
        })
    }

    _onClickClose = field => () => {
        this.setState(state => {
            const _state = state[field]
            return {
                [field]: {
                    ..._state,
                    open: !_state.open
                }
            }
        })
    }

    _mapRows = data => {
        return data.map((item = {}) => {
            const {name, prefix, status, created_at} = item

            const viewName = name
            const viewPrefix = prefix

            const variationStatus = (statuses.find(({value}) => value === status) || {}).variation || 'subdued'
            const viewStatus = <TextStyle variation={variationStatus}>{status}</TextStyle>

            const actions =
                <div>
                    <ButtonGroup>
                        <Button onClick={this._onClickToggle('edit')(item)} size="slim">Edit</Button>
                        {status !== 'revoked' &&
                        <Button onClick={this._onClickToggle('revoke')(item)} size="slim" destructive>Revoke</Button>}
                    </ButtonGroup>
                </div>

            return [
                viewName, viewPrefix, viewStatus,
                humanizeTime(created_at),
                actions
            ]
        })
    }

    _saveEdit = async (item) => {
        const {handleEdit} = this.props

        await handleEdit(item)

        this.setState(({edit}) => ({
            edit: {
                ...edit,
                ...item,
                open: false,
            }
        }))
    }

    _saveRevoke = async (item) => {
        const {handleRevoke} = this.props

        await handleRevoke(item)

        this.setState(({revoke}) => ({
            revoke: {
                ...revoke,
                ...item,
                open: false,
            }
        }))
    }

    render() {
        const {data, total, paging} = this.props
        const {edit, revoke} = this.state
        const rows = this._mapRows(data)
        const page = paging.page
        const limit = paging.limit
        const startShow = (page - 1) * limit + 1 > total ? total : (page - 1) * limit + 1
        const endShow = page * limit > total ? total : page * limit
        const footer = `Showing ${startShow} - ${endShow} of ${total} results`

        return (
            <React.Fragment>
                <DataTable
                    columnContentTypes={columnContentTypes}
                    headings={tableCol}
                    rows={rows}
                    footerContent={footer}
                />
                <RevokeModal toggle={this._onClickClose('revoke')} edit={edit} save={this._saveRevoke} revoke={revoke}/>
                <EditModal toggle={this._onClickClose('edit')} save={this._saveEdit} edit={edit}/>
            </React.Fragment>

        )
    }
}

ListAPIKey.propTypes = {
    total: PropTypes.number.isRequired,
    data: PropTypes.array.isRequired,
    filter: PropTypes.object.isRequired,
    paging: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    handleEdit: PropTypes.func.isRequired,
    handleRevoke: PropTypes.func.isRequired,
}

export default withRouter(ListAPIKey)
