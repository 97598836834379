import createAPIServices from './createApiServices'

const baseUrl = process.env.NODE_ENV === 'production' ? '/api/user-service/users' : 'https://staging-tracking-v2.merchize.com/api/user-service/users'
const api = createAPIServices({ baseUrl })


export const getMe = () => {
    return api.makeAuthRequest({
        url: '/me',
        method: 'get',
    })
}

