import React from 'react'
import ReactDOM from 'react-dom'
import {Router} from 'react-router-dom'
import getHistory from './store/getHistory'
import 'antd/dist/antd.min.css'
import './scss/app.scss'
import App from './app/App'
import enTranslations from '@shopify/polaris/locales/en.json'
import {AppProvider} from '@shopify/polaris'
import theme from './polarisTheme/index'
import {AuthContextProvider} from './app/auth/context/AuthContext'

ReactDOM.render((
    <AppProvider i18n={enTranslations} theme={theme}>
        <Router history={getHistory()}>
            <AuthContextProvider>
                <App/>
            </AuthContextProvider>
        </Router>
    </AppProvider>
), document.getElementById('root'))
