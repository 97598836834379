const statuses = [
    {'value': 'unknown', 'label': 'Unknown'},
    {'value': 'pre_transit', 'label': 'Pre transit'},
    {'value': 'in_transit', 'label': 'In transit'},
    {'value': 'out_for_delivery', 'label': 'Out for delivery'},
    {'value': 'delivered', 'label': 'Delivered'},
    {'value': 'exception', 'label': 'Exception'}
]

export default statuses
