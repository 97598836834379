import createAPIServices from './createApiServices'
import {getUserId} from '../AuthServices'

const baseUrl = process.env.NODE_ENV === 'production' ? '/api/user-service/users' : 'https://staging-tracking-v2.merchize.com/api/user-service/users'
const api = createAPIServices({baseUrl})

export const auth = ({email, password}) => {
    return api.makeRequest({
        url: '/auth',
        method: 'post',
        data: {
            email,
            password,
        },
    })
}

export const getListWebhookByOwner = (owner, {name, prefix, status, limit, page, start_date, end_date}) => {
    return api.makeAuthRequest({
        url: `/${owner}/webhooks`,
        method: 'get',
        params: {name, prefix, status, limit, page, start_date, end_date}
    })
}

export const getListUser = ({name, email, role, status, limit, page, start_date, end_date}) => {
    return api.makeAuthRequest({
        url: '',
        method: 'get',
        params: {name, email, role, status, limit, page, start_date, end_date},
    })
}

export const getUser = (userId) => {
    return api.makeAuthRequest({
        url: `/${userId}`,
        method: 'get',
    })
}

export const insertUser = ({email, name, role, password}) => {
    return api.makeAuthRequest({
        url: ``,
        method: 'post',
        data: {email, name, role, password},
    })
}

export const deleteUser = (userId) => {
    return api.makeAuthRequest({
        url: `/${userId}`,
        method: 'delete',
    })
}

export const getMe = () => {
    return api.makeAuthRequest({
        url: `/me`,
        method: 'get',
    })
}

export const updateUser = (userId, {name}) => {
    return api.makeAuthRequest({
        url: `/${userId}`,
        method: 'put',
        data: {name},
    })
}

export const updateMe = ({name}) => {
    const userId = getUserId()
    return api.makeAuthRequest({
        url: `/${userId}`,
        method: 'put',
        data: {name},
    })
}

export const changePass = ({oldPassword, newPassword}) => {
    const userId = getUserId()

    return api.makeAuthRequest({
        url: `/change-password/${userId}`,
        method: 'post',
        data: {oldPassword, newPassword},
    })
}

export const resetPass = (userId, {newPassword}) => {
    return api.makeAuthRequest({
        url: `/reset-password/${userId}`,
        method: 'post',
        data: {newPassword},
    })
}

export const updateRole = (userId, {role}) => {
    return api.makeAuthRequest({
        url: `/update-role/${userId}`,
        method: 'post',
        data: {role},
    })
}

export default api
