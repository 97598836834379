import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {ChoiceList, Filters, Loading} from '@shopify/polaris'
import statuses from '../static/statuses'
import carriers from '../static/carriers'
import keys from '../static/keys'

class TableFilter extends Component {
    _onChange = key => ([v]) => this.props.setFilter({[key]: v})

    _onChangeText = key => v => this.props.setFilter({[key]: v}, true)

    _onQueryClear = key => () => this.props.setFilter({[key]: ''})

    _makeAppliedFilters = filter => {
        const result = []

        Object.keys(filter).forEach(key => {
            if (key === 'last_id') return

            const label = (key === 'status') ? (statuses.find(i => i.value === filter[key]) || {}).label : filter[key]
            if (!label) return

            result.push({
                key,
                label: `${keys[key]}: ${label.toString()}`,
                onRemove: this._onQueryClear(key),
            })
        })

        return result
    }

    _clearFilter = () => {
        const {filter} = this.props
        const result = {}

        Object.keys(filter).forEach(key => {
            if (key === 'last_id') return

            result[key] = ''
        })
        this.props.setFilter(result)
    }

    render() {
        const {filter, loading} = this.props

        const filters = [{
            key: 'status',
            label: 'Status',
            filter: (
                <ChoiceList
                    title="Status"
                    choices={statuses}
                    selected={filter.status || []}
                    onChange={this._onChange('status')}
                />
            ),
        }, {
            key: 'carrier',
            label: 'Carrier',
            filter: (
                <ChoiceList
                    title="Carrier"
                    choices={carriers}
                    selected={filter.carrier || []}
                    onChange={this._onChange('carrier')}
                />
            ),
        }]

        const appliedFilters = this._makeAppliedFilters(filter)

        return (
            <React.Fragment>
                {loading && <Loading/>}
                <Filters
                    disabled={loading}
                    queryValue={filter.tracking_code}
                    filters={filters}
                    appliedFilters={appliedFilters}
                    queryPlaceholder={'Find by Tracking Number'}
                    onQueryChange={this._onChangeText('tracking_code')}
                    onQueryClear={this._onQueryClear('tracking_code')}
                    onClearAll={this._clearFilter}
                />
            </React.Fragment>
        )
    }
}

TableFilter.propTypes = {
    filter: PropTypes.object.isRequired,
    setFilter: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
}

export default TableFilter
