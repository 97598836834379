const keys = {
    'name': 'Name',
    'email': 'Email',
    'role': 'Role',
    'status': 'Status',
    'updated_at': 'Updated time',
    'created_at': 'Created time',
}

export default keys
