import createAPIServices from './createApiServices'

const baseUrl = process.env.NODE_ENV === 'production' ? '/api' : 'https://staging-tracking-v2.merchize.com/api'
const api = createAPIServices({baseUrl})

export const getMapping = data => {
    const {page, limit, ...body} = data
    return api.makeAuthRequest({
        url : `tracking-status`,
        method: 'POST',
        data : body,
        params : {page, limit}
    })
}

export const deleteMapping = data => {
    return api.makeAuthRequest({
        url: 'tracking-status/mapping',
        method : 'DELETE',
        data : data
    })
}

export const createMapping = data => {
    return api.makeAuthRequest({
        url : 'tracking-status/mapping',
        method : 'POST',
        data : data
    })
}

export const editMapping = data => {
    return api.makeAuthRequest({
        url : 'tracking-status/mapping',
        method : 'PUT',
        data : data
    })
}

export const getMappingV2 = data => {
    // const {page, limit, ...body} = data
    return api.makeAuthRequest({
        url : `mapping-status`,
        method: 'GET',
        // data : body,
        params : data
    })
}

export const createMappingV2 = data => {
    return api.makeAuthRequest({
        url : 'mapping-status',
        method : 'POST',
        data : data
    })
}

export const editMappingV2 = (id, data) => {
    return api.makeAuthRequest({
        url : `/mapping-status/${id}`,
        method : 'PUT',
        data : data
    })
}

export const deleteMappingV2 = async (id) => {
    return api.makeAuthRequest({
        url: `/mapping-status/${id}`,
        method : 'DELETE'
    })
}