import React, {Component} from 'react'
import {Layout, Page} from '@shopify/polaris'
import WebhookDetail from './WebhookDetail'
import WebhookDelete from './WebhookDelete'
import {withRouter} from 'react-router-dom'
import {deleteWebhookById, getWebhookById, updateWebhookById} from '../../../services/api/WebhookServices'

const getId = (pathname) => {
    if (!pathname) return null

    const pieces = pathname.split('/')

    return pieces[pieces.length - 1]
}

class ViewWebhook extends Component {
    state = {
        loading: true,
        webhook: null,
        _id: '',
    }

    _setState = args => this.setState(args)

    _updateWebhook = async (_id, webhook) => {
        const {success, data, message} = await updateWebhookById(_id, webhook)

        if (!success) throw new Error(message)

        this.setState({
            webhook: data
        })

        return {success, data, message}
    }

    _deleteWebhook = async (_id) => {
        const {success, data, message} = await deleteWebhookById(_id)

        if (!success) throw new Error(message)

        this.setState({
            webhook: data
        })

        return {success, data, message}
    }

    fetchWebhook = async _id => {
        const {success, message, data} = await getWebhookById(_id)

        if (!success) throw new Error(message)

        this.setState({
            webhook: data,
        })

        return data
    }

    _initView = async () => {
        const {location, history} = this.props
        const {pathname} = location

        const _id = getId(pathname)

        try {
            const webhook = await this.fetchWebhook(_id)
            this.setState({loading: false, _id, webhook})
        } catch (e) {
            alert(e.message)
            history.push('/d/manage-webhooks')
        }
    }

    componentDidMount() {
        this._initView()
    }

    render() {
        const {/*match, location,*/ history} = this.props
        const {loading, webhook, _id} = this.state

        return (
            <Page separator
                  breadcrumbs={[{content: 'List Webhooks', onAction: () => history.push('/d/manage-webhooks')}]}>
                <div className="Profile">
                    {
                        !loading && (
                            <Layout>
                                <Layout.Section>
                                    <WebhookDetail
                                        webhook={webhook}
                                        _id={_id}
                                        fetchWebhook={this.fetchWebhook}
                                        updateWebhook={this._updateWebhook}
                                    />
                                    <WebhookDelete
                                        webhook={webhook}
                                        _id={_id}
                                        fetchWebhook={this.fetchWebhook}
                                        deleteWebhook={this._deleteWebhook}
                                        history={history}
                                    />
                                </Layout.Section>
                            </Layout>
                        )
                    }
                </div>
            </Page>
        )
    }
}

export default withRouter(ViewWebhook)
