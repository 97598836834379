import React, { useEffect, useState } from 'react'
import { Select, Modal, Input, message } from 'antd'
import tracking_status from "../static/statuses"
import carriers from "../static/carriers"
import { FormFeedback } from 'reactstrap'
import { CirclePlusMajorMonotone } from '@shopify/polaris-icons'
import { Button } from '@shopify/polaris'
import PropTypes from 'prop-types'
import { createMappingV2 } from '../../../services/api/TrackingMappingServices'


const TrackingMappingCreate = (props) => {

    const { refreshTrackingMapping } = props
    const [notes, setNotes] = useState([])
    const [carrier, setCarrier] = useState('')
    const [visible, setVisible] = useState(false)
    const [errors, setErrors] = useState({})
    const [trackingStatus, setTrackingStatus] = useState('')
    const [location, setLocation] = useState('')
    const [type, setType] = useState('')
    const [trackingOrigin, setTrackingOrigin] = useState('')

    const _changeNote = value => {
        setNotes([value])
        delete errors.notes
    }

    const _handleChangeCarrier = carrier => {
        setCarrier(carrier)
        delete errors.carrier
    }

    const _handleChangeStatuses = status => {
        setTrackingStatus(status)
        delete errors.trackingStatus
    }

    const _handleChangeLocation = location => {
        setLocation(location)
        delete errors.location
    }

    const _handleChangeType = type => {
        setType(type)
        delete errors.type
    }

    const _handleChangeTrackingOrigin = value => {
        setTrackingOrigin(value)
        delete errors.trackingOrigin
    }

    const showModal = () => {
        setVisible(true)
    }

    const hideModal = () => {
        setVisible(false)
        // setNotes([])
        setTrackingStatus('')
        setCarrier('')
        setLocation('')
        setType('')
        setTrackingOrigin('')
        // delete errors.notes
        delete errors.carrier
        delete errors.trackingStatus
        delete errors.location
        delete errors.type
        delete errors.trackingOrigin
    }

    const _handleCreateMapp = async (carrier, status, status_mapping, type, location) => {
        try {
            const response = await createMappingV2({ carrier, status, status_mapping, type, location })
            const { message: mess, success } = response
            if (!success) return message.error(`${mess}!`)
            message.success('Create successfully!')
            hideModal()
            refreshTrackingMapping()
        } catch (e) {
            message.error(e.message || 'Unknown error.')
        }
    }

    const _validate = () => {
        let err = {}

        // if (notes.length === 0) err.notes = 'Original is required'
        if (!carrier) err.carrier = 'Carrier is required'
        if (!trackingOrigin.length) err.trackingOrigin = 'Tracking origin is required'
        if (!trackingStatus) err.trackingStatus = 'Tracking status is required'
        if (!location) err.location = 'Location is required'
        if (!type) err.type = 'Type is required'

        setErrors(err)

        if (Object.keys(err).length >= 1) return

        _handleCreateMapp(carrier, trackingOrigin, trackingStatus, type, location)
        // setVisible(false)
        // setNotes([])
        // setTrackingStatus('')
        // setCarrier('')
        // setTrackingOrigin('')
        // setLocation('')
        // setType('')
    }

    return (
        <React.Fragment>
            <div className="mb-3">
                <Button primary onClick={showModal} icon={CirclePlusMajorMonotone}>
                    Add new
                </Button>
            </div>
            <Modal
                title="Create mapping"
                visible={visible}
                onOk={_validate}
                onCancel={hideModal}
                okText="Add"
                cancelText="Cancel"
            >
                <div className="TrackingMappingCreate">
                    <div className="CreateContent">
                        <div className="CreateChild">
                            <label>Carriers</label>
                            <Select
                                value={carrier || []}
                                onChange={_handleChangeCarrier}
                                placeholder="Select carriers"
                                style={{ width: '100%' }}
                                allowClear
                            >
                                {
                                    carriers.length > 0 && carriers.map((item, index) => (
                                        <Select.Option key={index} value={item.value}>{item.label}</Select.Option>
                                    ))
                                }
                            </Select>
                            {errors.carrier && <FormFeedback>{errors.carrier}</FormFeedback>}
                        </div>
                        <div className="CreateChild">
                            <label htmlFor="Carrier" className="">Tracking Origin</label>
                            <Input
                                rows={3}
                                placeholder="Tracking origin"
                                value={trackingOrigin}
                                className="OriginalNote"
                                onChange={(e) => _handleChangeTrackingOrigin(e.target.value)}
                            />
                            {errors.trackingOrigin && <FormFeedback>{errors.trackingOrigin}</FormFeedback>}
                        </div>
                        <div className="CreateChild">
                            <label>Tracking status</label>
                            <Select
                                value={trackingStatus || []}
                                onChange={_handleChangeStatuses}
                                placeholder="Select Tracking Status"
                                style={{ width: '100%' }}
                                allowClear
                            >
                                {
                                    tracking_status.length > 0 && tracking_status.map((item, index) => (
                                        <Select.Option key={index} value={item.value}>{item.label}</Select.Option>
                                    ))
                                }
                            </Select>
                            {errors.trackingStatus && <FormFeedback>{errors.trackingStatus}</FormFeedback>}
                        </div>
                        <div className="CreateChild">
                            <label>Location</label>
                            <Select
                                value={location || []}
                                onChange={_handleChangeLocation}
                                placeholder="Select location"
                                style={{ width: '100%' }}
                                allowClear
                            >
                                <Select.Option value='vn'>vn</Select.Option>
                                <Select.Option value='default'>default</Select.Option>
                            </Select>
                            {errors.location && <FormFeedback>{errors.location}</FormFeedback>}
                        </div>
                        <div className="CreateChild">
                            <label>Type</label>
                            <Select
                                value={type || []}
                                onChange={_handleChangeType}
                                placeholder="Select type"
                                style={{ width: '100%' }}
                                allowClear
                            >
                                <Select.Option value='normal'>normal</Select.Option>
                                <Select.Option value='regex'>regex</Select.Option>
                            </Select>
                            {errors.type && <FormFeedback>{errors.type}</FormFeedback>}
                        </div>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    )
}

TrackingMappingCreate.propTypes = {
    handleClickAdd: PropTypes.func.isRequired,
}

export default TrackingMappingCreate