import React from 'react'
import WebhookNotification from './WebhookNotification'

const WebhookNotificationContainer = () => {
    return (
        <div className="WebhookSettingContainer">
            <WebhookNotification/>
        </div>
    )
}

export default WebhookNotificationContainer
