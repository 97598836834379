import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {DisplayText, InlineError, Modal, TextContainer, TextStyle} from '@shopify/polaris'

class WebhookDeleteModal extends Component {

    state = {
        message: {
            error: '',
            success: '',
        },
    }

    _clickDelete = async () => {
        const {save} = this.props

        try {
            await save()
        } catch (e) {
            this.setState({
                message: {
                    error: e.message || 'Delete error.',
                    success: '',
                },
            })
            alert(e.message || 'Delete error.')
        }
    }

    render() {
        const {open, toggle, loading} = this.props
        const {message} = this.state
        return (
            <Modal
                title='Delete'
                open={open}
                onClose={toggle}
                primaryAction={{
                    content: 'Delete',
                    onAction: this._clickDelete,
                    loading: loading,
                    destructive: true,
                }}
                secondaryActions={[
                    {
                        content: 'Close',
                        onAction: toggle,
                        disabled: loading,
                    },
                ]}
            >
                <Modal.Section>
                    <InlineError message={message.error}/>
                    <TextStyle variation='positive'>{message.success}</TextStyle>
                    <TextContainer>
                        <DisplayText size="small">
                            <TextStyle variation="negative">Are you sure to delete ?</TextStyle>
                        </DisplayText>
                    </TextContainer>
                </Modal.Section>
            </Modal>
        )
    }
}

WebhookDeleteModal.propTypes = {
    open: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
}


export default WebhookDeleteModal
