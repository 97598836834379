import React, {Component} from 'react'
import {Layout} from '@shopify/polaris'
import ChangePass from './ChangePass'

class Security extends Component {

    render() {

        return (
            <div className="Security">
                <Layout>
                    <Layout.Section>
                        <ChangePass/>
                    </Layout.Section>
                </Layout>
            </div>
        )
    }
}

export default Security
