import React, { useState } from 'react'
import {message} from 'antd'
import { ActionList, Popover, Button } from '@shopify/polaris'
import {updateBatchStatus} from '../../../services/api/WebhookSettingsServices'


function BulkActionRetryCrawl(props) {
    const {totalCheck, setTotalCheck, setIsCheckAll, fetchListWebhooks} = props
    const [popoverActive, setPopoverActive] = useState(false)

    const togglePopoverActive = () => {
        setPopoverActive((popoverActive) => !popoverActive)
    }

    const activator = (
        <Button onClick={togglePopoverActive} disclosure>
            Actions
        </Button>
    )

    const updateStatus = async (status) => {
        const _ids = totalCheck.map(item => item._id)
        try {
            const {success, message: error} = await updateBatchStatus(status, _ids)
            if (!success) {
                message.error(error)
            }

            message.success('Update status successfully!')
            fetchListWebhooks()
            setTotalCheck([])
            setIsCheckAll(false)
        } catch (e) {
            message.error(e.message)
        }
    }

    return (
        <div className='BulkActionRetryCrawl'>
            <div style={{ height: 'auto' }}>
                <Popover
                    active={popoverActive}
                    activator={activator}
                    autofocusTarget="first-node"
                    onClose={togglePopoverActive}
                >
                    <Popover.Pane>
                        <ActionList
                            actionRole="menuitem"
                            items={[
                                { content: <a onClick={() => updateStatus('active')}>Enable</a> },
                                { content: <a onClick={() => updateStatus('inactive')}>Disable</a> },
                            ]}
                        />
                    </Popover.Pane>
                </Popover>
            </div>
        </div>
    )
}

export default BulkActionRetryCrawl