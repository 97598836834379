import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Autocomplete, Banner, Modal, Select, Spinner} from '@shopify/polaris'
import carriers from '../static/carriers'

class InsertTrackingModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tracking_code: '',
            carrier: '',
            loadingAutoCPL: false,
            listCarriers: [],
            errorMess: '',
            error: false,
            loading: false,
        }
    }

    componentDidMount() {
        this.setState({
            tracking_code: '',
            carrier: '',
            loadingAutoCPL: false,
            listCarriers: [],
            errorMess: '',
            error: false,
        })
    }

    clickSave = async () => {
        try {
            const {tracking_code, carrier} = this.state
            // eslint-disable-next-line
            const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
            const isSpec = format.test(tracking_code)

            if (!isSpec) {
                this.setState({loading: true})
                const {success, message} = await this.props.save({tracking_code, carrier})
                if (!success) {
                    return this.setState({
                        error: true,
                        errorMess: message,
                        loading: false,
                        tracking_code: '',
                        carrier: '',
                    })
                }
                return this.setState({
                    tracking_code: '',
                    carrier: '',
                    loadingAutoCPL: false,
                    listCarriers: [],
                    loading: false,
                    error: false,
                }, () => this.close())
            }

            return this.setState({
                error: isSpec,
                errorMess: isSpec ? 'Your tracking number cant include special characters' : '',
            })

        } catch (error) {
            this.setState({
                validation: true,
                error: true,
                message: error.message,
                loading: false,
                tracking_code: '',
                carrier: '',
            })
        }
    }

    onChange = (key, value) => {
        this.setState({[key]: value, validation: false})
    }

    close = () => {
        this.setState({error: false, loading: false, isSuccess: false, errorMess: ''}, () => this.props.toggle())
    }

    render() {
        const {open} = this.props
        const {tracking_code, carrier, loadingAutoCPL, listCarriers, error, errorMess, loading} = this.state
        const optionsAutoCPL = listCarriers.map(_carrier => ({label: _carrier, value: _carrier}))
        const carrierOptions = [{
            label: 'Select a carrier',
            value: '',
        }, ...carriers]

        return (
            <Modal
                open={open}
                onClose={this.close}
                title="Add new Tracking Number"
                primaryAction= { (!error && !loading) && {
                    content: 'Add new Tracking Number',
                    onAction: this.clickSave,
                    disabled: !(tracking_code && carrier),
                }}
                secondaryActions={[
                    {
                        content: 'Close',
                        onAction: this.close,
                    },
                ]}
            >

                {(!loading && !error) && (<Modal.Section>
                    <Autocomplete
                        options={optionsAutoCPL}
                        selected={((carrier !== '') && [{value: carrier, label: carrier}]) || []}
                        onSelect={value => {
                            this.onChange('carrier', (value.length && value[0]) || '')
                        }}
                        loading={loadingAutoCPL}
                        textField={
                            <Autocomplete.TextField
                                onChange={value => this.onChange('tracking_code', value)}
                                label="Tracking Number"
                                value={tracking_code}
                            />
                        }
                    />
                    <Select
                        label="Carrier"
                        options={carrierOptions}
                        onChange={value => this.onChange('carrier', value)}
                        value={carrier}
                    />
                </Modal.Section>)}
                {(loading && !error) && <Spinner
                    accessibilityLabel="Pending..."
                    hasFocusableParent={true}
                />
                }
                {error && (<Banner
                    title='Warning!'
                    status="warning"
                >
                    {errorMess}
                </Banner>)}
            </Modal>
        )
    }
}

InsertTrackingModal.propTypes = {
    open: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    error: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
}


export default InsertTrackingModal
