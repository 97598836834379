import React, { Component } from 'react'
import {
    getListProxy,
    syncListProxyFromPartner,
    toggleStatusProxy
} from "../../../../services/api/ManageProxyServices"
import { Button, message, Pagination, Popconfirm, Select, Spin, Switch, Input } from "antd"
import humanizeTime from "../../../../helpers/humanizeTime"

class ListProxy extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            error: '',
            proxies: [],
            page: 1,
            limit: 50,
            active: '',
            total: 0,
            loadingSync: false,
            loadingToggle: false,
            ip: '',
            location: '',
        }
        this._timeOut = null
    }

    componentDidMount() {
        this._fetchListProxy()
    }

    _fetchListProxy = async () => {
        this.setState({
            loading: true,
            error: '',
            // proxies: []
        })

        try {
            const { page, limit, active, ip, location } = this.state
            const payload = {
                page: page,
                limit: limit,
            }

            if (active) payload.active = active
            if (ip) payload.ip = ip
            if (location) payload.location = location

            const response = await getListProxy(payload)

            const { success, data, message } = response

            if (!success) {
                return this.setState({
                    loading: false,
                    error: message
                })
            }

            this.setState({
                loading: false,
                proxies: data.data,
                total: data.total
            })
        } catch (e) {
            this.setState({
                loading: false,
                error: e.message
            })
        }
    }

    _updateSync = async () => {
        this.setState({
            loadingSync: true
        })

        try {
            const response = await syncListProxyFromPartner()

            const { success } = response

            if (!success) {
                return this.setState({
                    loadingSync: false
                })
            }

            this.setState({
                loadingSync: false
            }, () => {
                message.success('Sync list proxy success.')
            })
        } catch (e) {
            this.setState({
                loadingSync: false
            })
        }
    }

    _updateStatusProxy = async (checked, id) => {
        this.setState({
            loadingToggle: true
        })

        try {
            const payload = {
                "active": checked,
            }

            const response = await toggleStatusProxy(id, payload)

            const { success } = response

            if (!success) {
                return this.setState({
                    loadingToggle: false
                })
            }

            this.setState({
                loadingToggle: false
            }, () => {
                message.success('Change active proxy success.')
                this._fetchListProxy()
            })
        } catch (e) {
            this.setState({
                loadingToggle: false
            })
        }
    }

    _handleChangeActive = (value) => {
        this.setState({
            active: value,
            page: 1
        }, () => {
            this._fetchListProxy()
        })
    }

    _handleChangePagination = (page, pageSize) => {
        this.setState({
            limit: pageSize,
            page: pageSize !== this.state.limit ? 1 : page
        }, () => {
            this._fetchListProxy()
        })
    }

    _handleChangeInput = (name, value) => {
        const valueChange = { page: 1 }
        if (name === 'ip') valueChange.ip = value
        if (name === 'location') valueChange.location = value

        if (Object.keys(valueChange).length > 1) this.setState(valueChange, () => {
            if (this._timeOut) clearTimeout(this._timeOut)

            this._timeOut = setTimeout(() => {
                this._fetchListProxy()
            }, 500)
        })
    }

    render() {
        const { loading, loadingToggle, error, proxies, active, total, limit, page, ip, location } = this.state

        return (
            <div className="ListProxy">
                <Spin spinning={loading} tip="Getting list proxy...">
                    <div className="ContentList">
                        <div className="TopContent">
                            <div className="Totals">Show
                                all <span>{!!proxies && proxies.length}</span> of <span>{!!total && total}</span> items
                            </div>

                            <div className="ListFilter">
                                <div className="Filters">
                                    <label htmlFor="active">Ip</label>
                                    <Input
                                        value={ip || ''}
                                        onChange={(e) => this._handleChangeInput('ip', e.target.value)}
                                        placeholder='Search Ip'
                                        allowClear
                                    />
                                </div>
                                <div className="Filters">
                                    <label htmlFor="active">Location</label>
                                    <Input
                                        value={location || ''}
                                        onChange={(e) => this._handleChangeInput('location', e.target.value)}
                                        placeholder='Search Location'
                                        allowClear
                                    />
                                </div>
                                <div className="Filters">
                                    <label htmlFor="active">Active</label>
                                    <Select value={active} onChange={this._handleChangeActive}>
                                        <Select.Option value=''>All</Select.Option>
                                        <Select.Option value='true'>Active</Select.Option>
                                        <Select.Option value='false'>Deactivate</Select.Option>
                                    </Select>
                                </div>
                                <Popconfirm
                                    title="Are you sure to sync new list proxy?"
                                    onConfirm={() => this._updateSync()}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button type="primary">
                                        Sync proxy
                                    </Button>
                                </Popconfirm>
                            </div>
                        </div>


                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Active</th>
                                    <th>IP</th>
                                    <th>Location</th>
                                    <th>Created at</th>
                                    <th>Updated at</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    !!proxies && proxies.length > 0 && proxies.map((item, index) => (
                                        <tr key={item._id}>
                                            <td>{item._id}</td>
                                            <td className={`Status ${item.active ? 'Active' : 'Deactivate'}`}>
                                                {item.active ? 'Active' : 'Deactivate'}
                                            </td>
                                            <td>{item.ip}</td>
                                            <td className="Country">{item.location}</td>
                                            <td>{item.created_at ? humanizeTime(item.created_at) : ''}</td>
                                            <td>{item.updated_at ? humanizeTime(item.updated_at) : ''}</td>
                                            <td>
                                                <Switch
                                                    loading={loadingToggle}
                                                    checkedChildren="On"
                                                    unCheckedChildren="Off"
                                                    defaultChecked={item.active}
                                                    onChange={(checked) => this._updateStatusProxy(checked, item._id)}
                                                />
                                            </td>
                                        </tr>
                                    ))
                                }

                                {
                                    !!error && error.length > 0 && <tr>
                                        <td colSpan={7}>{error}</td>
                                    </tr>
                                }

                                {
                                    !!proxies && proxies.length === 0 && <tr>
                                        <td colSpan={7}>No record</td>
                                    </tr>
                                }
                            </tbody>
                        </table>

                        <Pagination
                            current={page}
                            total={total}
                            pageSize={limit}
                            onChange={this._handleChangePagination}
                            className='text-right'
                            showSizeChanger
                            pageSizeOptions={['10', '20', '50', '100']}
                        />
                    </div>
                </Spin>
            </div>
        )
    }
}

export default ListProxy
