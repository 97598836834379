import React, {Component} from 'react'
import {GlobeMajorMonotone} from '@shopify/polaris-icons'
import {Button} from '@shopify/polaris'

class TrackingDetails extends Component {

    render() {
        const {details, url} = this.props

        return (
            <div>
                <div className='mb-3'>
                    <Button icon={GlobeMajorMonotone}>
                        <a className='website'
                           href={url}
                           rel="noopener noreferrer"
                           target='_blank'>
                            Get Public Website
                        </a>
                    </Button>
                </div>

                {details.map(detail => (
                    <div>
                        <span className='font-bold'>{detail.date} </span> {' : ' + detail.message}
                    </div>
                ))}
            </div>
        )
    }
}

export default TrackingDetails