
import React from 'react'
import {Toast} from '@shopify/polaris'


class ToastComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            active: !!this.props.error
        }
    }


    toggleActive = () => {
        this.setState({active: !this.state.active})
    }

    render() {
        const toastMarkup = this.state.active ? (
            <Toast content={this.props.message} error={this.props.error} onDismiss={this.toggleActive} />
        ) : null;


        return toastMarkup;
    }
}

export default ToastComponent