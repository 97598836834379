import React, {Component} from 'react'
import {getListWebhook} from '../../../services/api/WebhookServices'
import ListWebhook from './ListWebhook'
// import moment from 'moment'
import {Card, Page} from '@shopify/polaris'
import FilterWebhook from './FilterWebhook'
import Pagination from '../../shared/Pagination'
import InsertWebhook from './InsertWebhook'

class ManageWebhooks extends Component {
    timeoutLoading = null

    state = {
        query: {
            name: '',
            url: '',
            status: '',
            end_date: '',
            start_date: '',
        },
        paging: {
            limit: 100,
            page: 1,
        },
        fetching: {
            loading: false,
            err: null,
        },
        webhooks: {
            data: [],
            total: 0,
        },
    }

    componentDidMount() {
        this._fetchListWebhooks()
    }

    _insertWebhook = item => {
        const {webhooks} = this.state
        const {data, total} = webhooks

        return this.setState({
            webhooks: {
                data: [item, ...data],
                total: total + 1,
            },
        })
    }

    _updateWebhook = (index, item) => {
        const {webhooks} = this.state
        const {data, total} = webhooks

        return this.setState({
            webhooks: {
                data: data.map((e, i) => {
                    return i === index ? item : e
                }),
                total: total,
            },
        })
    }

    _setQuery = (filter, delayLoading = false) => this.setState(({query, paging}) => ({
        query: {
            ...query, ...filter,
        },
        paging: {...paging, page: 1},
    }), () => {
        if (!delayLoading) return this._fetchListWebhooks()

        if (this.timeoutLoading) clearTimeout(this.timeoutLoading)
        this.timeoutLoading = setTimeout(async () => await this._fetchListWebhooks(), 1000)
    })

    _setFetching = (loading, err = null) => {
        const {webhooks} = this.state
        this.setState({
            fetching: {loading, err},
            webhooks: {
                ...webhooks,
                ...loading ? {data: []} : {},
            },
        })
    }

    _submitQuery = () => {
        const {paging} = this.state
        this.setState({paging: {...paging, page: 1}}, () => this._fetchListWebhooks())
    }

    _switchPage = page => () => {
        const {paging} = this.state

        this.setState({
            paging: {...paging, page},
        }, () => this._fetchListWebhooks())
    }

    _fetchListWebhooks = async () => {
        const {query, paging, fetching} = this.state
        if (fetching.loading) return

        this._setFetching(true)

        try {
            const {success, data: resp, message} = await getListWebhook({...query, ...paging})

            if (!success) throw new Error(message)

            this._setFetching(false, message)
            const {total, data} = resp
            this.setState({webhooks: {data, total}})
        } catch (e) {
            this._setFetching(false, e.message)
            alert(e.message)
        }
    }

    render() {
        const {webhooks, query, fetching, paging} = this.state
        const {total, data} = webhooks
        const {loading} = fetching
        const totalPage = Math.ceil(total / paging.limit)
        const label = (totalPage > 0 && paging.page + '/' + totalPage) || null

        return (
            <Page fullWidth title="Manage Webhooks">
                <div className="ManageWebhooks">
                    <div className="Toolbar">
                        <InsertWebhook insert={this._insertWebhook}/>
                    </div>
                    <Card sectioned>
                        <Card.Subsection>
                            <FilterWebhook query={query} setQuery={this._setQuery} loading={loading}/>
                        </Card.Subsection>
                        <Card.Subsection>
                            <ListWebhook
                                total={total} data={data} loading={loading}
                                filter={query} setFilter={this._setQuery}
                                submitFilter={this._submitQuery} paging={paging}
                                update={this._updateWebhook}/>
                        </Card.Subsection>
                        <Card.Subsection>
                            <Pagination label={label} showQuickJumper onSwitchPage={this._switchPage}
                                        amount={totalPage} current={paging.page}/>
                        </Card.Subsection>
                    </Card>
                </div>
            </Page>
        )
    }
}

export default ManageWebhooks
