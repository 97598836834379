import React, { useState } from 'react'
import { Select, Modal, Input } from 'antd'
import tracking_status from "../static/statuses"
import carriers from "../static/carriers"
import { FormFeedback } from 'reactstrap'
import { CirclePlusMajorMonotone } from '@shopify/polaris-icons'
import { Button } from '@shopify/polaris'
import PropTypes from 'prop-types'


const TrackingMappingCreate = (props) => {

    const { handleClickAdd } = props
    const [notes, setNotes] = useState([])
    const [carrier, setCarrier] = useState('')
    const [visible, setVisible] = useState(false)
    const [errors, setErrors] = useState({})
    const [trackingStatus, setTrackingStatus] = useState('')

    const _changeNote = value => {
        setNotes([value])
        delete errors.notes
    }

    const _handleChangeCarrier = carrier => {
        setCarrier(carrier)
        delete errors.carrier
    }

    const _handleChangeStatuses = status => {
        setTrackingStatus(status)
        delete errors.trackingStatus
    }

    const showModal = () => {
        setVisible(true)
    }

    const hideModal = () => {
        setVisible(false)
        setNotes([])
        setTrackingStatus('')
        setCarrier('')
        delete errors.notes
        delete errors.carrier
        delete errors.trackingStatus
    }

    const _validate = () => {
        let err = {}

        if (notes.length === 0) err.notes = 'Original is required'
        if (!carrier) err.carrier = 'Carrier is required'
        if (!trackingStatus) err.trackingStatus = 'Tracking status is required'

        setErrors(err)

        if (Object.keys(err).length >= 1) return
        const notesArr = notes.map(item => item.split('\n'))
        const notesFiter = notesArr.filter(item => item !== '')
        handleClickAdd(notesFiter[0], trackingStatus, carrier)
        setVisible(false)
        setNotes([])
        setTrackingStatus('')
        setCarrier('')
    }


    return (
        <React.Fragment>
            <div className="mb-3">
                <Button primary onClick={showModal} icon={CirclePlusMajorMonotone}>
                    Add new
                </Button>
            </div>
            <Modal
                title="Create mapping"
                visible={visible}
                onOk={_validate}
                onCancel={hideModal}
                okText="Add"
                cancelText="Cancel"
            >
                <div className="TrackingMappingCreate">
                    <div className="CreateContent">
                        <div className="CreateChild">
                            <label>Carriers</label>
                            <Select
                                value={carrier || []}
                                onChange={_handleChangeCarrier}
                                placeholder="Select carriers"
                                style={{ width: '100%' }}
                                allowClear
                            >
                                {
                                    carriers.length > 0 && carriers.map((item, index) => (
                                        <Select.Option key={index} value={item.value}>{item.label}</Select.Option>
                                    ))
                                }
                            </Select>
                            {errors.carrier && <FormFeedback>{errors.carrier}</FormFeedback>}
                        </div>
                        <div className="CreateChild">
                            <label>Tracking satus</label>
                            <Select
                                value={trackingStatus || []}
                                onChange={_handleChangeStatuses}
                                placeholder="Select Tracking Status"
                                style={{ width: '100%' }}
                                allowClear
                            >
                                {
                                    tracking_status.length > 0 && tracking_status.map((item, index) => (
                                        <Select.Option key={index} value={item.value}>{item.label}</Select.Option>
                                    ))
                                }
                            </Select>
                            {errors.trackingStatus && <FormFeedback>{errors.trackingStatus}</FormFeedback>}
                        </div>
                        <div className="CreateChild">
                            <label htmlFor="Carrier" className="">Original</label>
                            <Input.TextArea
                                rows={3}
                                placeholder="Tags notes"
                                value={notes}
                                className="OriginalNote"
                                onChange={(e) => _changeNote(e.target.value)}
                            />
                            {errors.notes && <FormFeedback>{errors.notes}</FormFeedback>}
                        </div>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    )
}

TrackingMappingCreate.propTypes = {
    handleClickAdd: PropTypes.func.isRequired,
}

export default TrackingMappingCreate