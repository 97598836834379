import React, {Component} from 'react'
import {Button} from '@shopify/polaris'
import {RefreshMajorMonotone} from '@shopify/polaris-icons'
import Toast from '../../dashboard/toast'

class ForceUpdateButton extends Component {
    state = {loading: false, message: ''}


    _forceUpdate = async () => {
        const {forceUpdate} = this.props
        this.setState({loading: true})
        try {
            await forceUpdate()
            this.setState({loading: false})
        } catch (e) {
            this.setState({loading: false, message: e.message})
        }
    }

    render() {

        return (
            <div className="ForceUpdateButton ml-4">
                {this.state.message && (<Toast message={this.state.message} error={true}/>)}
                <Button destructive onClick={this._forceUpdate} loading={this.state.loading}
                        icon={RefreshMajorMonotone}>
                    Force update
                </Button>
            </div>
        )
    }
}

export default ForceUpdateButton
