import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Modal, TextField, TextStyle, InlineError, DisplayText} from '@shopify/polaris'
import safeTrim from '../../../helpers/safeTrim'

const _parse = ({name}) => {
    const apikey = {}
    if (safeTrim(name) !== '') apikey.name = name

    return apikey
}

class GenerateKeyModal extends Component {
    state = {
        name: '',
        apikey: null,
        message: {
            error: '',
            success: '',
        },
        copy: false,
    }

    _copyToClipboard = () => {
        this.textArea.select()
        document.execCommand('copy')

        this.setState({
            copy: true
        }, () => setTimeout(() => this.setState({copy: false}), 1500))
    }

    _clickGenerate = async () => {
        const {name} = this.state
        try {
            const {data} = await this.props.save(_parse({name}))
            const key = `${data.prefix}.${data.key}`
            this.setState({
                name: '',
                apikey: key,
                copy: false,
                message: {
                    error: '',
                    success: 'Please store it somewhere safe because as soon as you navigate away from this page, we will not be able to retrieve or restore this generated token.'
                }
            })

        } catch (e) {
            this.setState({message: {error: e.message || 'Generate error!', success: ''}})
        }
    }

    _onChange = (key, value) => this.setState({[key]: value})

    _canAdd = ({name}) => {
        return name
    }

    _toggle = () => {
        const {toggle} = this.props
        const {apikey} = this.state

        if (apikey) {
            const sure = window.confirm('Are you sure?')

            if (sure) {
                this.setState({
                    name: '',
                    apikey: '',
                    copy: false,
                    message: {
                        error: '',
                        success: '',
                    }
                })

                toggle()
            }
        } else {
            toggle()
        }
    }

    render() {
        const {open, loading} = this.props
        const {name, message, apikey, copy} = this.state

        const canAdd = this._canAdd({name}) && !loading

        const primaryAction = apikey ? {
            content: copy ? 'Copied!' : 'Copy',
            onAction: this._copyToClipboard,
            disabled: !document.queryCommandSupported('copy') || copy,
        } : {
            content: 'Generate',
            onAction: this._clickGenerate,
            disabled: !canAdd,
        }

        return (
            <Modal
                open={open}
                onClose={this._toggle}
                title="Generate Your API-Key"
                primaryAction={primaryAction}
                secondaryActions={[
                    {
                        content: 'Close',
                        onAction: this._toggle,
                        disabled: loading,
                    },
                ]}
            >
                <Modal.Section>
                    {!apikey && <TextField
                        value={name}
                        onChange={value => this._onChange('name', value)}
                        label="Name"
                    />}
                    <InlineError message={message.error}/>
                    <DisplayText size="small">
                        <TextStyle variation='strong'>
                            <TextStyle variation='positive'>{message.success}</TextStyle>
                        </TextStyle>
                    </DisplayText>
                    {apikey && <TextField
                        value={apikey}
                    />}
                    <textarea
                        style={{opacity: 0}}
                        onChange={() => {
                        }}
                        ref={(textarea) => this.textArea = textarea}
                        value={apikey || ''}
                    />
                </Modal.Section>
            </Modal>
        )
    }
}

GenerateKeyModal.propTypes = {
    open: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
}


export default GenerateKeyModal
