import React, { Component } from 'react'
import { Layout, Page } from '@shopify/polaris'
import UserDetail from './UserDetail'
import UserSecurity from './UserSecurity'
import UserElimination from './UserElimination'
import { withRouter } from 'react-router-dom'
import { getUser } from '../../../services/api/UserServices'

class ViewUser extends Component {
    state = {
        loading: true,
        user: null,
        _id: '',
    }

    _setState = args => this.setState(args)

    fetchUser = async userId => {
        const { success, message, data } = await getUser(userId)

        if (!success) throw new Error(message)

        this.setState({
            user: data,
        })

        return data
    }

    _initView = async () => {
        const { location: { state }, history } = this.props
        if (!state || !state.user || !state.user._id) {
            history.push('/d/manage-users')

            return
        }

        this.setState({ loading: false, user: state.user, _id: state.user._id })

        // try {
        //     const user = await this.fetchUser(state.user._id)
        //     this.setState({loading: false, _id: state.user._id})
        // } catch (e) {
        //     alert(e.message)
        //     history.push('/d/manage-users')
        // }
    }

    componentDidMount() {
        this._initView()
    }

    render() {
        const {/*match, location,*/ history } = this.props
        const { loading, user, _id } = this.state
        // const {state} = location

        return (
            <Page separator breadcrumbs={[{ content: 'List Users', onAction: () => history.push('/d/manage-users') }]}>
                <div className="Profile">
                    {
                        !loading && (
                            <Layout>
                                <Layout.Section>
                                    <UserDetail
                                        user={user}
                                        _id={_id}
                                        fetchUser={this.fetchUser}
                                        setViewUserState={this._setState}
                                    />
                                    <UserSecurity
                                        user={user}
                                        _id={_id}
                                        fetchUser={this.fetchUser}
                                        setViewUserState={this._setState}
                                    />
                                    <UserElimination
                                        user={user}
                                        _id={_id}
                                        fetchUser={this.fetchUser}
                                        setViewUserState={this._setState}
                                        history={history}
                                    />
                                </Layout.Section>
                            </Layout>
                        )
                    }
                </div>
            </Page>
        )
    }
}

export default withRouter(ViewUser)
