import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Select, Input, Checkbox, message } from "antd"
import { Button, Tooltip } from '@shopify/polaris'
import humanizeTime from "../../../helpers/humanizeTime"
import { FormFeedback } from 'reactstrap'
import carriers from "../static/carriers"
import tracking_status from "../static/statuses"
import { EditMajorMonotone, MobileCancelMajorMonotone } from '@shopify/polaris-icons'
import { editMappingV2 } from '../../../services/api/TrackingMappingServices'


const TrackingMappingTable = props => {
    const { index, item, handleClickDelete, totalCheck, onCheck, refreshTrackingMapping} = props
    const [newOriginal, setNewOriginal] = useState(item.original)
    const [newTrackingStatus, setNewTrackingStatus] = useState(item.status_mapping)
    const [newCarrier, setNewCarrier] = useState(item.carrier)
    const [visible, setVisible] = useState(false)
    const [errors, setErrors] = useState({})
    const [newTrackingOrigin, setNewTrackingOrigin] = useState(item.status)
    const [newLocation, setNewLocation] = useState(item.location)
    const [newType, setNewType] = useState(item.type)

    const _handleChangeNewOriginal = (e) => {
        setNewOriginal(e.target.value)
        delete errors.notes
    }

    const _handleChangeCarrier = carrier => {
        setNewCarrier(carrier)
        delete errors.carrier
    }

    const _handleChangeStatuses = status => {
        setNewTrackingStatus(status)
        delete errors.trackingStatus
    }

    const _handleChangeLocation = location => {
        setNewLocation(location)
        delete errors.location
    }

    const _handleChangeType = type => {
        setNewType(type)
        delete errors.type
    }

    const showModal = () => {
        setVisible(true)
    }

    const hideModal = () => {
        setVisible(false)
        setNewTrackingStatus(item.status_mapping)
        setNewCarrier(item.carrier)
        setNewTrackingOrigin(item.status)
        setNewLocation(item.location)
        setNewType(item.type)
        delete errors.type
        delete errors.location
        delete errors.trackingStatus
    }

    const _handleEditMapp = async (id, status_mapping, type, location) => {
        const payload = {
            status_mapping,
            type,
            location
        }
        try {
            const response = await editMappingV2(id, payload)
            const { message: mess, success } = response
            if (!success) return message.error(`${mess}!`)
            message.success('Edit successfully!')
            setVisible(false)
            refreshTrackingMapping()
        } catch (e) {
            message.error(e.message || 'Unknown error.')
        }
    }

    const _validate = () => {
        let err = {}

        if (!newTrackingStatus) err.trackingStatus = 'Tracking status is required'
        if (!newLocation) err.location = 'Location is required'
        if (!newType) err.type = 'Type is required'

        setErrors(err)

        if (Object.keys(err).length >= 1) return
        
        _handleEditMapp(item._id, newTrackingStatus, newType, newLocation)
    }

    const _mapTrackingStatus = status => {
        let lableStatus = '';
        switch(status) {
            case 'unknown':
                lableStatus = 'Unknown'
                break;

            case 'pre_transit':
                lableStatus = 'Pre transit'
                break;

            case 'in_transit':
                lableStatus = 'In transit'
                break;

            case 'out_for_delivery':
                lableStatus = 'Out for delivery'
                break;

            case 'delivered':
                lableStatus = 'Delivered'
                break;

            case 'exception':
                lableStatus = 'Exception'
                break;

            case 'mapped':
                lableStatus = 'Mapped'
                break;
            default:
                lableStatus = '--'
        }

        return lableStatus
    }

    return (
        <React.Fragment>
            <tr key={index}>
                {/* <td><Checkbox data={item} checked={totalCheck.includes(item)} onChange={onCheck} /></td> */}
                <td>{item.status}</td>
                <td>{_mapTrackingStatus(item.status_mapping)}</td>
                <td>{item.carrier}</td>
                <td>{item.location}</td>
                <td>{item.type}</td>
                <td>{item.created_at ? humanizeTime(item.created_at) : ''}</td>
                <td>{item.updated_at ? humanizeTime(item.updated_at) : ''}</td>
                <td>
                    <div className="acions">
                        <Tooltip content="Edit" preferredPosition="above">
                            <Button icon={EditMajorMonotone} onClick={showModal} />
                        </Tooltip>
                        <Tooltip content="Delete" preferredPosition="above">
                            <Button id="DeleteButton" icon={MobileCancelMajorMonotone} onClick={(e) => handleClickDelete(e, item._id)} />
                        </Tooltip>
                    </div>
                </td>
            </tr>
            <Modal
                title="Edit mapping"
                visible={visible}
                onOk={_validate}
                onCancel={hideModal}
                okText="Edit"
                cancelText="Cancel"
            >
                <div className="TrackingMappingCreate">
                    <div className="CreateContent">
                        <div className="CreateChild">
                            <label>Carriers</label>
                            <Select
                                value={newCarrier || []}
                                onChange={_handleChangeCarrier}
                                placeholder="Select carriers"
                                style={{ width: '100%' }}
                                allowClear
                                disabled
                            >
                                {
                                    carriers.length > 0 && carriers.map((item, index) => (
                                        <Select.Option key={index} value={item.value}>{item.label}</Select.Option>
                                    ))
                                }
                            </Select>
                            {/* {errors.carrier && <FormFeedback>{errors.carrier}</FormFeedback>} */}
                        </div>
                        <div className="CreateChild">
                            <label htmlFor="Carrier" className="">Tracking Origin</label>
                            <Input
                                rows={3}
                                placeholder="Tracking origin"
                                value={newTrackingOrigin}
                                className="OriginalNote"
                                disabled
                            />
                            {/* {errors.trackingOrigin && <FormFeedback>{errors.trackingOrigin}</FormFeedback>} */}
                        </div>
                        <div className="CreateChild">
                            <label>Tracking status</label>
                            <Select
                                value={newTrackingStatus || []}
                                onChange={_handleChangeStatuses}
                                placeholder="Select Tracking Status"
                                style={{ width: '100%' }}
                                allowClear
                            >
                                {
                                    tracking_status.length > 0 && tracking_status.map((item, index) => (
                                        <Select.Option key={index} value={item.value}>{item.label}</Select.Option>
                                    ))
                                }
                            </Select>
                            {errors.trackingStatus && <FormFeedback>{errors.trackingStatus}</FormFeedback>}
                        </div>
                        <div className="CreateChild">
                            <label>Location</label>
                            <Select
                                value={newLocation || []}
                                onChange={_handleChangeLocation}
                                placeholder="Select location"
                                style={{ width: '100%' }}
                                allowClear
                            >
                                <Select.Option value='vn'>vn</Select.Option>
                                <Select.Option value='default'>default</Select.Option>
                            </Select>
                            {errors.location && <FormFeedback>{errors.location}</FormFeedback>}
                        </div>
                        <div className="CreateChild">
                            <label>Type</label>
                            <Select
                                value={newType || []}
                                onChange={_handleChangeType}
                                placeholder="Select type"
                                style={{ width: '100%' }}
                                allowClear
                            >
                                <Select.Option value='normal'>normal</Select.Option>
                                <Select.Option value='regex'>regex</Select.Option>
                            </Select>
                            {errors.type && <FormFeedback>{errors.type}</FormFeedback>}
                        </div>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    )
}

TrackingMappingTable.propTypes = {
    index: PropTypes.number.isRequired,
    item: PropTypes.object.isRequired,
    handleClickDelete: PropTypes.func.isRequired

}

export default TrackingMappingTable