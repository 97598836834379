import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Redirect, Route, Switch} from "react-router-dom"
import ManageProxyPage from "./ManageProxyPage"

class ManageProxyContainer extends Component {
    render() {
        const {basePath} = this.props

        return (
            <Switch>
                <Route exact path={`${basePath}/manage-proxy`} render={() => <ManageProxyPage {...this.props}/>}/>
                <Route path={'*'}>
                    <Redirect to={`${basePath}/manage-proxy`}/>
                </Route>
            </Switch>
        )
    }
}

ManageProxyContainer.propTypes = {
    basePath: PropTypes.string
}

export default ManageProxyContainer
