const polarisTheme = {
    colors: {
        topBar: {
            background: '#262D37',
        },
    },
    colorScheme: 'dark',
    logo: {
        width: 40,
        height: 40,
        topBarSource:
            'https://d1vkijg56t0qe5.cloudfront.net/yhnh3wqo/19/07/settings/1563416645930_logo-merchize.png',
        url: '/',
        accessibilityLabel: 'Tracking',
    },
}

export default polarisTheme
