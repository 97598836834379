import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import statuses from '../static/statuses'
import {Input, Select, DatePicker} from 'antd'

const TableFilter = (props) => {
    const {filter, setFilter} = props

    const _changeFiltersDate = (name, value) => {
        setFilter({
            ...filter,
            [name]: !!value ? moment(value).format('YYYY-MM-DD') : null,
            page: 1
        })
    }

    const _onSearch = query => {
        setFilter({
            ...filter,
            tracking_code: query,
            page: 1
        })
    }

    const _handleChangeStatuses = status => {
        setFilter({
            ...filter,
            status: status,
            page: 1
        })
    }

    return (
        <div className="TrackingFilter">
            <div className="TrackingSearch">
                <Input.Search
                    placeholder="Find by Tracking Number"
                    onChange={(e) =>
                        _onSearch(e.target.value)
                    }
                    value={filter.tracking_code}
                    style={{width: '100%'}}
                    allowClear
                />
            </div>
            <div className="ListFilter">
                <div className="DateControl Filters pl-3">
                    <label className="mr-2 mr-3">Created date </label>
                    <DatePicker
                        format={'YYYY-MM-DD'}
                        placeholder="Select date from"
                        onChange={val => _changeFiltersDate('created_from', val)}
                    />
                </div>
                <div className="DateControl Filters pl-3 ml-2">
                    <label className="mr-2 mr-3">- </label>
                    <DatePicker
                        format={'YYYY-MM-DD'}
                        placeholder="Select date to"
                        onChange={val => _changeFiltersDate('created_to', val)}
                    />
                </div>
                <div className="TrackingStatuses">
                    <div className="Filters">
                        <label htmlFor="status">
                            Status
                        </label>
                        <Select
                            value={filter.status || ''}
                            onChange={_handleChangeStatuses}
                            defaultValue={filter.status || ''}
                        >
                            {statuses.length > 0 &&
                                statuses.map(
                                    (item, index) => (
                                        <Select.Option
                                            key={index}
                                            value={
                                                item.value
                                            }
                                        >
                                            {item.label}
                                        </Select.Option>
                                    )
                                )}
                        </Select>
                    </div>
                </div>
            </div>
        </div>
    )
}

TableFilter.propTypes = {
    filter: PropTypes.object.isRequired,
    setFilter: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
}

export default TableFilter
