import React, {Component} from 'react'
import Security from './Security'
import {Page} from '@shopify/polaris'

class SecurityContainer extends Component {

    render() {

        return (
            <Page narrowWidth title="Security">
                <Security {...this.props}/>
            </Page>
        )
    }
}

export default SecurityContainer
