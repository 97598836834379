import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Button} from '@shopify/polaris'
import InsertTrackingModal from './InsertTrackingModal'
import {CirclePlusMajorMonotone} from '@shopify/polaris-icons'

class InsertTrackingNumber extends Component {
    state = {
        toggle: false,
        error: false,
        message: ''
    }

    _toggle = () => {
        this.setState({toggle: !this.state.toggle})
    }

    _save = async trackingNumber => {
        try {
            const {message, error} = await this.props.insert(trackingNumber)
            if (error) {
                return {success: false, message}
            }
            return {success :true}
        } catch (e) {
            return {success: false, message: e.message}
        }
    }

    render() {
        const {toggle, error, message} = this.state

        return (
            <div className="InsertTrackingNumber mr-4">
                <Button primary onClick={this._toggle} icon={CirclePlusMajorMonotone}>
                    Add new
                </Button>
                <InsertTrackingModal open={toggle} toggle={this._toggle} save={this._save} error={error} message={message} />
            </div>
        )
    }
}

InsertTrackingNumber.propTypes = {
    insert: PropTypes.func.isRequired,
}

export default InsertTrackingNumber
