import createAPIServices from './createApiServices'

const baseUrl = process.env.NODE_ENV === 'production' ? '/api/auth/statistics/v2' : 'https://staging-tracking-v2.merchize.com/api/auth/statistics/v2'

const api = createAPIServices({baseUrl})

export const getStatusStatistics = ({start_date, end_date}) => {
    return api.makeAuthRequest({
        url: '/status',
        method: 'get',
        params: {start_date, end_date},
    })
}

export const getTransitTimeStatistics = ({start_date, end_date}) => {
    return api.makeAuthRequest({
        url: '/transit-time',
        method: 'get',
        params: {start_date, end_date},
    })
}

export const getTrackerStatistics = ({type, createdFrom, createdTo}) => {
    return api.makeAuthRequest(({
        url: '/tracker-statistics',
        method: 'GET',
        params: {type, createdFrom, createdTo}
    }))
}

export const getCrawlerTracker = ({createdFrom, createdTo}) => {
    return api.makeAuthRequest(({
        url: '/crawler-tracker-statistics',
        method: 'GET',
        params: {createdFrom, createdTo}
    }))
}

export const getCrawlerTraffics = ({createdFrom, createdTo}) => {
    return api.makeAuthRequest(({
        url: '/crawler-traffics',
        method: 'GET',
        params: {createdFrom, createdTo}
    }))
}
