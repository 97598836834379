import React, { useState } from 'react'
import { Modal, Select, Input } from "antd"
import { Button, Tooltip } from '@shopify/polaris'
import { FormFeedback } from 'reactstrap'
import { EditMajorMonotone } from '@shopify/polaris-icons'

function TrackingSourceTable(props) {
    const { index, item, handleClickEdit} = props
    const [newName, setNewName] = useState(item.name || '')
    const [newCode, setNewCode] = useState(item.code || '')
    const [newAlias, setNewAlias] = useState(item.alias || [])
    const [searchAlias, setSearchAlias] = useState('')
    const [newSource, setNewSource] = useState(item.source ? item.source.split(',') : [])
    const [newDecription, setNewDecription] = useState(item.description || '')
    const [visible, setVisible] = useState(false)
    const [errors, setErrors] = useState({})

    const showModal = () => {
        setVisible(true)
    }

    const _handleChangeName = (e) => {
        setNewName(e.target.value)
        delete errors.name
    }
    const _handleChangeCode = (e) => {
        setNewCode(e.target.value)
        delete errors.code
    }

    const _handleChangeSource = source => {
        setNewSource(source)
        delete errors.source
    }

    const _handleChangeAlias = alias => {
        setNewAlias([...alias])
        setSearchAlias('')
        delete errors.alias
    }

    const handleSearch = (value) => {
        if (value && value.includes(',')) {
            const newValue = [...new Set([...value.replace(/ /g, ',').split(',').map(i => i.trim()).filter(i => i), ...newAlias])]
            setNewAlias(newValue)
            setSearchAlias('')
        } else return setSearchAlias(value)
    }

    const _handleChangeDecription = e => {
        setNewDecription(e.target.value)
        delete errors.description
    }


    const hideModal = () => {
        setVisible(false)
        setNewName(item.name)
        setNewCode(item.code)
        setNewAlias(item.alias)
        setNewSource(item.source ? item.source.split(',') : [])
        setNewDecription(item.description)
        delete errors.name
        delete errors.code
        delete errors.source
        delete errors.alias
        delete errors.description
    }

    const _validate = (id) => {
        let err = {}

        if (!newName) err.name = 'Name is required'
        if (!newCode) err.code = 'Code is required'
        if (!newAlias.length) err.alias = 'Alias is required'
        if (!newSource.length) err.source = 'Source is required'

        setErrors(err)

        if (Object.keys(err).length >= 1) return

        const old_data = {
            name: newName,
            code: item.code,
            alias: item.alias,
            source: item.source ? item.source.split(',') : [],
            description: item.description,
        }

        const new_data = {
            name: newName,
            code: newCode,
            alias: newAlias,
            source: newSource,
            description: newDecription,
        }
        handleClickEdit(id, new_data, false)
        setVisible(false)
    }

    return (
        <React.Fragment>
            <tr key={index}>
                <td>{item.name || ''}</td>
                <td>{item.code || ''}</td>
                <td>{!!item.alias && item.alias.length > 0 ? item.alias.join(', ') : ''}</td>
                <td>{item.source || ''}</td>
                <td>
                    <div className="acions">
                        <Tooltip content="Edit" preferredPosition="above">
                            <Button icon={EditMajorMonotone} onClick={showModal} />
                        </Tooltip>
                    </div>
                </td>
            </tr>
            <Modal
                title="Edit source"
                visible={visible}
                onOk={() => _validate(item._id)}
                onCancel={hideModal}
                okButtonProps={{disabled:!!Object.keys(errors).length}}
                okText="Save"
                cancelText="Cancel"
            >
                <div className="TrackingSourceCreate">
                    <div className="CreateContent">
                        <div className="CreateChild required">
                            <label>Name</label>
                            <Input
                                value={newName || []}
                                onChange={_handleChangeName}
                                placeholder="Input Name"
                                style={{ width: '100%' }}
                                allowClear
                            />
                            {errors.name && <FormFeedback>{errors.name}</FormFeedback>}
                        </div>
                        <div className="CreateChild required">
                            <label>Code</label>
                            <Input
                                value={newCode || []}
                                onChange={_handleChangeCode}
                                placeholder="Input Code"
                                style={{ width: '100%' }}
                                allowClear
                            />
                            {errors.code && <FormFeedback>{errors.code}</FormFeedback>}
                        </div>
                        <div className="CreateChild required">
                            <label>Alias</label>
                            <Select
                                mode={'tags'}
                                value={newAlias || []}
                                onChange={_handleChangeAlias}
                                placeholder="Input Alias"
                                style={{ width: '100%' }}
                                onSearch={handleSearch}
                                open={false}
                                virtual={false}
                                searchValue={searchAlias}
                                allowClear
                            />
                            {errors.alias && <FormFeedback>{errors.alias}</FormFeedback>}
                        </div>
                        <div className="CreateChild">
                            <label>Source</label>
                            <Select
                                value={newSource || []}
                                onChange={_handleChangeSource}
                                placeholder="Select Source"
                                style={{ width: '100%' }}
                                mode={'multiple'}
                            >
                                <Select.Option value="crawl">Crawl</Select.Option>
                                <Select.Option value="trackingmore">Tracking more</Select.Option>
                                <Select.Option value="multrans">Multrans</Select.Option>
                                {/* <Select.Option value="">Off</Select.Option> */}
                            </Select>
                            {errors.source && <FormFeedback>{errors.source}</FormFeedback>}
                        </div>
                        <div className="CreateChild">
                            <label>Decriptions</label>
                            <Input.TextArea
                                value={newDecription || ''}
                                onChange={_handleChangeDecription}
                                placeholder="Input Decription"
                                style={{ width: '100%' }}
                                allowClear
                            />
                            {errors.description && <FormFeedback>{errors.description}</FormFeedback>}
                        </div>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default TrackingSourceTable