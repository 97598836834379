import React, {useState} from 'react'
import {Input, message, Modal, Select} from 'antd'
import {FormFeedback} from 'reactstrap'
import {sendExampleWebhooks} from '../../../services/api/WebhookSettingsServices'
import {isValidUrl} from '../../../helpers/validateURL'

const WebhookSimulatorModal = (props) => {
    const { visible, setVisible, events } = props
    const eventDefault = events.length > 1 ? events[1].value : ''
    const [errors, setErrors] = useState({})
    const [fields, setFields] = useState({
        url: '',
        event: eventDefault,
    })

    const _changeField = (e) => {
        const {name, value} = e.target
        console.log(name, value)
        setFields({
            ...fields,
            [name]: value
        })
    }

    const _changeFieldEvent = (value) => {
        setFields({
            ...fields,
            event: value
        })
    }

    const hideModal = () => {
        setVisible(false)
    }

    const _validate = () => {
        let err = {}

        if (fields.url === '') err.url = 'Endpoint is required'
        if (!isValidUrl(fields.url)) err.url = 'Endpoint must be valid URL'
        if (fields.event === '') err.event = 'Event is required'

        setErrors(err)

        if (Object.keys(err).length >= 1) return

        handleSendTest(fields)
        setFields({
            url: '',
            event: eventDefault,
        })
        setVisible(false)
    }

    const handleSendTest = async (fields) => {
        try {
            const {success, message: error} = await sendExampleWebhooks(fields)

            if (!success) {
                return message.error(error)
            }

            message.success('Test notification has been sent!')
        } catch (e) {
            message.error(e.message)
        }
    }

    return (
        <Modal
            title="Webhook simulator"
            visible={visible}
            onOk={_validate}
            onCancel={hideModal}
            okText="Send test"
            cancelText="Cancel"
        >
            <div className="TrackingMappingCreate">
                <div className="CreateChild">
                    <label htmlFor="Carrier" className="">Endpoint:</label>
                    <Input name="url" defaultValue={!!fields ? fields.url : ''} onChange={_changeField}/>
                    {errors.url && <FormFeedback>{errors.url}</FormFeedback>}
                </div>
                <div className="CreateChild">
                    <label htmlFor="event" className="">Event:</label>
                    <Select
                        value={eventDefault}
                        name="event"
                        style={{width: "100%"}}
                        onChange={_changeFieldEvent}
                        defaultValue={eventDefault}
                    >
                        {events.length > 0 &&
                            events.map(
                                (item, index) => (
                                    <Select.Option
                                        key={index}
                                        value={
                                            item.value
                                        }
                                    >
                                        {item.label}
                                    </Select.Option>
                                )
                            )}
                    </Select>
                    {errors.event && <FormFeedback>{errors.event}</FormFeedback>}
                </div>
            </div>
        </Modal>
    )
}

export default WebhookSimulatorModal