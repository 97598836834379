import React, {Component} from 'react'
import {Layout} from '@shopify/polaris'
import ProfileDetails from './ProfileDetails'

class Profile extends Component {

    render() {

        return (
            <div className="Profile">
                <Layout>
                    <Layout.Section>
                        <ProfileDetails/>
                    </Layout.Section>
                </Layout>
            </div>
        )
    }
}

export default Profile
