import React, {Component} from 'react'
import TrackingNumbers from './TrackingNumbers'
import {Page} from '@shopify/polaris'

class TrackingNumbersContainer extends Component {
    render() {
        return (
            <Page fullWidth title="Tracking Numbers">
                <TrackingNumbers {...this.props}/>
            </Page>
        )
    }
}

export default TrackingNumbersContainer
