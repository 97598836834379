import React, { useState } from 'react'
import {ComposeMajorMonotone, TransactionMajorMonotone} from '@shopify/polaris-icons'
import { Button } from '@shopify/polaris'
import PayloadExampleModal from './PayloadExampleModal'

const PayloadExample = (props) => {
    const [visible, setVisible] = useState(false)

    const showModal = () => {
        setVisible(true)
    }

    return (
        <React.Fragment>
            <Button onClick={showModal} icon={TransactionMajorMonotone}>
                Payload example
            </Button>
            {visible && <PayloadExampleModal visible={visible} setVisible={setVisible} showModal={showModal} {...props}/>}
        </React.Fragment>
    )
}

export default PayloadExample