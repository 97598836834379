import React from 'react'
import {loginUser, logoutUser, isAuthenticated, setUserData, getUserData} from '../../../services/AuthServices'
import {auth, getMe} from '../../../services/api/UserServices'
import {subscribeGlobalErrorRequest, clearSubscribeGlobalErrorRequest} from '../../../services/api/createApiServices'

const defaultValue = {
    isAuthenticated: false,
    isLoading: true,
    userData: {},
}

const _login = async ({email, password}) => {
    try {
        const {success, data, message} = await auth({email, password})
        if (success) {
            const {token, payload, user_data: userData} = Object.assign({}, data)
            if (token) {
                loginUser({token, payload, userData})
                return true
            }
        }

        throw new Error(message || 'Login Error!')
    } catch (e) {
        throw e
    }
}

const _logout = () => {
    logoutUser()
}

const AuthContext = React.createContext(defaultValue)

class AuthContextProvider extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ...defaultValue,
        }
    }

    setNotAuth = () => {
        this.setState({isAuthenticated: false, userData: {}}, _logout)
    }

    fetchMe = () => {
        getMe().then(response => {
            const {success, data} = response
            if (success) {
                setUserData(data)
                this.setState({
                    isAuthenticated: true,
                    isLoading: false,
                    userData: data,
                })
            } else {
                this.logout()
            }
        }).catch(e => {
            // console.log("error AuthContext.js: 63")
            this.logout()
        })
    }

    componentDidMount() {
        subscribeGlobalErrorRequest(({status}) => {
            if (status === 403) {
                this.setNotAuth()

                return
            }
        })

        if (!isAuthenticated()) {
            this.logout()
            this.setState({
                isLoading: false,
            })
        } else {
            const userData = getUserData()
            if (userData) {
                this.setState({
                    isAuthenticated: true,
                    isLoading: false,
                    userData,
                })
            } else {
                this.fetchMe()
            }

        }
    }

    componentWillUnmount() {
        clearSubscribeGlobalErrorRequest()
    }

    login = async ({email, password}) => {
        try {
            const isAuth = await _login({email, password})

            if (isAuth) {
                this.setState({
                    isAuthenticated: true,
                })
            } else {
                throw new Error('Authenticate Error!')
            }
        } catch (e) {
            throw e
        }
    }

    logout = () => {
        this.setState((state) => {
            const {isAuthenticated} = state

            if (isAuthenticated) return {
                isAuthenticated: false,
            }

            return {}
        }, _logout)
    }

    render() {
        //console.log(this.state)
        return (
            <AuthContext.Provider value={{
                ...this.state,
                login: this.login,
                logout: this.logout,
            }}>
                {this.props.children}
            </AuthContext.Provider>
        )
    }
}

export {
    AuthContextProvider,
}

export default AuthContext
