import React from 'react'
import TrackingMapping from './TrackingMapping'
import { Page } from '@shopify/polaris'

const TrackingMappingContainer = props => {
    return (
        <Page fullWidth title="Tracking Mapping">
            <TrackingMapping {...props} />
        </Page>
    )
}

export default TrackingMappingContainer
