import React, {Component} from 'react'
import {Card, Page} from "@shopify/polaris"
import ListProxy from "./list-proxy/ListProxy"
import BandWidth from "./band-width/BandWidth"

class ManageProxyPage extends Component {
    state = {
        active: 'list'
    }

    _onChangeTab = (value) => {
        this.setState({
            active: value
        })
    }

    render() {
        const {active} = this.state

        return (
            <Page fullWidth title="Manage Proxy" className="ManageProxyPage">
                <div className="ManageJobs">
                    <div className="HeadingTab">
                        <ul>
                            <li
                                className={`${active === 'list' ? 'Active' : ''}`}
                                onClick={() => this._onChangeTab('list')}
                            >
                                List proxy
                            </li>
                            <li
                                className={`${active === 'bandWidth' ? 'Active' : ''}`}
                                onClick={() => this._onChangeTab('bandWidth')}
                            >
                                Bandwidth and cost
                            </li>
                        </ul>
                    </div>
                    <Card sectioned>
                        <div className="ContentTab">
                            {
                                active === 'list' ? <ListProxy/> : <BandWidth/>
                            }
                        </div>
                    </Card>
                </div>
            </Page>
        )
    }
}

export default ManageProxyPage
